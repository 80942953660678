const ItemPersona = ({persona, index, onEdit, onDelete, onSelect}) => {
  const handleSelect = (persona) => {
    if (onSelect) {
      onSelect(persona); // Si se pasa la función de selección, se invoca
    }
  }; 
  return (
        <tr key={persona.id} onClick={() => handleSelect(persona)}>
                    <td>{index + 1}</td>
                    <td>{persona.apellido1 || ""}</td>
                    <td>{persona.apellido2 || ""}</td>
                    <td>{persona.nombre}</td>
                    <td>{persona.ci}</td>
                    {!onSelect && 
                    <>
                     <td>{persona.foto ? 'Sí' : 'No'}</td>
                    <td>
                      <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => onEdit(persona)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => onDelete(persona.id)}>
                        <i className="bi bi-trash"></i>
                      </button>
                    </td>
                    </>}
                   
                  </tr>
    )
}
export default ItemPersona;