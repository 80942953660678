import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchIngresosVarios, removeIngresoVarios } from '../../reducers/ingresosVarios_slice';
import IngresosVariosForm from './IngresosVariosForm';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import CustomPrintModal from '../UI/CustomPrintModal'; // Añadido para la impresión
import { useNavigate } from 'react-router-dom';

const IngresosVariosList = () => {
    const dispatch = useDispatch();
    const ingresosVariosState = useSelector(state => state.ingresosVarios || { ingresosVarios: [] });
    const ingresosVarios = ingresosVariosState.ingresosVarios || [];
    const [showModal, setShowModal] = useState(false);
    const [currentIngresoVario, setCurrentIngresoVario] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [showPreviewModal, setShowPreviewModal] = useState(false); // Estado para controlar el modal de impresión
    const [printContent, setPrintContent] = useState({ head: [], body: [] }); // Datos para imprimir
    const navigate=useNavigate();

    useEffect(() => {
        dispatch(fetchIngresosVarios());
    }, [dispatch]);

    const handleDelete = id => {
        Swal.fire({
            title: '¿Desea continuar con la eliminación?',
            text: "Este proceso es irreversible, ¿está seguro de eliminar este registro?",
            icon: 'warning', // Icono de advertencia
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#464646',
            confirmButtonText: 'Sí, eliminar',
             cancelButtonText: 'Cancelar',
      reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeIngresoVarios(id)).unwrap().then(() => {
                    Swal.fire('Eliminado', 'El registro ha sido eliminado con éxito.', 'success');
                }).catch((error) => {
                    Swal.fire('Error', error.error || 'No se puede eliminar, tiene dependencias..', 'error');
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelado', 'La eliminación ha sido cancelada.', 'info');
            }
        });
    };
    const handleBack = () => {
        navigate('/admin');
      };
    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleEdit = ingresoVario => {
        setCurrentIngresoVario(ingresoVario);
        setShowModal(true);
    };

    const handleCreate = () => {
        setCurrentIngresoVario(null);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setCurrentIngresoVario(null);
    };

    const handleExport = () => {
        const worksheet = XLSX.utils.json_to_sheet(sortedIngresosVarios);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "IngresosVarios");
        XLSX.writeFile(workbook, "ingresos_varios.xlsx");
    };

    const sortedIngresosVarios = useMemo(() => {
        let sortedData = [...ingresosVarios];
        if (sortConfig.key) {
            sortedData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return sortedData;
    }, [ingresosVarios, sortConfig]);

    // Función para generar el contenido de impresión
    const handleShowPreview = () => {
        const content = {
            head: [['Descripción', 'Código Cuenta']],
            body: sortedIngresosVarios.map((ingresoVario) => [
                ingresoVario.descripcion,
                ingresoVario.codigo_cuenta
            ])
        };
        setPrintContent(content);
        setShowPreviewModal(true);
    };

    return (
        <div className="list-container list3">
            <h1>Lista de Otros Ingresos</h1>
            <div className="list-controls">
                <ActionButton onClick={handleCreate} outline icon='plus'>
                    Nuevo Otros Ingresos
                </ActionButton>
                <button title="Volver" onClick={handleBack} className="icon-button btn-transparent">
                    <i className="bi bi-arrow-left"></i>
                </button>
                <div className="right-buttons">
                    <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent">
                        <i className="bi bi-printer"></i>
                    </button>
                    <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent">
                        <i className="bi bi-file-spreadsheet"></i>
                    </button>
                </div>
            </div>
            {ingresosVarios.length === 0 ? (
                <p>No hay ingresos varios disponibles.</p>
            ) : (
                <Table bordered hover size='sm' onDoubleClick={handleExport}> {/* Exportar con doble clic */}
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('descripcion')}>
                                Descripción
                                {sortConfig.key === 'descripcion' && (
                                    sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                                )}
                            </th>
                            <th onClick={() => handleSort('codigo_cuenta')}>
                                Código Cuenta
                                {sortConfig.key === 'codigo_cuenta' && (
                                    sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                                )}
                            </th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedIngresosVarios.map((ingresoVario) => (
                            <tr key={ingresoVario.id}>
                                <td>{ingresoVario.descripcion}</td>
                                <td>{ingresoVario.codigo_cuenta}</td>
                                <td>
                                    <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(ingresoVario)}>
                                        <i className="bi bi-pencil"></i>
                                    </button>
                                    <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(ingresoVario.id)}>
                                        <i className="bi bi-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            <IngresosVariosForm currentIngresoVario={currentIngresoVario} showModal={showModal} handleClose={handleClose} />
            {/* Modal para imprimir */}
            <CustomPrintModal
                show={showPreviewModal}
                handleClose={() => setShowPreviewModal(false)}
                title1="Lista de Otros Ingresos"
                title2=""
                content={printContent}
                copia={false}
            />
        </div>
    );
};

export default IngresosVariosList;
