import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addValorado, modifyValorado, fetchValorados } from '../../reducers/valorado_slice';
import { fetchPlanCuentas } from '../../reducers/planCuentas_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';

const initialState = {
    descripcion: "",
    codigo_cuenta: "",
};

const ValoradoForm = ({ currentValorado, showModal, handleClose }) => {
    const [datos, setDatos] = useState(initialState);
    const [error, setError] = useState('');
    const [isModified, setIsModified] = useState(false);

    const dispatch = useDispatch();
    const requestStatus = useSelector(state => state.valorado.statusSend);
    const errorInRequest = useSelector(state => state.valorado.error);
    const cuentas = useSelector(state => state.planCuentas.planCuentas || []);

    useEffect(() => {
        if (currentValorado) {
            setDatos(currentValorado);
            setIsModified(false);
        } else {
            resetForm();
        }
    }, [currentValorado]);

    useEffect(() => {
        if (showModal) {
            dispatch(fetchPlanCuentas());
        }
    }, [dispatch, showModal]);

    const handleSubmit = e => {
        e.preventDefault();

        if (isModified) {
            setError('');

            if (currentValorado) {
                dispatch(modifyValorado({ id: currentValorado.id, valorado: datos })).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al actualizar el valorado.',
                        });
                    } else {
                        handleClose();
                        resetForm();
                        dispatch(fetchValorados());
                    }
                });
            } else {
                dispatch(addValorado(datos)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear el valorado.',
                        });
                    } else {
                        handleClose();
                        resetForm();
                        dispatch(fetchValorados());
                    }
                });
            }
        } else {
            handleClose();
            resetForm();
        }
    };

    const resetForm = () => {
        setDatos(initialState);
        setError('');
        setIsModified(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        setIsModified(true);
    };

    let title = currentValorado ? "Editar valorado" : "Nuevo valorado";
    let headerColor = currentValorado ? '#255957' : '#254159';
    let buttonText = requestStatus !== "sending" ? (currentValorado ? 'Actualizar' : 'Crear') : "Enviando...";

    return (
        <Modal show={showModal} onHide={() => { handleClose(); resetForm(); }} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton style={{ backgroundColor: headerColor, color: 'white' }}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                    <Row xs={1} md={1}>
                        <Col className='col-form'>
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control
                                type="text"
                                name="descripcion"
                                value={datos.descripcion}
                                onChange={handleInputChange}
                                required
                            />
                        </Col>
                    </Row>
                    <Row xs={1} md={1} className="mt-3">
                        <Col className='col-form'>
                            <Form.Label>Código de Cuenta</Form.Label>
                            <Form.Control
                                as="select"
                                name="codigo_cuenta"
                                value={datos.codigo_cuenta}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Seleccione una cuenta</option>
                                {cuentas
                                    .filter(cuenta => cuenta.tipo_cuenta === 'DETALLE')
                                    .map(cuenta => (
                                        <option key={cuenta.id} value={cuenta.codigo_cuenta}>
                                            {cuenta.codigo_cuenta} - {cuenta.nombre_cuenta}
                                        </option>
                                    ))}
                            </Form.Control>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between mt-5">
                        <Button variant="outline-dark" onClick={() => { handleClose(); resetForm(); }}>
                            Cancelar
                        </Button>
                        <ActionButton outline>
                            {buttonText}
                        </ActionButton>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ValoradoForm;
