import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPersona, modifyPersona, fetchPersonas } from '../../reducers/persona_slice';
import { fetchLugares } from '../../reducers/lugar_slice';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import userImgPlaceholder from '../../assets/usuario.png';
import { useInputFile } from '../../hooks/useInputFile';
import { datosNoNulos, formatearFecha, getUploadUrl } from '../../util/valueCalculator';
import ActionButton from '../UI/Buttons/ActionButton';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import { BsCamera } from 'react-icons/bs';
import { Value } from 'sass';

const initialState = {
    nombre: "",
    apellido1: "",
    apellido2: "",
    ci: "",
    id_lugar_nac: "",
    fecha_nac: "",
    direccion: "",
    telefono: "",
    celular: "",
    email: "",
    genero: ""
}
const initialFormDetalles = () =>({title:"Nueva Persona",
    headerColor:"#254159", 
    buttonText:"Crear" })

const PersonaForm = ({  simplePersona=null, onSelectPersona = null, showModal, handleClose, actualizarListaFn=null }) => {
    const dispatch = useDispatch();
    const [datos, setDatos] = useState(initialState);
    const {
        file: foto,
        handleFileChange: handleFotoChange,
        resetInputFile: resetFoto,
        isValid: isFotoValid
    } = useInputFile("image");
    const currentPersona = useSelector(state => state.persona.current);
    const [fotoPreview, setFotoPreview] = useState(userImgPlaceholder);
    const [errors, setErrors] = useState({});
    const [isModified, setIsModified] = useState(false);
    const [formDetalles, setFormDetalles] = useState(initialFormDetalles);
    const [cameraConfig, setCameraConfig] = useState({
        nombreArchivo:"",
        show:false
    });
    const videoRef = useRef(null); 
    const lugares = useSelector(state => state.lugar.lugares) || [];
    // const lugarStatus = useSelector(state => state.lugar.status);
    const requestStatus = useSelector(state => state.persona.statusSend);
    // const errorInRequest = useSelector(state => state.persona.error);

    useEffect(() => {
        if(showModal)
        {  if (currentPersona) {
                setFormDetalles({title:"Editar Datos Personales",headerColor:"#255957", buttonText:"Actualizar" })
                const persona = { ...currentPersona, fecha_nac: formatearFecha(currentPersona.fecha_nac) }
                setDatos(datosNoNulos(persona));
                setIsModified(false);

                if (currentPersona.foto) {
                    setFotoPreview(getUploadUrl(currentPersona.foto));
                } else {
                    setFotoPreview(userImgPlaceholder);
                }
                resetFoto()
            } else {
                resetForm();
        }}
    }, [currentPersona,showModal]);
    
    useEffect(() => {
        if(showModal)
        {   dispatch(fetchLugares());

        }
    }, [showModal]);

    const handleSubmit = async e => {
        e.preventDefault();
        let nuevosErrores = {
            ...errors,
            ci: datos.ci === '',
            nombre: datos.nombre === '',
            apellido: datos.apellido1 === "" && datos.apellido2 === ""
        };
        setErrors(nuevosErrores);

        if (isModified || !currentPersona || foto) {
            console.log("Nuevos errores", nuevosErrores)
            if (!Object.values(nuevosErrores).includes(true)) {
            const personaData = { ...datos,
                nombre: datos.nombre.toUpperCase(),
                apellido1: datos.apellido1?.toUpperCase(),
                apellido2: datos.apellido2?.toUpperCase(),
            };
            setErrors('');
         let mensajeAlerta = `<hr>
          <div><strong>NUEVA PERSONA</strong></div>
                     <hr>
         <ul style="list-style: none; padding: 0; text-align: left;">
                    
            <li><strong>C.I.:</strong> ${personaData.ci}</li>
            <li><strong>Nombre:</strong> ${personaData?.nombre}</li>
            <li><strong>Primer apellido:</strong> ${personaData?.apellido1 || ""}</li>
            <li><strong>Segundo apellido:</strong> ${personaData?.apellido2 || ""}</li>
            <li><strong>Celular:</strong> ${personaData?.celular || ""}</li>
            <hr>
            </ul>

            `;
            
            const confirmacion = !currentPersona? await Swal.fire({
                title: "Confirmar Datos:",
                html: mensajeAlerta,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Crear",
                cancelButtonText: "Cancelar"
            }) : ({isConfirmed:true});
            
            if (confirmacion.isConfirmed) {
            const formData = new FormData();
            for (const key in personaData) {
                if (personaData[key] !== null) {
                    formData.append(key, personaData[key]);
                }
            }
            if (foto) {
                formData.append("foto", foto);
            }
            if (currentPersona) {
                dispatch(modifyPersona({ id: currentPersona.id, persona: formData })).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al actualizar la persona.',
                        });
                    } else {
                        handleCloseForm()
                        if(actualizarListaFn) dispatch(actualizarListaFn())
                    }
                });
            } else {
                dispatch(addPersona(formData)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear la persona.',
                        });
                    } else {
                        handleCloseForm()
                        if(actualizarListaFn) dispatch(actualizarListaFn())
                        if(onSelectPersona) onSelectPersona(response.payload)
                    }
                });
            }}
        }} else {
            handleCloseForm()
        }

    };

    const startCamera = () => {
        console.log("startCamera",datos)
        const iniciales = datos.ci? `${datos.ci}`:"algunapersona";
        const randomNum = Math.floor(Math.random() * 1000) + 1;
    
        console.log("iniciales",iniciales)
        setCameraConfig({nombreArchivo:`${iniciales}-${randomNum}.jpg`, 
                        show:true});
    
        navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }).catch((err) => {
          console.error("Error al acceder a la cámara: ", err);
        });
      };

      const dataURLtoBlob = (dataURL) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    const captureImage = () => {
        if (!cameraConfig.nombreArchivo) {
        Swal.fire('Error', 'Debe proporcionar un nombre para el archivo antes de capturar la imagen.', 'error');
        return;
        }

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL('image/jpeg');
        const blob = dataURLtoBlob(dataUrl);

        const file = new File([blob], cameraConfig.nombreArchivo, { type: 'image/jpeg' });
        console.log("File captured",file)
        handleFotoChange(file)
        handleChangeCameraConfig("show",false);
        };

    const handleChangeCameraConfig = (name, value)=>{
        setCameraConfig(prev=>({...prev, [name]:value}))
    }
    const resetForm = () => {
        setDatos(initialState);
        resetFoto();
        setFotoPreview(userImgPlaceholder);
        setErrors({});
        setFormDetalles(initialFormDetalles);
        setIsModified(false);
    };

    const handleCloseForm = ()=>{
        handleClose();
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let esValido = true
        let errorsToAdd = {}
        if (name === "nombre" || name === "apellido1" || name === "apellido2") {
            const regex = /^[A-Za-zÁÉÍÓÚáéíóúÑñÇç'’-]+(?:\s[A-Za-zÁÉÍÓÚáéíóúÑñÇç'’-]+)*$/;
         console.log("name", name, value)
            if(name !== "nombre") 
                {   esValido = regex.test(value.trim()) || value.trim()==="";
                    errorsToAdd = {apellido: false}
            }else{ 
                esValido = regex.test(value.trim())
                errorsToAdd = {nombre: value === ''}
            }
        }
        else if (name === "ci") {
            const regex = /^(?=.*\d)[A-Za-z0-9.-]+$/;
               esValido = value && regex.test(value.trim());
               errorsToAdd = {[name]: value === ''}
        }else if (name === "celular") {
            const regex = /^[\d\s()+-]+$/; // Permite números, espacios, paréntesis, guiones y el signo +
            esValido = value && regex.test(value.trim());
        }
        errorsToAdd = {...errorsToAdd, [`${name}_valido`] : !esValido}

        setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        setIsModified(true);
        setErrors({ ...errors, ...errorsToAdd});
    }

    return (
        <Modal show={showModal} onHide={handleCloseForm} backdrop="static" keyboard={false} size={!simplePersona ? "xl" : "lg"} centered>
            <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
                <Modal.Title>{formDetalles.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.mensaje && (
                    <Alert variant="danger">
                        {errors.mensaje }
                    </Alert>
                )}
           
                <Form encType="multipart/form-data" className='soft-labels'>
              
                    <Row xs={1} md={2} lg={!simplePersona ? 4 : 2}>
                        <Col className='col-form'>
                                <Form.Label>C.I.</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="ci"
                                    placeholder='Número de cédula de identidad'
                                    value={datos.ci}
                                    onChange={handleInputChange}
                                    isInvalid={errors.ci || errors.ci_valido}
                                    required
                                />
                                  <Form.Control.Feedback type='invalid'>
                                        {errors.ci && "El C.I. es requerido"}
                                        {errors.ci_valido && "Ingrese un C.I. válido."}
                                    </Form.Control.Feedback>
                            </Col>
                        <Col className='col-form'>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                name="nombre"
                                placeholder='Nombre de la persona'
                                value={datos.nombre}
                                onChange={handleInputChange}
                                isInvalid={errors.nombre || errors.nombre_valido}
                                required
                            />           
                            <Form.Control.Feedback type='invalid'>
                            {errors.nombre && "El nombre es requerido"}
                            {errors.nombre_valido && "Ingrese un nombre válido."}
                        </Form.Control.Feedback>
                        </Col>
                        <Col className='col-form'>
                            <Form.Label>Primer Apellido</Form.Label>
                            <Form.Control
                                type="text"
                                name="apellido1"
                                placeholder='Primer apellido'
                                value={datos.apellido1}
                                onChange={handleInputChange}
                                isInvalid={errors.apellido || errors.apellido1_valido}
                                required
                            />
                              <Form.Control.Feedback type='invalid'>
                              {errors.apellido && "Al menos un apellido es requerido"}
                              {errors.apellido1_valido && "Ingrese un apellido válido."}
                        </Form.Control.Feedback>
                        </Col>
                        <Col className='col-form'>
                            <Form.Label>Segundo apellido</Form.Label>
                            <Form.Control
                                type="text"
                                name="apellido2"
                                placeholder='Segundo Apellido'
                                value={datos.apellido2}
                                onChange={handleInputChange}
                                isInvalid={errors.apellido || errors.apellido2_valido}
                                required
                            />
                            <Form.Control.Feedback type='invalid'>
                                {errors.apellido && "Al menos un apellido es requerido"}
                                {errors.apellido2_valido && "Ingrese un apellido válido."}
                            </Form.Control.Feedback>
                        </Col>
                        
                      
                    </Row>
                    {!simplePersona && <>
                        <Row xs={2} md={2} lg={4}>
                      
                    
                      <Col className='col-form'>
                          <Form.Label>Lugar de Nacimiento</Form.Label>
                          <Form.Control
                              as="select"
                              name="id_lugar_nac"
                              value={datos.id_lugar_nac}
                              onChange={handleInputChange}
                              required
                          >
                              <option value="">- Selecciona un lugar -</option>
                              {lugares.map(lugar =>
                               (<option key={lugar.id} value={lugar.id}>{`${lugar.nombre}`}</option>
                              ))}
                          </Form.Control>
                      </Col>
                      <Col className='col-form'>
                          <Form.Label>Fecha de Nacimiento</Form.Label>
                          <Form.Control
                              type="date"
                              name="fecha_nac"
                              value={datos.fecha_nac}
                              onChange={handleInputChange}
                          />
                      </Col>
                      <Col className='col-form'>
                          <Form.Label>Dirección</Form.Label>
                          <Form.Control
                              type="text"
                              name="direccion"
                              placeholder='Dirección de domicilio'
                              value={datos.direccion}
                              onChange={handleInputChange}
                          />
                      </Col>
                      <Col className='col-form'>
                          <Form.Label>Género</Form.Label>
                          <Form.Control
                              as="select"
                              name="genero"
                              value={datos.genero}
                              onChange={handleInputChange}
                              required
                          >
                              <option value="">- Selecciona un género -</option>
                              <option value="MASCULINO">MASCULINO</option>
                              <option value="FEMENINO">FEMENINO</option>
                          </Form.Control>
                      </Col>   
                  </Row>
                  </>}
                  <Row xs={2} md={2} lg={!simplePersona ? 4 : 2}>
                    <Col className='col-form'>
                            <Form.Label>Celular</Form.Label>
                            <Form.Control
                                type="text"
                                name="celular"
                                placeholder='Número de celular'
                                value={datos.celular}
                                isInvalid={errors.celular_valido}
                                onChange={handleInputChange}
                            />
                                <Form.Control.Feedback type='invalid'>
                                {errors.celular_valido && "Ingrese un número de celular válido."}
                                </Form.Control.Feedback>
                        </Col>
                    {!simplePersona &&
                    <><Col className='col-form'>
                          <Form.Label>Teléfono fijo</Form.Label>
                          <Form.Control
                              type="text"
                              name="telefono"
                              placeholder='Número de teléfono fijo'
                              value={datos.telefono}
                              onChange={handleInputChange}
                          />
                      </Col>
                       <Col className='col-form'>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                              type="email"
                              name="email"
                              placeholder='Correo electrónico'
                              value={datos.email}
                              onChange={handleInputChange}
                          />
                      </Col>
                      </> }
                   
                  </Row>
                  {!simplePersona &&
                  <Row xs={1} md={1} lg={2}>
             
             <Col className='col-form'>
                 <Form.Label>Foto</Form.Label>
                 <Form.Control
                     type="file"
                     name="foto"
                     isInvalid={!isFotoValid}
                     onChange={(e) => {handleFotoChange(e.target.files[0]); 
                                        console.log("Foto subida",e.target.files[0]);
                                         setIsModified(true);}}
                     disabled={false}
                 />
                 <button
                        className="icon-button"
                        style={{ marginLeft: '10px' }}
                        onClick={() => startCamera(null)}
                         type="button"
                    >
                        <BsCamera />
                    </button>
                  <Form.Control.Feedback type="invalid">
                     Por favor selecciona una imagen válida.
                  </Form.Control.Feedback>
                  </Col>
                  <Col className='col-form'>
                 <div className="d-flex justify-content-center mb-4">
                     <div className="text-center">
                         {fotoPreview && (
                             <div>
                                 <div>Foto</div>
                                 <Image 
                                     src={!currentPersona && foto && isFotoValid ? URL.createObjectURL(foto) : fotoPreview} 
                                     alt="Foto previa" 
                                     className="img-fluid img-cuadrada-150 mb-2 mt-3"
                                 />
                             </div>
                         )}
                     </div>
                     <div className="text-center">
                         {(currentPersona && foto && isFotoValid) && (
                             <>
                                 <div>Nueva foto</div>
                                 <Image
                                     className="img-fluid img-cuadrada-150 mb-2 mt-3"
                                     src={URL.createObjectURL(foto)}
                                 />
                             </>
                         )}
                     </div>
                 </div>
                 </Col>
                  </Row>}
                 
                    <div className="d-flex justify-content-end mt-3">
                        <button className="button-mine btn-cancel-outline" onClick={handleCloseForm} type='button'>
                            Cancelar
                        </button>
                        <button type="button" onClick={handleSubmit}  className="button-mine btn-success-outline" disabled={!isModified}>
                        <i className="bi bi-check-circle"></i>   {requestStatus !== "sending" ? formDetalles.buttonText: "Enviando..."}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
            {cameraConfig.show && (
                <Modal show={cameraConfig.show} onHide={() => handleChangeCameraConfig("show", false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Capturar Imagen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <video ref={videoRef} style={{ width: '100%' }} />
                    <Form.Group className="mt-3">
                    <Form.Label>Nombre del Archivo</Form.Label>
                    <Form.Control
                        type="text"
                        value={cameraConfig.nombreArchivo}
                        onChange={(e) => handleChangeCameraConfig("nombreArchivo", e.target.value)}
                        placeholder="Ingrese el nombre del archivo"
                    />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button-mine" onClick={() => handleChangeCameraConfig("show",false)}>
                    Cerrar
                    </button>
                    <button className="button-mine" onClick={captureImage}>
                    Capturar Imagen
                    </button>
                </Modal.Footer>
                </Modal>
            )}
        </Modal>
    );
};

export default PersonaForm;
