import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTituloProfesional, modifyTituloProfesional, fetchTitulosProfesionales } from '../../reducers/tituloProfesional_slice';
import { fetchGrados } from '../../reducers/grado_slice';
import { fetchInstituciones } from '../../reducers/institucion_slice';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import userImgPlaceholder from '../../assets/usuario.png';
import { useInputFile } from '../../hooks/useInputFile';
import { datosNoNulos, formatearFecha, getUploadUrl } from '../../util/valueCalculator';
import Swal from 'sweetalert2';

const initialState = {
  nombre_titulo: '',
  id_grado: '',
  id_institucion: '',
  fecha_obtencion: '',
  numero_registro: '',
};

const TituloProfesionalForm = ({ afiliado, currentTitulo, showModal, handleClose }) => {
  const dispatch = useDispatch();
  const grados = useSelector((state) => state.grado.grados || []);
  const instituciones = useSelector((state) => state.institucion.instituciones || []);
  const [fotoPreview, setFotoPreview] = useState(userImgPlaceholder);
  const [fotoNuevaPreview, setFotoNuevaPreview] = useState(null);
  const [datos, setDatos] = useState(initialState);
  const videoRef = useRef(null);
  const [showCameraModal, setShowCameraModal] = useState(false);
  const [nombreArchivo, setNombreArchivo] = useState('');
  const [isFotoActualVisible, setIsFotoActualVisible] = useState(true);
  const [isFotoNuevaVisible, setIsFotoNuevaVisible] = useState(true);

  const {
    file: foto,
    handleFileChange: handleFotoChange,
    resetInputFile: resetFoto,
  } = useInputFile('image');

  useEffect(() => {
    dispatch(fetchGrados());
    dispatch(fetchInstituciones());

    if (showModal) {
      if (currentTitulo) {
        setDatos({
          nombre_titulo: currentTitulo.nombre_titulo,
          id_grado: currentTitulo.id_grado,
          id_institucion: currentTitulo.id_institucion,
          fecha_obtencion: formatearFecha(currentTitulo.fecha_obtencion),
          numero_registro: currentTitulo.numero_registro,
        });

        if (currentTitulo.foto) {
          setFotoPreview(getUploadUrl(currentTitulo.foto));
        } else {
          setFotoPreview(userImgPlaceholder);
        }
      } else {
        resetForm();
      }
    }
  }, [currentTitulo, showModal, dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDatos({ ...datos, [name]: value });
  };

  const resetForm = () => {
    setDatos(initialState);
    resetFoto();
    setFotoPreview(userImgPlaceholder);
    setFotoNuevaPreview(null);
  };

  const startCamera = () => {
    const randomNum = Math.floor(Math.random() * 10000) + 1;
    const fileName = `${afiliado.num_reg}-${randomNum}.jpg`;
    setNombreArchivo(fileName);
    setShowCameraModal(true);

    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        videoRef.current.srcObject = stream; // Asignar flujo de la cámara
        videoRef.current.play(); // Reproducir video
      })
      .catch((err) => {
        console.error('Error al acceder a la cámara:', err);
        Swal.fire('Error', 'No se pudo acceder a la cámara. Verifica los permisos.', 'error');
      });
  };

  const captureImage = () => {
    if (!nombreArchivo) {
      Swal.fire('Error', 'Debe proporcionar un nombre para el archivo antes de capturar la imagen.', 'error');
      return;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    const dataUrl = canvas.toDataURL('image/jpeg');
    const blob = dataURLtoBlob(dataUrl);
    const file = new File([blob], nombreArchivo, { type: 'image/jpeg' });

    handleFotoChange(file);
    setFotoNuevaPreview(URL.createObjectURL(file));
    stopCamera();
    setShowCameraModal(false);
  };

  const stopCamera = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    videoRef.current.srcObject = null;
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    Object.keys(datosNoNulos(datos)).forEach((key) => {
      formData.append(key, datos[key]);
    });

    if (foto) {
      formData.append('foto', foto);
    }

    formData.append('id_afiliado', afiliado.id);

    if (currentTitulo) {
      dispatch(modifyTituloProfesional({ id: currentTitulo.id, formData }))
        .then(() => {
          Swal.fire('Éxito', 'Título profesional actualizado.', 'success');
          handleClose();
          dispatch(fetchTitulosProfesionales(afiliado.id));
        })
        .catch(() => Swal.fire('Error', 'No se pudo actualizar el título profesional.', 'error'));
    } else {
      dispatch(addTituloProfesional(formData))
        .then(() => {
          Swal.fire('Éxito', 'Título profesional creado.', 'success');
          handleClose();
          dispatch(fetchTitulosProfesionales(afiliado.id));
        })
        .catch(() => Swal.fire('Error', 'No se pudo crear el título profesional.', 'error'));
    }
  };

  const toggleFotoActualVisibility = () => setIsFotoActualVisible((prev) => !prev);
  const toggleFotoNuevaVisibility = () => setIsFotoNuevaVisible((prev) => !prev);

  return (
    <Modal show={showModal} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{currentTitulo ? 'Editar Título Profesional' : 'Nuevo Título Profesional'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          <div className="w-75">
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
              <Form.Group>
                <Form.Label>Nombre del Título</Form.Label>
                <Form.Control
                  type="text"
                  name="nombre_titulo"
                  value={datos.nombre_titulo}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Grado</Form.Label>
                <Form.Control
                  as="select"
                  name="id_grado"
                  value={datos.id_grado}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Seleccione un grado</option>
                  {grados.map((grado) => (
                    <option key={grado.id} value={grado.id}>
                      {grado.nombre}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Institución</Form.Label>
                <Form.Control
                  as="select"
                  name="id_institucion"
                  value={datos.id_institucion}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Seleccione una institución</option>
                  {instituciones.map((inst) => (
                    <option key={inst.id} value={inst.id}>
                      {inst.nombre}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Fecha de Obtención</Form.Label>
                <Form.Control
                  type="date"
                  name="fecha_obtencion"
                  value={datos.fecha_obtencion}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Número de Registro</Form.Label>
                <Form.Control
                  type="text"
                  name="numero_registro"
                  value={datos.numero_registro}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Foto Nueva</Form.Label>
                <Form.Control
                  type="file"
                  name="foto"
                  onChange={(e) => {
                    handleFotoChange(e.target.files[0]);
                    if(e.target.files[0]) setFotoNuevaPreview(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </Form.Group>
              <div className="d-flex justify-content-between mt-3">
                <Button variant="secondary" onClick={startCamera}>
                  Usar Cámara
                </Button>
                <Button variant="primary" type="submit">
                  {currentTitulo ? 'Actualizar' : 'Crear'}
                </Button>
              </div>
            </Form>
          </div>
          <div className="w-25 ms-4">
            <div className="mb-3">
              <h6>Foto Actual</h6>
              {isFotoActualVisible && <Image src={fotoPreview} alt="Foto actual" fluid />}
              <Button variant="link" onClick={toggleFotoActualVisibility}>
                <i className={`bi bi-eye${isFotoActualVisible ? '-slash' : ''}`}></i>
              </Button>
            </div>
            <div>
              <h6>Foto Nueva</h6>
              {fotoNuevaPreview && isFotoNuevaVisible && <Image src={fotoNuevaPreview} alt="Foto nueva" fluid />}
              {fotoNuevaPreview && (
                <Button variant="link" onClick={toggleFotoNuevaVisibility}>
                  <i className={`bi bi-eye${isFotoNuevaVisible ? '-slash' : ''}`}></i>
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal show={showCameraModal} onHide={() => { setShowCameraModal(false); stopCamera(); }}>
          <Modal.Header closeButton>
            <Modal.Title>Capturar Imagen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <video ref={videoRef} style={{ width: '100%' }} />
            <Form.Group className="mt-3">
              <Form.Label>Nombre del Archivo</Form.Label>
              <Form.Control
                type="text"
                value={nombreArchivo}
                onChange={(e) => setNombreArchivo(e.target.value)}
                placeholder="Ingrese el nombre del archivo"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { setShowCameraModal(false); stopCamera(); }}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={captureImage}>
              Capturar Imagen
            </Button>
          </Modal.Footer>
        </Modal>
      </Modal.Footer>
    </Modal>
  );
};

export default TituloProfesionalForm;



