import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAfiliadoByIdPersona } from '../../reducers/afiliado_slice';
import { modifyPersona } from '../../reducers/persona_slice';
import { fetchLugares } from '../../reducers/lugar_slice';
import { fetchSituaciones } from '../../reducers/situacion_slice';
import { useInputFile } from '../../hooks/useInputFile';
import { datosNoNulos, getUploadUrl } from '../../util/valueCalculator';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { BsCamera } from 'react-icons/bs';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import userImgPlaceholder from '../../assets/usuario.png';
import { apellidosNombreFullPersona } from '../../util/customFormatter';

const AfiliadoEditForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id_persona } = useSelector((state) => state.sesion);
    const afiliado = useSelector((state) => state.afiliado.current);
    const lugares = useSelector((state) => state.lugar.lugares || []);
    const situaciones = useSelector((state) => state.situacion.situaciones || []);

    const [datos, setDatos] = useState({
        ci: '',
        genero: '',
        id_lugar_nac: '',
        fecha_nac: '',
        direccion: '',
        telefono: '',
        celular: '',
        email: '',
        foto: null,
    });

    const [fotoPreview, setFotoPreview] = useState(userImgPlaceholder);
    const [fotoNuevaPreview, setFotoNuevaPreview] = useState(null);
    const [nombreImagen, setNombreImagen] = useState(`imagen-${Date.now()}.jpg`);
    const [isFotoActualVisible, setIsFotoActualVisible] = useState(true);
    const [isFotoNuevaVisible, setIsFotoNuevaVisible] = useState(true);
    const [showCameraModal, setShowCameraModal] = useState(false);
    const videoRef = useRef(null);

    const { file: foto, handleFileChange: handleFotoChange } = useInputFile('image');

    useEffect(() => {
        if (id_persona) {
            dispatch(fetchAfiliadoByIdPersona(id_persona));
        }
        dispatch(fetchLugares());
        dispatch(fetchSituaciones());
    }, [dispatch, id_persona]);

    useEffect(() => {
        if (afiliado?.persona) {
            setDatos({
                ci: afiliado.persona.ci,
                genero: afiliado.persona.genero || '',
                id_lugar_nac: afiliado.persona.id_lugar_nac || '',
                fecha_nac: afiliado.persona.fecha_nac || '',
                direccion: afiliado.persona.direccion || '',
                telefono: afiliado.persona.telefono || '',
                celular: afiliado.persona.celular || '',
                email: afiliado.persona.email || '',
                foto: afiliado.persona.foto || null,
            });
            setFotoPreview(
                afiliado.persona.foto ? getUploadUrl(afiliado.persona.foto) : userImgPlaceholder
            );
        }
    }, [afiliado]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDatos((prev) => ({ ...prev, [name]: value }));
    };

    const startCamera = () => {
        setShowCameraModal(true);
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            })
            .catch(() => Swal.fire('Error', 'No se pudo acceder a la cámara. Verifica los permisos.', 'error'));
    };

    const captureImage = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

        const dataUrl = canvas.toDataURL('image/jpeg');
        const blob = dataURLtoBlob(dataUrl);
        const file = new File([blob], nombreImagen, { type: 'image/jpeg' });

        handleFotoChange(file);
        setFotoNuevaPreview(URL.createObjectURL(file));
        stopCamera();
        setShowCameraModal(false);
    };

    const stopCamera = () => {
        const stream = videoRef.current?.srcObject;
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
        }
        videoRef.current.srcObject = null;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        const datosSinFoto = { ...datos };
        delete datosSinFoto.foto;

        Object.keys(datosNoNulos(datosSinFoto)).forEach((key) => {
            formData.append(key, datosSinFoto[key]);
        });

        if (foto) {
            formData.append('foto', foto);
        }

        dispatch(modifyPersona({ id: afiliado.persona.id, persona: formData }))
            .then(() => {
                Swal.fire('Éxito', 'Datos actualizados correctamente.', 'success').then(() => {
                    navigate('/admin');
                });
            })
            .catch(() => Swal.fire('Error', 'Hubo un problema al actualizar los datos.', 'error'));
    };

    const dataURLtoBlob = (dataURL) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    return (
        <div className="list-container list0">
            <h2 className="text-center">Editar Datos Personales</h2>

            <div className="d-flex justify-content-start align-items-center mb-4">
                <div className="text-center">
                    <h6>Foto Actual</h6>
                    {isFotoActualVisible && (
                        <Image
                            src={fotoPreview}
                            alt="Foto Actual"
                            className="img-thumbnail me-3"
                            style={{ maxWidth: '140px' }}
                        />
                    )}
                    <Button
                        variant="link"
                        onClick={() => setIsFotoActualVisible(!isFotoActualVisible)}
                        className="p-0"
                    >
                        {isFotoActualVisible ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                </div>
                <div className="text-center">
                    <h6>Foto Nueva</h6>
                    {fotoNuevaPreview && isFotoNuevaVisible && (
                        <Image
                            src={fotoNuevaPreview}
                            alt="Nueva Foto"
                            className="img-thumbnail me-3"
                            style={{ maxWidth: '140px' }}
                        />
                    )}
                    
                    <Button
                        variant="link"
                        onClick={() => setIsFotoNuevaVisible(!isFotoNuevaVisible)}
                        className="p-0"
                    >
                        {isFotoNuevaVisible ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                </div>
            </div>

            <Row>
                <Col md={6}>
                    <p>
                        <strong>Apellidos y Nombre:</strong> {`${apellidosNombreFullPersona(afiliado?.persona, "coma")}`}
                        
                    </p>
                </Col>
                <Col md={4}>
                    <p>
                    <strong>  C.I.:</strong> {`${afiliado?.persona?.ci || ''} `}
                        
                    </p>
                </Col>

                <Col md={4}>
                    <p>
                        <strong>Número de Registro:</strong> {afiliado?.num_reg || 'No disponible'}
                    </p>
                </Col>
                <Col md={4}>
                    <p>
                        <strong>Fecha de Registro:</strong> {afiliado?.fecha_reg_cab || 'No disponible'}
                    </p>
                </Col>
                <Col md={4}>
                    <p>
                        <strong>Situación:</strong> {situaciones.find((s) => s.id === afiliado?.id_situacion)?.nombre || 'No disponible'}
                    </p>
                </Col>
            </Row>

            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Género</Form.Label>
                            <Form.Control
                                as="select"
                                name="genero"
                                value={datos.genero}
                                onChange={handleInputChange}
                            >
                                <option value="">Selecciona Género</option>
                                <option value="MASCULINO">Masculino</option>
                                <option value="FEMENINO">Femenino</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Lugar de Nacimiento</Form.Label>
                            <Form.Control
                                as="select"
                                name="id_lugar_nac"
                                value={datos.id_lugar_nac}
                                onChange={handleInputChange}
                            >
                                <option value="">Selecciona Lugar</option>
                                {lugares.map((lugar) => (
                                    <option key={lugar.id} value={lugar.id}>
                                        {lugar.nombre}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Fecha de Nacimiento</Form.Label>
                            <Form.Control
                                type="date"
                                name="fecha_nac"
                                value={datos.fecha_nac}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Dirección</Form.Label>
                            <Form.Control
                                type="text"
                                name="direccion"
                                value={datos.direccion}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control
                                type="text"
                                name="telefono"
                                value={datos.telefono}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Celular</Form.Label>
                            <Form.Control
                                type="text"
                                name="celular"
                                value={datos.celular}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={datos.email}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group className="d-flex align-items-center">
                
                    <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            handleFotoChange(e.target.files[0]);
                            setFotoNuevaPreview(URL.createObjectURL(e.target.files[0]));
                        }}
                        className="me-2"
                        style={{ maxWidth: '400px' }} // Ajuste del ancho máximo
                    />
                    <Button variant="secondary" onClick={startCamera}  type="button">
                        <BsCamera />
                    </Button>
                </Form.Group>
                <Button type="submit" className="mt-3" variant="primary">
                    Guardar Cambios
                </Button>
                <Button
                    type="button"
                    className="mt-3 ms-2"
                    variant="secondary"
                    onClick={() => navigate('/admin')}
                >
                    Cancelar
                </Button>
            </Form>

            <Modal show={showCameraModal} onHide={() => { setShowCameraModal(false); stopCamera(); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Capturar Imagen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <video ref={videoRef} style={{ width: '100%' }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowCameraModal(false); stopCamera(); }}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={captureImage}>
                        Capturar Imagen
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AfiliadoEditForm;
