import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, useNavigate } from "react-router-dom";
import { register } from "../../actions/authAction";
import { toast } from "react-toastify";
import { Alert, Col, Row } from "react-bootstrap";

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    ci:"",
    nombre: "",
    apellido1: "",
    apellido2: "",
    usuario:"",
    clave_acceso: "",
    id_persona:"2049",
  });
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmitRegister = (e) => {
    e.preventDefault();
    setIsSending(true)
    dispatch(register(formData)).then((response) => {
     
      if(response.status === 200){
        navigate('/login')
      }else if(response.status === 400){
        setError(response.messages)
      }else if(response.status === 500){
        toast.error(response.message)
      }
      setIsSending(false)
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <>
      <Form
        onSubmit={handleSubmitRegister}
        className="form-auth form-register"
      >
        <h3 className="mb-3 ">Crear cuenta</h3>
        <>
            {error && error.map((err, index) => (
                <Alert key={index} variant="danger">
                    {err}
                </Alert>
            ))}
        </>
        <Row xs={1} md={2} className="mb-3">
        <Col className="mb-3">
          <label
              htmlFor="ci"
              className="form-label"
            >
              N° Cédula de Identidad
            </label>
            <input
              type="text"
              className="form-control"
              name="ci"
              value={formData.ci}
              onChange={handleChange}
              required
            />
          </Col>
        <Col>
          <label
            htmlFor="nombre"
            className="form-label"
          >
            Nombre(s)
          </label>
          <input
            type="text"
            className="form-control"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
          />
        </Col>
        <Col>
          <label
            htmlFor="apellido1"
            className="form-label"
          >
            Primer Apellido
          </label>
          <input
            type="text"
            className="form-control"
            name="apellido1"
            value={formData.apellido1 || ""}
            onChange={handleChange}
            required
          />
        </Col>
        <Col>
          <label
            htmlFor="apellido2"
            className="form-label"
          >
            Segundo Apellido
          </label>
          <input
            type="text"
            className="form-control"
            name="apellido2"
            value={formData.apellido2 || ""}
            onChange={handleChange}
            required
          />
          </Col>
      </Row>
        <div className="mb-3">
        <label htmlFor="usuario" className="form-label">
          Usuario
        </label>
        <input
          type="text"
          className="form-control"
          name="usuario"
          value={formData.usuario}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="clave_acceso" className="form-label">
        Clave de acceso
        </label>
        <input
          type="password"
          className="form-control"
          name="clave_acceso"
          value={formData.clave_acceso}
          onChange={handleChange}
          required
        />
      </div>
      <div className="button-container">
        <button
          disabled={isSending}
          className="button-mine btn-mine-primary w-50"
        >
          {isSending ? "Registrando..." : "Registrar"}
        </button>
        </div>
      </Form>
    </>
  );
};
export default RegisterForm;
