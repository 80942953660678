import { useEffect, useState } from "react";
import { apellidosNombreFullPersona } from "../../util/customFormatter";
import { useDispatch, useSelector } from "react-redux";
import { Form, InputGroup, Table } from "react-bootstrap";
import ItemPersona from "./ItemPersona";
import { fetchPersonas, personaActions, setListPersonas } from "../../reducers/persona_slice";
import ActionButton from "../UI/Buttons/ActionButton";
import PersonaForm from "./PersonaForm";

const TablePersona = ({onSelectPersona=null, simplePersona=null, excludeList=null, handleDelete,actualizarListaFn=null}) => {
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');
  const personas = useSelector(state => state.persona.personas);
  const sortedFilteredPersonas = useSelector(state => state.persona.sortedFilteredList || personas);
  const [showModal, setShowModal] = useState(false);

  useEffect(()=>{
    let  filteredPersonas = [...personas].filter(p=>
      apellidosNombreFullPersona(p, "ci").toLowerCase().includes(searchTerm.toLowerCase()) 
    )
    // Excluir personas que están en la lista de exclusión, si excludeList no es null
    if (excludeList) {
      filteredPersonas = filteredPersonas.filter(p => 
        !excludeList.some(excludedPersona => excludedPersona.id === p.id)
      );
  }

    let sortedData = [...filteredPersonas];
    if (sortConfig.key) {
        sortedData.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
        });
      }
      dispatch(personaActions.setListPersonas(sortedData))
},[personas, sortConfig, searchTerm])


  const handleSort = (key) => {
   
    if(sortConfig.direction === 'desc'){
        setSortConfig({ key: null, direction: 'asc' });
        return
    }
    
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
    direction = 'desc';
    } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
    direction = null; // Si ya estaba descendente, lo dejamos sin ordenar
    }

    setSortConfig({ key, direction });
};

const handleSearch = (event) => {
  setSearchTerm(event.target.value);
};
 
const handleEdit = persona => {
  dispatch(personaActions.setCurrentPersona(persona));
  setShowModal(true);
};

const handleCreate = () => {
  dispatch(personaActions.setCurrentPersona(null));
  setShowModal(true);
};

const handleClose = () => {
  setShowModal(false);
  dispatch(personaActions.setCurrentPersona(null));
};
  return (
      <>
        <div className="list-container-header">
      <div className="left-buttons">
      <InputGroup size="sm">
        <InputGroup.Text>
        <i className="bi bi-search"></i>
        </InputGroup.Text>
      <Form.Control
                type="text"
                size="sm"
                placeholder="Buscar"
                className="form-control"
                value={searchTerm}
                onChange={handleSearch}
              />
              </InputGroup>
      </div>
        <div className="right-buttons">
        <ActionButton onClick={handleCreate} outline icon='plus' floatEnd type="button">
              Nueva Persona
            </ActionButton>
          </div>
        </div>
        
      {sortedFilteredPersonas.length === 0 ? (
          <p>No hay personas disponibles.</p>
        ) : (
          <>
           
              <div className='table-container'>
           
           <Table bordered hover size='sm'>
            
             <thead className='text-center align-middle'>
               <tr>
               <th className='col-index'>
                   N°
                   </th>
              
                   <th onClick={() => handleSort('apellido1')}>
                   Primer Apellido
                   {sortConfig.key === 'apellido1' && (
                       sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                   )}
                   </th>
                   <th onClick={() => handleSort('apellido2')}>
                   Segundo Apellido
                   {sortConfig.key === 'apellido2' && (
                       sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                   )}
                   </th>
                   <th onClick={() => handleSort('nombre')}>
                   Nombre
                   {sortConfig.key === 'nombre' && (
                       sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                   )}
                   </th>
                   <th onClick={() => handleSort('ci')}>
                   C.I.
                   {sortConfig.key === 'ci' && (
                       sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                   )}
                   </th>
                   {!onSelectPersona && 
                   <>
                    <th>Foto</th>
                    <th className='col-simple-actions'>Acciones</th>
                    </>
                   }
                
               </tr>
             </thead>
             <tbody>
               {sortedFilteredPersonas.map((persona, index) => (
                 <ItemPersona key={persona.id} index={index} persona={persona} onEdit={handleEdit} onDelete={handleDelete} onSelect={onSelectPersona}/>
               ))}
             </tbody>
           </Table>
         </div>
         <PersonaForm currentPersona={null} 
                                simplePersona = {simplePersona}
                                showModal={showModal} 
                                handleClose={handleClose} 
                                actualizarListaFn={actualizarListaFn? actualizarListaFn : fetchPersonas}
                                onSelectPersona={onSelectPersona}/>
              </>
         
        )}
      </>
    )
}
export default TablePersona;