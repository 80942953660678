import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import VistaPreviaArchivo from '../UI/VistaPreviaArchivo';

const VistaPreviaDocumento = ({ show, handleClose, documento }) => {
  const [docActual, setDocActual] = useState(null);

  useEffect(() => {
    if (documento) {
      setDocActual(documento);
    }
  }, [documento]);

  if (!docActual) return null;

  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>{docActual.nombre}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VistaPreviaArchivo archivo={docActual} medida={"normal"}/> {/* Usamos el componente VistaPreviaArchivo */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
        <a href={docActual.url} download className="btn btn-primary">Descargar</a>
      </Modal.Footer>
    </Modal>
  );
};

export default VistaPreviaDocumento;
