import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import VistaGeneralProyecto from './VistaGeneralProyecto';
import { getEstadoProyecto, getUploadUrl } from '../../util/valueCalculator';
import imgPlaceholder from '../../assets/plano_placeholder2.png';
import { Col, Image, Row } from 'react-bootstrap';
import { fetchProyectoById, setCurrentProyecto } from '../../reducers/proyecto_slice';
import PaseProfesionalModal from './PaseProfesionalModal';


const VerProyectoModal = ({ currentProyecto, headerColor, handleClose, show}) => {
  const dispatch = useDispatch()
  const proyecto = useSelector(state => state.proyecto.current)
  const {rolActivo} = useSelector(state => state.sesion)
  const [modalExtra, setModalExtra] = useState({show: false, tipo:null})
  useEffect(()=>{
      if(currentProyecto && currentProyecto.id)
       {  dispatch(fetchProyectoById(currentProyecto.id)) }

  },[dispatch, currentProyecto])
  
  const handleOpenPaseProfesional = ()=>{
    setModalExtra({show:true, tipo:"pase"});
  }
  const handleClosePaseModal = (realizado =false)=>{
    setModalExtra({show:false, tipo:null});
    if(realizado) handleClose()
  }
  return (
    <>        <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header closeButton style={{ backgroundColor:headerColor, color: 'white' }}>
        <Modal.Title>
          PROYECTO {currentProyecto? getEstadoProyecto(currentProyecto.estado).titulo : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <VistaGeneralProyecto proyecto={currentProyecto} ver={true} />
          <div className="list-container-header mt-4">
                                <div className="left-buttons">
                                {rolActivo?.alias==="gerente" && currentProyecto?.estado === "visado" && <>
                      <button className="icon-button btn-secondary-outline" 
                              title="Pase profesional" onClick={() => handleOpenPaseProfesional(proyecto)}>
                           <i class="bi bi-chevron-double-right"></i>
                      </button>
              </>}
                              
                                </div>
                                <div className="right-buttons">
                                <button className="button-mine btn-cancel-outline" type="button" onClick={handleClose}>
                                    Cerrar
                                </button>
                            </div>
                            </div>

        
      </Modal.Body>
   

      </Modal>
         <PaseProfesionalModal proyecto={currentProyecto} 
         show={modalExtra.show && modalExtra.tipo==="pase"}
         handleClose={handleClosePaseModal}/>
         </>

    );
};

export default VerProyectoModal;
