import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDirectivas, removeDirectiva } from '../../reducers/directiva_slice';
import DirectivaForm from './DirectivaForm';
import DirAfiliadoModal from './DirAfiliadoModal';
import DirAfiliadoView from './DirAfiliadoView';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import * as XLSX from 'xlsx';
import CustomPrintModal from '../UI/CustomPrintModal';

const DirectivaList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const directivas = useSelector(state => state.directiva.directivas || []);
  const [showModal, setShowModal] = useState(false);
  const [currentDirectiva, setCurrentDirectiva] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [showDirAfiliadoModal, setShowDirAfiliadoModal] = useState(false);
  const [showDirAfiliadoViewModal, setShowDirAfiliadoViewModal] = useState(false);
  const [selectedDirectiva, setSelectedDirectiva] = useState(null);

  useEffect(() => {
    dispatch(fetchDirectivas());
  }, [dispatch]);

  // Ordenar directivas por nombre
  const directivasOrdenadas = useMemo(() => {
    return [...directivas].sort((a, b) => a.nombre.localeCompare(b.nombre));
  }, [directivas]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeDirectiva(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'La directiva ha sido eliminada.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.message || 'No se pudo eliminar la directiva.', 'error');
        });
      }
    });
  };

  const handleEdit = directiva => {
    setCurrentDirectiva(directiva);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentDirectiva(null);
    setShowModal(true);
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      directivasOrdenadas.map((directiva, index) => ({
        Número: index + 1,
        Nombre: directiva.nombre,
        "Fecha Inicio": directiva.fechaini,
        "Fecha Fin": directiva.fechafin,
        Comité: directiva.comite,
        Observación: directiva.observacion,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Directivas");
    XLSX.writeFile(workbook, "directivas.xlsx");
  };

  const handleShowPreview = () => {
    const content = {
      head: [['Número', 'Nombre', 'Fecha Inicio', 'Fecha Fin', 'Comité', 'Observación']],
      body: directivasOrdenadas.map((directiva, index) => [
        index + 1,
        directiva.nombre,
        directiva.fechaini,
        directiva.fechafin,
        directiva.comite,
        directiva.observacion,
      ])
    };
    setPrintContent(content);
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const handleBack = () => {
    navigate('/admin');
  };

  const handleConformarDirectiva = directiva => {
    setSelectedDirectiva(directiva);
    setShowDirAfiliadoModal(true);
  };

  const handleViewDirectiva = directiva => {
    setSelectedDirectiva(directiva);
    setShowDirAfiliadoViewModal(true);
  };

  return (
    <div className="list-container list1">
      <h1 className='table-title'>Lista de Directivas</h1>
      <div className="list-container-header d-flex justify-content-between">
        <div className="left-buttons">
          <button title="Volver" onClick={handleBack} className="icon-button btn-transparent">
            <i className="bi bi-arrow-left"></i>
          </button>
        </div>
        <div className="right-buttons d-flex gap-2">
          <Button variant="outline-primary" onClick={handleCreate}>
            <i className="bi bi-plus-circle"></i> Nueva Directiva
          </Button>
          <Button variant="outline-secondary" onClick={handleShowPreview}>
            <i className="bi bi-printer"></i> Imprimir
          </Button>
          <Button variant="outline-success" onDoubleClick={handleExportExcel}>
            <i className="bi bi-file-spreadsheet"></i> Exportar a Excel
          </Button>
        </div>
      </div>
      <div className='table-container'>
        <Table bordered hover size='sm'>
          <thead className='text-center align-middle'>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Fecha Inicio</th>
              <th>Fecha Fin</th>
              <th>Comité</th>
              <th>Observación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {directivasOrdenadas.map((directiva, index) => (
              <tr key={directiva.id}>
                <td>{index + 1}</td>
                <td>{directiva.nombre}</td>
                <td>{directiva.fechaini}</td>
                <td>{directiva.fechafin}</td>
                <td>{directiva.comite}</td>
                <td>{directiva.observacion}</td>
                <td>
                  <Button 
                    variant="outline-secondary" 
                    size="sm" 
                    title="Editar" 
                    onClick={() => handleEdit(directiva)}
                  >
                    <i className="bi bi-pencil"></i>
                  </Button>{' '}
                  <Button 
                    variant="outline-danger" 
                    size="sm" 
                    title="Eliminar" 
                    onClick={() => handleDelete(directiva.id)}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>{' '}
                  <Button 
                    variant="outline-primary" 
                    size="sm" 
                    title="Conformar Directiva" 
                    onClick={() => handleConformarDirectiva(directiva)}
                  >
                    <i className="bi bi-people"></i>
                  </Button>{' '}
                  <Button 
                    variant="outline-info" 
                    size="sm" 
                    title="Ver Directiva" 
                    onClick={() => handleViewDirectiva(directiva)}
                  >
                    <i className="bi bi-eye"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {directivasOrdenadas.length === 0 && (
          <p className="text-center mt-4">No hay directivas disponibles.</p>
        )}
      </div>
      <DirectivaForm currentDirectiva={currentDirectiva} showModal={showModal} handleClose={() => setShowModal(false)} />
      <CustomPrintModal
        show={showPreviewModal}
        handleClose={handleClosePreview}
        title1="LISTA DE DIRECTIVAS"
        title2=""
        content={printContent}
        copia={false}
      />
      {showDirAfiliadoModal && (
        <DirAfiliadoModal
          showModal={showDirAfiliadoModal}
          handleClose={() => setShowDirAfiliadoModal(false)}
          directiva={selectedDirectiva}
        />
      )}
      {showDirAfiliadoViewModal && (
        <DirAfiliadoView
          showModal={showDirAfiliadoViewModal}
          handleClose={() => setShowDirAfiliadoViewModal(false)}
          directiva={selectedDirectiva}
        />
      )}
    </div>
  );
};

export default DirectivaList;
