import React, { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { addComprobante, fetchComprobanteByNroWithDetails } from '../../reducers/comprobante_slice';
import { fetchPlanCuentas } from '../../reducers/planCuentas_slice';
import CustomPrintModal from '../UI/CustomPrintModal3';
import { getLocalDate, splitGlosa, formatCurrency } from '../../util/valueCalculator';
import { fetchPersonas } from '../../reducers/persona_slice';

const ComprobanteForm = ({ currentComprobante, nroComprobanteGenerado, handleClose }) => {
  const dispatch = useDispatch();
  const cuentas = useSelector((state) => state.planCuentas?.planCuentas || []);
  const isNuevoComprobante = !currentComprobante;
  const personas = useSelector((state) => state.persona.personas || []);
  const [fecha, setFecha] = useState(currentComprobante?.fecha || getLocalDate());
  const [gestion, setGestion] = useState(isNuevoComprobante ? new Date().getFullYear() : currentComprobante?.gestion || '');
  const [tipoComprobante, setTipoComprobante] = useState(currentComprobante?.tipo_comprobante || 'TRASPASOS');
  const [glosa, setGlosa] = useState(currentComprobante?.glosa || '');
  const [beneficiario, setBeneficiario] = useState(currentComprobante?.beneficiario || '');
  const [detalles, setDetalles] = useState([]);
  const [debeTotal, setDebeTotal] = useState(0);
  const [haberTotal, setHaberTotal] = useState(0);
  const [estadoComprobante, setEstadoComprobante] = useState(currentComprobante?.estado || 'BORRADOR');
  const [searchText, setSearchText] = useState('');
  const [isPrintEnabled, setIsPrintEnabled] = useState(!isNuevoComprobante);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [isBeneficiarioSelected, setIsBeneficiarioSelected] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    dispatch(fetchPlanCuentas());
    if (personas.length === 0) {
      dispatch(fetchPersonas());
    }
  }, [dispatch, personas.length]);

  useEffect(() => {
    const loadDetails = async () => {
      if (currentComprobante) {
        const res = await dispatch(fetchComprobanteByNroWithDetails(currentComprobante.nrocomprobante));
        const details = res?.payload?.detalles || [];
        
        if (cuentas.length === 0) {
          await dispatch(fetchPlanCuentas());
        }
        
        const updatedDetails = details.map(detalle => {
          const cuenta = cuentas.find(c => c.codigo_cuenta === detalle.codigo_cuenta && c.tipo_cuenta === 'DETALLE');
          return {
            ...detalle,
            nombreCuenta: cuenta ? cuenta.nombre_cuenta : 'CUENTA NO ENCONTRADA'
          };
        });
        
        setDetalles(updatedDetails);
      } else {
        setDetalles([{ codigo_cuenta: '', nombreCuenta: '', glosa: '', debe: '0', haber: '0' }]);
      }
    };

    loadDetails();
  }, [currentComprobante, cuentas, dispatch]);

  useEffect(() => {
    if (currentComprobante && currentComprobante.beneficiario) {
      setSearchText(currentComprobante.beneficiario);
      setBeneficiario(currentComprobante.beneficiario);
      setIsBeneficiarioSelected(true);
    }
  }, [currentComprobante]);

  useEffect(() => {
    updateTotals(detalles);
  }, [detalles]);

  const updateTotals = (detalles) => {
    const debeSum = detalles.reduce((sum, d) => sum + (parseFloat(d.debe) || 0), 0);
    const haberSum = detalles.reduce((sum, d) => sum + (parseFloat(d.haber) || 0), 0);
    setDebeTotal(debeSum);
    setHaberTotal(haberSum);
  };

  const isEditable = estadoComprobante !== 'REGISTRADO';
  const filteredPersonas = personas.filter((persona) => {
    const fullName = `${persona.nombre} ${persona.apellido1 || ''} ${persona.apellido2 || ''}`.toLowerCase();
    return fullName.includes(searchText.toLowerCase());
  });

  const handleSave = async (status) => {
    if (!glosa.trim()) {
      Swal.fire('Error', 'La glosa no puede estar vacía', 'error');
      return;
    }
    if (!beneficiario) {
      Swal.fire('Error', 'Debe seleccionar un beneficiario válido', 'error');
      return;
    }

    const comprobanteData = {
      nrocomprobante: currentComprobante ? currentComprobante.nrocomprobante : nroComprobanteGenerado,
      gestion: gestion,
      fecha: fecha,
      tipo_comprobante: tipoComprobante,
      glosa: glosa,
      beneficiario: beneficiario,
      estado: status,
      detalles: detalles
    };

    try {
      const response = await dispatch(addComprobante(comprobanteData));
      if (response?.meta?.requestStatus === 'fulfilled') {
        Swal.fire('Éxito', 'El comprobante ha sido registrado correctamente', 'success');
        setEstadoComprobante(status);
        setIsPrintEnabled(true);
        setIsModified(false);
      } else {
        Swal.fire('Error', 'No se pudo registrar el comprobante', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Ocurrió un error al registrar el comprobante', 'error');
    }
  };

  const handleRegister = () => {
    const today = new Date().toISOString().split('T')[0];

    if (fecha > today) {
      Swal.fire('Error', 'La fecha no puede ser futura', 'error');
      return;
    }

    if (new Date(fecha).getFullYear() !== gestion) {
      Swal.fire('Error', 'La fecha pertenece a otra gestión', 'error');
      return;
    }

    if (!glosa.trim()) {
      Swal.fire('Error', 'La glosa no puede estar vacía', 'error');
      return;
    }
    if (!beneficiario) {
      Swal.fire('Error', 'Debe seleccionar un beneficiario válido', 'error');
      return;
    }
 
    if (detalles.some(det => !det.codigo_cuenta)) {
      Swal.fire('Error', 'Todos los detalles deben tener una cuenta válida', 'error');
      return;
    }

    if (detalles.some(det => 
      (parseFloat(det.debe) <= 0 && parseFloat(det.haber) <= 0 || 
      parseFloat(det.debe) < 0 || 
      parseFloat(det.haber) < 0
    ))) {
      Swal.fire('Error', 'Debe haber al menos un valor positivo en Debe o Haber, y no se permiten valores negativos', 'error');
      return;
    }

    if (debeTotal !== haberTotal || debeTotal <= 0 || haberTotal <= 0) {
      Swal.fire('Error', 'Las sumas de Debe y Haber deben ser iguales y mayores a 0', 'error');
      return;
    }

    Swal.fire({
      title: '¿Estás seguro?',
      text: "Este proceso será irreversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, continuar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handleSave('REGISTRADO');
      }
    });
  };

  const addNewDetail = () => {
    setDetalles([...detalles, { codigo_cuenta: '', nombreCuenta: '', glosa: '', debe: '0', haber: '0' }]);
    setIsPrintEnabled(false);
  };

  const removeDetail = (index) => {
    const updatedDetails = [...detalles];
    updatedDetails.splice(index, 1);
    setDetalles(updatedDetails);
    updateTotals(updatedDetails);
    setIsPrintEnabled(false);
  };

  const { glosa1, glosa2, glosa3 } = splitGlosa(glosa);

  const handlePrint = () => {
    if (!isPrintEnabled) {
      Swal.fire('Error', 'Impresión no disponible. Debes guardar o registrar el comprobante primero.', 'error');
      return;
    }

    const content = {
      head: [['Código', 'Nombre de Cuenta', 'Glosa', 'Debe', 'Haber']],
      body: detalles.map((d) => [
        d.codigo_cuenta || 'N/D', 
        d.nombreCuenta || 'Cuenta no encontrada', 
        d.glosa, 
        formatCurrency(d.debe), 
        formatCurrency(d.haber)
      ])
    };

    setPrintContent(content);
    setShowPrintModal(true);
  };

  const handleDetailChange = (index, field, value) => {
    const updatedDetails = [...detalles];
    
    if (field === 'codigo_cuenta') {
      const cuentaEncontrada = cuentas.find(cuenta => 
        cuenta.codigo_cuenta === value && cuenta.tipo_cuenta === 'DETALLE');
      
      if (cuentaEncontrada) {
        updatedDetails[index] = { 
          ...updatedDetails[index], 
          codigo_cuenta: value, 
          nombreCuenta: cuentaEncontrada.nombre_cuenta 
        };
      } else {
        updatedDetails[index] = { 
          ...updatedDetails[index], 
          codigo_cuenta: value,
          nombreCuenta: 'CUENTA NO ENCONTRADA'
        };
      }
    } else {
      updatedDetails[index][field] = value;
    }
    
    setDetalles(updatedDetails);
    updateTotals(updatedDetails);
    if (estadoComprobante === 'BORRADOR') {
      setIsPrintEnabled(false);
    }
    setIsModified(true);
  };

  const handleGlosaChange = (e) => {
    setGlosa(e.target.value);
    setIsPrintEnabled(false);
    setIsModified(true);
  };

  const handleFechaChange = (e) => {
    setFecha(e.target.value);
    setIsPrintEnabled(false);
    setIsModified(true);
  };

  const handleTipoComprobanteChange = (e) => {
    setTipoComprobante(e.target.value);
    setIsPrintEnabled(false);
    setIsModified(true);
  };

  const handleCloseForm = () => {
    if (isModified) {
      Swal.fire({
        title: '¿Salir sin guardar?',
        text: 'Tienes cambios sin guardar. ¿Estás seguro de que deseas salir?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Sí, salir',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          handleClose();
        }
      });
    } else {
      handleClose();
    }
  };

  return (
    <div className="comprobante-form">
      <h3 className="text-center font-weight-bold">
        {estadoComprobante === 'BORRADOR' ? 'B O R R A D O R' : ' COMPROBANTE'} NRO: {currentComprobante ? currentComprobante.nrocomprobante : nroComprobanteGenerado}
      </h3>

      <Form>
        <div className="d-flex justify-content-between">
          <Form.Group>
            <Form.Label>Fecha</Form.Label>
            <Form.Control
              type="date"
              value={fecha}
              onChange={(e) => {
                if (isEditable) handleFechaChange(e);
                if (isNuevoComprobante) {
                  setGestion(new Date(e.target.value).getFullYear());
                }
              }}
              disabled={!isEditable}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>TIPO DE COMPROBANTE</Form.Label>
            <Form.Control
              as="select"
              value={tipoComprobante}
              onChange={(e) => isEditable && handleTipoComprobanteChange(e)}
              disabled={!isEditable}
            >
              <option value="TRASPASOS">TRASPASOS</option> 
              <option value="EGRESOS">EGRESOS</option>
              <option value="INGRESOS">INGRESOS</option>
            </Form.Control>
          </Form.Group>
        </div>

        <Form.Group>
          <Form.Label>Beneficiario</Form.Label>
          {isBeneficiarioSelected ? (
            <Form.Control
              type="text"
              value={searchText}
              readOnly
              onClick={() => setIsBeneficiarioSelected(false)}
            />
          ) : (
            <>
              <Form.Control
                type="text"
                placeholder="Buscar beneficiario"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                required
              />
              {(filteredPersonas.length > 0 || !searchText.trim()) && (
                <div className="beneficiario-dropdown">
                  {filteredPersonas.map((persona) => (
                    <div
                      key={persona.id}
                      className="beneficiario-item"
                      onClick={() => {
                        const nombreCompleto = `${persona.apellido1 || ''} ${persona.apellido2 || ''} ${persona.nombre}`.trim();
                        setSearchText(nombreCompleto);
                        setBeneficiario(nombreCompleto);
                        setIsBeneficiarioSelected(true);
                        setIsModified(true);
                      }}
                    >
                      {`${persona.apellido1 || ''} ${persona.apellido2 || ''} ${persona.nombre}`.trim()}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>GLOSA:</Form.Label>
          <Form.Control
            as="textarea"
            value={glosa}
            onChange={(e) => isEditable && handleGlosaChange(e)}
            placeholder="Descripción del comprobante"
            disabled={!isEditable}
            required
          />
        </Form.Group>

        <h5>Detalle del Comprobante</h5>
        <Table bordered striped hover className="table-details">
          <thead className="thead-dark">
            <tr>
              <th style={{ width: '15%' }}>Código de Cuenta</th>
              <th style={{ width: '25%' }}>Nombre de Cuenta</th>
              <th style={{ width: '30%' }}>Glosa</th>
              <th style={{ width: '10%', textAlign: 'right' }}>Debe</th>
              <th style={{ width: '10%', textAlign: 'right' }}>Haber</th>
              <th style={{ width: '10%' }}>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {detalles.map((detalle, index) => (
              <tr key={index} className={!detalle.codigo_cuenta ? 'table-warning' : ''}>
                <td>
                  {isEditable ? (
                    <Form.Control
                      as="select"
                      value={detalle.codigo_cuenta || ''}
                      onChange={(e) => handleDetailChange(index, 'codigo_cuenta', e.target.value)}
                      disabled={!isEditable}
                    >
                      <option value="">Seleccione Cuenta</option>
                      {cuentas
                        .filter((cuenta) => cuenta.tipo_cuenta === 'DETALLE')
                        .map((cuenta) => (
                          <option key={cuenta.id} value={cuenta.codigo_cuenta}>
                            {cuenta.codigo_cuenta.padEnd(18, '\u00A0')}
                          </option>
                        ))}
                    </Form.Control>
                  ) : (
                    <span className={!detalle.codigo_cuenta ? 'text-danger' : ''}>
                      {detalle.codigo_cuenta || 'No definido'}
                    </span>
                  )}
                </td>
                <td>
                  <span className={!detalle.nombreCuenta ? 'text-danger' : ''}>
                    {detalle.nombreCuenta || 'Cuenta no encontrada'}
                  </span>
                </td>
                <td>
                  <Form.Control
                    type="text"
                    value={detalle.glosa}
                    onChange={(e) => isEditable && handleDetailChange(index, 'glosa', e.target.value)}
                    disabled={!isEditable}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={detalle.debe}
                    onChange={(e) => isEditable && handleDetailChange(index, 'debe', e.target.value)}
                    disabled={!isEditable}
                    className="text-right"
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={detalle.haber}
                    onChange={(e) => isEditable && handleDetailChange(index, 'haber', e.target.value)}
                    disabled={!isEditable}
                    className="text-right"
                  />
                </td>
                <td>
                  {isEditable && detalles.length > 1 && (
                    <button 
                      type="button" 
                      className="icon-button btn-danger" 
                      onClick={() => removeDetail(index)}
                      title="Eliminar fila"
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  )}
                  {isEditable && index === detalles.length - 1 && (
                    <button 
                      className="icon-button btn-success ml-2" 
                      onClick={addNewDetail} 
                      disabled={!detalle.codigo_cuenta}
                      title="Agregar nueva fila"
                    >
                      <i className="bi bi-plus-circle"></i>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="totales mt-4 text-right">
          <strong>Debe Total: </strong> {formatCurrency(debeTotal)}<br />
          <strong>Haber Total: </strong> {formatCurrency(haberTotal)}
        </div>
        <div className="list-container-header mt-4">
          <div className="left-buttons">
            <button 
              title="Volver" 
              onClick={handleCloseForm} 
              className="icon-button btn-transparent"
            >
              <i className="bi bi-arrow-left"></i> Volver
            </button>
            <button
              type="button"
              title="Imprimir"
              className="icon-button btn-transparent"
              onClick={handlePrint}
              disabled={!isPrintEnabled}
            >
              <i className="bi bi-printer"></i> Imprimir
            </button>
          </div>
          <div className="right-buttons">
            {(isNuevoComprobante || estadoComprobante === 'BORRADOR') && (
              <>
                <button 
                  type="button" 
                  className="button-mine btn-mine-primary-outline" 
                  onClick={() => handleSave('BORRADOR')} 
                  disabled={!isEditable || !isModified}
                >
                  <i className="bi bi-save"></i> Pre-grabar
                </button>
                <button 
                  type="button" 
                  className="button-mine btn-success-outline ml-2" 
                  onClick={handleRegister} 
                  disabled={!isEditable}
                >
                  <i className="bi bi-check-circle"></i> Registrar
                </button>
              </>
            )}
          </div>
        </div>
      </Form>

      {showPrintModal && (
        <CustomPrintModal
          show={showPrintModal}
          handleClose={() => setShowPrintModal(false)}
          title1={`COMPROBANTE ${estadoComprobante === 'BORRADOR' ? 'BORRADOR' : ''}`}
          title2={`Nro.: ${currentComprobante ? currentComprobante.nrocomprobante : nroComprobanteGenerado}     Fecha: ${fecha}  `}
          title4={`Glosa: ${glosa1}`}
          title5={`${glosa2}`}  
          title3={`Beneficiario: ${beneficiario}`}  
          content={printContent}
          total1={`TOTALES                                                                                                       ${formatCurrency(debeTotal)}    ${formatCurrency(haberTotal)}`}
        />
      )}
    </div>
  );
};

export default ComprobanteForm;
