import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import { fetchCuotas } from '../../reducers/cuota_slice';
import { fetchNumeroCertificado } from '../../reducers/valoradoVen_slice';
import { Table, Form, InputGroup } from 'react-bootstrap';
import Swal from 'sweetalert2';
import AfiliadoCert1 from './AfiliadoCert1'; // Modal para Certificado 1
import AfiliadoCert2 from './AfiliadoCert2'; // Modal para Certificado 2
import AfiliadoEstadoCuentaModal from './AfiliadoEstadoCuentaModal'; // Modal para visualizar estado de cuenta
import { useNavigate } from 'react-router-dom';
import { fetchDatGeneral } from '../../reducers/datGeneral_slice';
import { apellidosNombreFullPersona } from '../../util/customFormatter';

const AfiliadosCert = () => {
    const dispatch = useDispatch();
    const afiliados = useSelector(state => state.afiliado.afiliados || []);
    const cuotas = useSelector(state => state.cuota.cuotas || []);
    const statusGetCuotas = useSelector(state => state.cuota.statusGet);
    const datgeneralList = useSelector((state) => state.datgeneral.datgeneralList);
    const [searchTerm, setSearchTerm] = useState('');
    const [showCertModal, setShowCertModal] = useState(false); // Estado para el modal de certificados
    const [showEstadoModal, setShowEstadoModal] = useState(false); // Estado para el modal de estado de cuenta
    const [certType, setCertType] = useState(null); // Tipo de certificado
    const [selectedAfiliado, setSelectedAfiliado] = useState(null);
    const [certificadoData, setCertificadoData] = useState(null); // Estado para los datos del certificado
    const xcuotas = datgeneralList[0]?.cuotas_tolerancia || 0;
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchDatGeneral());
        dispatch(fetchAfiliados());
        dispatch(fetchCuotas());
    }, [dispatch]);

    const handleCertificadoClick = async (tipo, afiliado) => {
        const cuotasAdeudadas = getCuotasAdeudadas(afiliado.id);

        if (cuotasAdeudadas > xcuotas) {
            Swal.fire({
                title: 'Afiliado con deudas',
                text: `El afiliado ${afiliado.persona.nombre} tiene ${cuotasAdeudadas} cuotas adeudadas. Debe ponerse al día antes de generar el certificado.`,
                icon: 'warning',
                confirmButtonText: 'Aceptar',
            });
            return;
        }

        try {
            const nombreCertificado = `CERTIFICADO${tipo}`;
            const response = await dispatch(fetchNumeroCertificado({
                id_afiliado: afiliado.id,
                nombre_certificado: nombreCertificado,
            })).unwrap();

            if (!response.numeroCertificado) {
                Swal.fire({
                    title: 'Sin certificados disponibles',
                    text: `Debe comprar más certificados tipo ${nombreCertificado} antes de poder generarlo.`,
                    icon: 'warning',
                    confirmButtonText: 'Aceptar',
                });
            } else {
                setCertificadoData(response); // Establecer datos completos del certificado
                setSelectedAfiliado(afiliado); // Establecer afiliado seleccionado
                setCertType(tipo); // Establecer tipo de certificado
                setShowCertModal(true); // Abrir el modal
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.error || 'No se pudo obtener el certificado.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        }
    };

    const handleVisualizarEstado = (afiliado) => {
        setSelectedAfiliado(afiliado); // Guardar el afiliado seleccionado
        setShowEstadoModal(true); // Abrir el modal de estado de cuenta
    };

    const handleBack = () => {
        navigate('/admin');
    };
    const getCuotasAdeudadas = (afiliadoId) => {
        const hoy = new Date(); // Fecha actual
        const diaActual = hoy.getDate(); // Día del mes actual
        const mesActual = hoy.getMonth(); // Mes actual (0-indexado)
        const anioActual = hoy.getFullYear(); // Año actual
      
        // Determinar la última cuota pagada según el día
        const ultimoMesEvaluado = diaActual <= 15 ? mesActual - 1 : mesActual;
      
        const cuotasFiltradas = cuotas.filter(cuota => {
          const cuotaFecha = new Date(cuota.cuota + "-01"); // Convertir "YYYY-MM" a Date
      
          return (
            cuota.id_afiliado === afiliadoId &&
            cuota.estado === "no_pagada" &&
            (cuotaFecha.getFullYear() < anioActual ||
              (cuotaFecha.getFullYear() === anioActual && cuotaFecha.getMonth() < ultimoMesEvaluado))
          );
        });
      
        return cuotasFiltradas.length; // Retornar número de cuotas adeudadas
      };
      
    const filteredAfiliados = afiliados.filter(afiliado =>
        `${apellidosNombreFullPersona(afiliado.persona, "ci")} ${afiliado.num_reg}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <div className="list-container list6">
                <div className="list-container-header">
                    <h2 className="header-title">Gestión de Certificados</h2>
                    <InputGroup size="sm" className="mt-2">
                        <InputGroup.Text>
                            <i className="bi bi-search"></i>
                        </InputGroup.Text>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Buscar por número registro, C.I. o nombre del afiliado"
                            className="form-control"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputGroup>
                    <div className="left-buttons">
                        <button title="Volver" onClick={handleBack} className="icon-button btn-transparent">
                            <i className="bi bi-arrow-left"></i>
                        </button>
                    </div>
                </div>

                {statusGetCuotas === 'loading' ? (
                    <p>Cargando cuotas...</p>
                ) : (
                    <div className="table-container list6">
                        <Table bordered hover size="sm">
                            <thead className="text-center align-middle">
                                <tr>
                                    <th style={{ width: '5%' }}>#</th>
                                    <th style={{ width: '10%' }}>Nro. Reg.</th>
                                    <th style={{ width: '20%' }}>1er Apellido</th>
                                    <th style={{ width: '20%' }}>2do Apellido</th>
                                    <th style={{ width: '25%' }}>Nombre</th>
                                    <th>CI</th>
                                    
                                    <th style={{ width: '17%' }}>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredAfiliados.map((afiliado, index) => (
                                    <tr key={afiliado.id}>
                                        <td>{index + 1}</td>
                                        <td>{afiliado.num_reg}</td>
                                        <td>{afiliado.persona.apellido1 || ""}</td>
                                        <td>{afiliado.persona.apellido2 || ""}</td>
                                        <td>{afiliado.persona.nombre}</td>
                                        <td>{afiliado.persona.ci}</td>
                                        
                                        <td className="text-center">
                                            <button
                                                className="icon-button btn-secondary-outline me-2"
                                                title="Generar Certificado 1"
                                                onClick={() => handleCertificadoClick(1, afiliado)}
                                            >
                                                <i className="bi bi-file-earmark-text"></i>
                                            </button>
                                            <button
                                                className="icon-button btn-secondary-outline me-2"
                                                title="Generar Certificado 2"
                                                onClick={() => handleCertificadoClick(2, afiliado)}
                                            >
                                                <i className="bi bi-file-earmark-text-fill"></i>
                                            </button>
                                            <button
                                                className="icon-button btn-secondary-outline me-2"
                                                title="Generar Certificado 3"
                                                onClick={() => handleCertificadoClick(3, afiliado)}
                                            >
                                                <i className="bi bi-file-earmark-richtext"></i>
                                            </button>
                                            <button
                                                className="icon-button btn-secondary-outline"
                                                title="Visualizar Estado"
                                                onClick={() => handleVisualizarEstado(afiliado)}
                                            >
                                                <i className="bi bi-eye"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </div>

            {showCertModal && certType === 1 && (
                <AfiliadoCert1
                    show={showCertModal}
                    handleClose={() => setShowCertModal(false)}
                    afiliado={selectedAfiliado}
                    certificadoData={certificadoData}
                />
            )}

            {showCertModal && certType === 2 && (
                <AfiliadoCert2
                    show={showCertModal}
                    handleClose={() => setShowCertModal(false)}
                    afiliado={selectedAfiliado}
                    certificadoData={certificadoData}
                />
            )}

            {showEstadoModal && (
                <AfiliadoEstadoCuentaModal
                    show={showEstadoModal}
                    handleClose={() => setShowEstadoModal(false)}
                    id_afiliado={selectedAfiliado.id}
                />
            )}
        </>
    );
};

export default AfiliadosCert;
