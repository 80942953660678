import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear instancia del servicio para el endpoint /auth
const authService = apiService("/auth");

// Thunk para cambiar contraseña
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ claveActual, nuevaClave,id }, { rejectWithValue }) => {
    try {
      // Enviar solicitud al backend
      const response = await authService.createSome("/change-password", {
        claveActual,
        nuevaClave,
        id,
      });
      return response.message; // Retornar el mensaje de éxito
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Error al cambiar la contraseña.";
      return rejectWithValue(errorMessage);
    }
  }
);

// Estado inicial del slice
const initialState = {
  status: "idle", // idle | loading | succeeded | failed
  error: null,
  successMessage: null,
};

// Definición del slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuthState(state) {
      state.status = "idle";
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.successMessage = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.successMessage = action.payload; // Mensaje de éxito
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload; // Mensaje de error
      });
  },
});

// Exportar acciones y reducer
export const { clearAuthState } = authSlice.actions;
export default authSlice.reducer;
