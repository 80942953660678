import React from 'react';
import CustomPrintModalR from '../UI/CustomPrintModalR';
import {formaFechaI, formatFecha, formatMonetaryValue } from '../../util/valueCalculator';

// Formato fijo para cantidad con 4 posiciones
const formatCantidad = (cantidad) => {
    return String(cantidad).padStart(4, ' ');
};
const splitConcepto = (concepto="", maxLength) => {
    const words = concepto.split(' ');
    let currentLine = '';
    let lines = [];

    words.forEach((word) => {
        if ((currentLine + word).length > maxLength) {
            lines.push(currentLine.trim());
            currentLine = word + ' ';
        } else {
            currentLine += word + ' ';
        }
    });

    if (currentLine.trim() !== '') {
        lines.push(currentLine.trim());
    }

    return {
        title4: lines[0] || '',          // Primera línea
        title5: lines.slice(1).join(' ') // Resto de las líneas concatenadas
    };
};

// Formato fijo para concepto con 40 posiciones
const formatConcepto = (concepto) => {
    const maxLineLength = 60;  // Longitud máxima de cada línea
    let lines = [];
    let words = concepto.split(' ');  // Dividir el concepto en palabras

    let currentLine = '';

    words.forEach((word) => {
        // Si agregar la palabra actual excede la longitud máxima de la línea, enviar la línea actual a lines y comenzar una nueva
        if ((currentLine + word).length > maxLineLength) {
            lines.push(currentLine.trim().padEnd(maxLineLength, ' '));  // Agregar la línea a 'lines' y ajustar
            currentLine = word + ' ';  // Comenzar una nueva línea con la palabra actual
        } else {
            currentLine += word + ' ';  // Agregar la palabra actual a la línea
        }
    });

    // Agregar la última línea si queda alguna
    if (currentLine.trim() !== '') {
        lines.push(currentLine.trim().padEnd(maxLineLength, ' '));
    }

    // Unir las líneas con saltos de línea
    return lines.join('\n');
};

// Función para extraer la fecha sin ajustes de zona horaria
export const fechaHoraBdAjustada = (fecha) =>{
    if(!fecha) return {}

    const fechar = new Date(fecha);
    const formattedDate = fechar.toLocaleDateString('es-ES');
    const horas = String(fechar.getHours()).padStart(2, '0'); // Asegura dos dígitos
    const minutos = String(fechar.getMinutes()).padStart(2, '0'); // Asegura dos dígitos
    //const segundos = String(fechar.getSeconds()).padStart(2, '0'); // Asegura dos dígitos
    const horaMinutos = `${horas}:${minutos}`; 

    return {completa:`${formattedDate} ${horaMinutos}`, formattedDate, horaMinutos}
}


const ImprimirRecibo = ({ show, handleClose, recibo, reciboDetalles = [], totalLiteral }) => {
    console.log("recibo ImprimirRecibo= ",recibo) 
    // Extraer la fecha en formato DD/MM/YYYY sin ajustar zona horaria
    const {formattedDate, horaMinutos} = fechaHoraBdAjustada(recibo.fecha);
   
    const { title4, title5 } = splitConcepto(recibo.concepto, 60);
    const title6=recibo.concepto2;
    const title7=recibo.concepto3;

  
    // Preparar el contenido del body formateando cada campo
    const bodyContent = reciboDetalles.length>0 ? {
        head: [['Cant.', 'Concepto', '        P.U.','      Monto', '      Descto.', '     Pagado']],
        body: reciboDetalles.map(detalle => [
            formatCantidad(detalle.cantidad),            // Cantidad con 4 posiciones
            formatConcepto(detalle.concepto),            // Concepto con 40 posiciones
            formatMonetaryValue(detalle.pu),             // P.U. formateado
            formatMonetaryValue(detalle.monto),          // Monto con formato 9.999.999,99
            formatMonetaryValue(detalle.descuento),      // Descuento formateado
            formatMonetaryValue(detalle.pagado)          // Pagado formateado
        ])
    }:{};

    return (
        <CustomPrintModalR
            show={show}
            handleClose={handleClose}
            title1={`RECIBO: ${recibo.nroRecibo}    ${recibo.pago === "C" ? "Caja" : recibo.pago === "B" ? "Banco" : recibo.pago}`}
            title2={`Fecha: ${formattedDate} ${horaMinutos}    Usuario: ${recibo.usuario?.persona?.nombre || ''} ${recibo.usuario?.persona?.apellido1 || ''} ${recibo.usuario?.persona?.apellido2 || ''}`} // Usar la fecha formateada sin zona horaria
            title3={`${recibo.beneficiario}`}
            title4={`${title4}`}
            title5={`${title5}`}
            title6={`${title6}`}
            title7={`${title7}`}
            content={bodyContent}
            total1={`Total: ${formatMonetaryValue(recibo.monto)} Bs.`}
            total2={`Son: ${totalLiteral} BOLIVIANOS`}
        />
    );
};

export default ImprimirRecibo;
