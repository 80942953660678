import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPlanCuentas } from '../../reducers/planCuentas_slice';
import { fetchAllComprobantesWithDetails } from '../../reducers/comprobante_slice';
import { Table, Modal, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { fechaActualBoliviaOnly } from '../../util/valueCalculator';
import { BiShow, BiHide, BiPrinter, BiRefresh, BiArrowBack } from 'react-icons/bi';
import moment from 'moment-timezone';

const PlanCuentasView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // Redux states
  const planCuentas = useSelector((state) => state.planCuentas.planCuentas || []);
  const comprobantes = useSelector((state) => state.comprobante?.comprobantes || []);
  const fecha = moment().tz("America/La_Paz").format('YYYY-MM-DD');
    const currentYear = new Date().getFullYear();
    const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');
    const currentDay = String(new Date().getDate()).padStart(2, '0');
  // Component states
  const [expandedCuentas, setExpandedCuentas] = useState({});
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(fecha);
  const [fechaFinal, setFechaFinal] = useState(fecha);
  const [isUpdating, setIsUpdating] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [includeZeroBalances, setIncludeZeroBalances] = useState(true);

  // Initialize component
  useEffect(() => {
    console.log("Inicializando componente...");
    dispatch(fetchPlanCuentas());
    dispatch(fetchAllComprobantesWithDetails());
    const currentDate = fechaActualBoliviaOnly();
    setFechaInicio(currentDate);
    setFechaFinal(currentDate);
    console.log("Fechas inicializadas:", currentDate);
  }, [dispatch]);

  // Función para parsear fechas con logs
  const parseDate = (dateString) => {
    console.log("Parseando fecha:", dateString);
    if (!dateString) return new Date();
    if (dateString instanceof Date) return dateString;
    const parsedDate = new Date(dateString);
    return !isNaN(parsedDate.getTime()) ? parsedDate : new Date();
  };

  // Función principal para calcular balances con logs detallados
  const calculateAccountBalances = useCallback(() => {
    console.group("=== CALCULO DE BALANCES ===");
    console.log("Iniciando cálculo con parámetros:", {
      fechaInicio,
      fechaFinal,
      totalCuentas: planCuentas.length,
      totalComprobantes: comprobantes.length
    });

    const cuentasMap = {};
    const parsedFechaInicio = parseDate(fechaInicio);
    const parsedFechaFinal = parseDate(fechaFinal);
    console.log("Fechas parseadas:", { parsedFechaInicio, parsedFechaFinal });

    // 1. Inicialización de cuentas
    console.log("Paso 1: Inicializando cuentas...");
    planCuentas.forEach((cuenta) => {
      cuentasMap[cuenta.codigo_cuenta] = {
        ...cuenta,
        debe: 0,
        haber: 0,
        saldo: 0,
        subcuentas: []
      };
    });
    console.log("Cuentas inicializadas:", cuentasMap);

    // 2. Procesar comprobantes y detalles
    console.log("Paso 2: Procesando comprobantes...");
    let totalDetallesProcesados = 0;
    comprobantes.forEach((comprobante) => {
      const fechaComprobante = parseDate(comprobante.fecha);
      console.log(`Procesando comprobante ${comprobante.id} con fecha: ${fechaComprobante}`);
      
      if (fechaComprobante >= parsedFechaInicio && 
          fechaComprobante <= parsedFechaFinal && 
          comprobante.estado === "REGISTRADO") {
        
        console.log(`Comprobante ${comprobante.id} cumple condiciones`);
        
        if (comprobante.detalles && Array.isArray(comprobante.detalles)) {
          comprobante.detalles.forEach((detalle) => {
            console.log(`Procesando detalle para cuenta ${detalle.codigo_cuenta}`, detalle);
            
            const cuenta = cuentasMap[detalle.codigo_cuenta];
            if (cuenta && cuenta.tipo_cuenta === 'DETALLE') {
              console.log(`Encontrada cuenta DETALLE ${cuenta.codigo_cuenta}`);
              
              const debe = Number(detalle.debe) || 0;
              const haber = Number(detalle.haber) || 0;
              console.log(`Sumando valores: debe=${debe}, haber=${haber}`);
              
              cuenta.debe += debe;
              cuenta.haber += haber;
              
              if (cuenta.naturaleza_cuenta === 'DEBE') {
                cuenta.saldo = cuenta.debe - cuenta.haber;
              } else {
                cuenta.saldo = cuenta.haber - cuenta.debe;
              }
              
              totalDetallesProcesados++;
              console.log(`Cuenta actualizada:`, cuenta);
            } else {
              console.log(`Cuenta ${detalle.codigo_cuenta} no es de DETALLE o no existe`);
            }
          });
        }
      }
    });
    console.log(`Total detalles procesados: ${totalDetallesProcesados}`);
    console.log("Estado después de copiar montos:", cuentasMap);

    // 3. Mayorización
    console.log("Paso 3: Iniciando mayorización...");
    const cuentasDetalle = Object.values(cuentasMap)
      .filter(cuenta => cuenta.tipo_cuenta === 'DETALLE' && (cuenta.debe !== 0 || cuenta.haber !== 0));
    
    console.log(`Cuentas DETALLE con movimientos: ${cuentasDetalle.length}`, cuentasDetalle);

    cuentasDetalle.forEach((cuentaDetalle) => {
      console.group(`Mayorizando cuenta ${cuentaDetalle.codigo_cuenta}`);
      let codigoPadre = cuentaDetalle.codigo_padre;
      let nivel = 0;
      
      while (codigoPadre && cuentasMap[codigoPadre] && nivel < 20) {
        const cuentaPadre = cuentasMap[codigoPadre];
        console.log(`Nivel ${nivel}: Procesando padre ${codigoPadre}`);
        
        console.log("Antes de sumar:", {
          padreDebe: cuentaPadre.debe,
          padreHaber: cuentaPadre.haber,
          detalleDebe: cuentaDetalle.debe,
          detalleHaber: cuentaDetalle.haber
        });
        
        cuentaPadre.debe += cuentaDetalle.debe;
        cuentaPadre.haber += cuentaDetalle.haber;
        
        if (cuentaPadre.naturaleza_cuenta === 'DEBE') {
          cuentaPadre.saldo = cuentaPadre.debe - cuentaPadre.haber;
        } else {
          cuentaPadre.saldo = cuentaPadre.haber - cuentaPadre.debe;
        }
        
        console.log("Después de sumar:", {
          padreDebe: cuentaPadre.debe,
          padreHaber: cuentaPadre.haber,
          padreSaldo: cuentaPadre.saldo
        });
        
        codigoPadre = cuentaPadre.codigo_padre;
        nivel++;
      }
      
      if (nivel >= 20) {
        console.warn("¡Alerta! Jerarquía muy profunda");
      }
      
      console.groupEnd();
    });
    
    console.log("Estado final después de mayorización:", cuentasMap);
    console.groupEnd();
    
    return cuentasMap;
  }, [planCuentas, comprobantes, fechaInicio, fechaFinal]);

  // Construir jerarquía para visualización
  const buildHierarchy = useCallback((cuentasMap, parentCodigo = null) => {
    return Object.values(cuentasMap)
      .filter(cuenta => cuenta.codigo_padre === parentCodigo)
      .map(cuenta => ({
        ...cuenta,
        subcuentas: buildHierarchy(cuentasMap, cuenta.codigo_cuenta)
      }));
  }, []);

  // Memoized balances
  const cuentasMap = useMemo(() => {
    console.log("Recalculando balances...");
    return calculateAccountBalances();
  }, [calculateAccountBalances]);

  const cuentasHierarchy = useMemo(() => {
    console.log("Construyendo jerarquía...");
    return buildHierarchy(cuentasMap);
  }, [cuentasMap, buildHierarchy]);

  const filteredHierarchy = useMemo(() => {
    console.log("Filtrando jerarquía...");
    const filter = (cuentas) => {
      return cuentas
        .filter(cuenta => includeZeroBalances || cuenta.debe !== 0 || cuenta.haber !== 0)
        .map(cuenta => ({
          ...cuenta,
          subcuentas: filter(cuenta.subcuentas)
        }));
    };
    return filter(cuentasHierarchy);
  }, [cuentasHierarchy, includeZeroBalances]);

  // Handlers
  const handleExpandToggle = (id) => {
    console.log(`Alternando expansión de cuenta ${id}`);
    setExpandedCuentas(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const handleDateChange = () => {
    console.log("Cambiaron fechas, habilitando actualización");
    setCanUpdate(true);
  };

  const handleUpdate = () => {
    console.log("Iniciando actualización...");
    setShowUpdateModal(false);
    setIsUpdating(true);
    setCanUpdate(false);

    Swal.fire({
      title: 'Actualizando...',
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    dispatch(fetchAllComprobantesWithDetails())
      .then(() => {
        console.log("Actualización completada");
        Swal.fire('Éxito', 'Datos actualizados', 'success');
      })
      .catch((error) => {
        console.error("Error en actualización:", error);
        Swal.fire('Error', 'Error al actualizar', 'error');
      })
      .finally(() => {
        console.log("Finalizando estado de actualización");
        setIsUpdating(false);
      });
  };

  const handlePrint = () => {
    console.log("Preparando para imprimir...");
    window.print();
  };

  const renderCuentas = (cuentas, level = 0) => {
    console.log(`Renderizando nivel ${level} con ${cuentas.length} cuentas`);
    return cuentas.map((cuenta) => (
      <React.Fragment key={cuenta.codigo_cuenta}>
        <tr>
          <td style={{ paddingLeft: `${level * 20}px` }}>
            {cuenta.subcuentas.length > 0 && (
              <button
                onClick={() => handleExpandToggle(cuenta.codigo_cuenta)}
                className="btn btn-sm btn-link"
              >
                {expandedCuentas[cuenta.codigo_cuenta] ? '▼' : '►'}
              </button>
            )}
            {cuenta.codigo_cuenta}
          </td>
          <td>{cuenta.nombre_cuenta}</td>
          <td className="text-end">{cuenta.debe.toFixed(2)}</td>
          <td className="text-end">{cuenta.haber.toFixed(2)}</td>
          <td className="text-end">{cuenta.saldo.toFixed(2)}</td>
        </tr>
        {expandedCuentas[cuenta.codigo_cuenta] && renderCuentas(cuenta.subcuentas, level + 1)}
      </React.Fragment>
    ));
  };

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Plan de Cuentas</h1>

      <div className="mb-4">
        <div className="d-flex align-items-center gap-3">
          <button onClick={() => navigate('/admin')} className="btn btn-outline-secondary">
            <BiArrowBack /> Volver
          </button>
          
          <div className="d-flex align-items-center gap-2">
            <label>Fecha Inicio:
              <input
                type="date"
                value={fechaInicio}
                onChange={(e) => {
                  setFechaInicio(e.target.value);
                  handleDateChange();
                }}
                className="form-control ms-2"
              />
            </label>
            
            <label>Fecha Final:
              <input
                type="date"
                value={fechaFinal}
                onChange={(e) => {
                  setFechaFinal(e.target.value);
                  handleDateChange();
                }}
                className="form-control ms-2"
              />
            </label>
          </div>
          
          <button
            onClick={() => setShowUpdateModal(true)}
            className="btn btn-outline-primary"
            disabled={!canUpdate}
          >
            <BiRefresh /> Actualizar
          </button>
          
          <button
            onClick={() => setIncludeZeroBalances(!includeZeroBalances)}
            className="btn btn-outline-secondary"
          >
            {includeZeroBalances ? <BiShow /> : <BiHide />}
            {includeZeroBalances ? " Ocultar ceros" : " Mostrar ceros"}
          </button>
          
          <button onClick={handlePrint} className="btn btn-outline-success">
            <BiPrinter /> Imprimir
          </button>
        </div>
      </div>

      <div className="table-responsive">
        <Table bordered hover>
          <thead className="table-dark">
            <tr>
              <th>Código</th>
              <th>Nombre</th>
              <th className="text-end">Debe</th>
              <th className="text-end">Haber</th>
              <th className="text-end">Saldo</th>
            </tr>
          </thead>
          <tbody>
            {renderCuentas(filteredHierarchy)}
          </tbody>
        </Table>
      </div>

      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Actualización</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Está seguro de actualizar los saldos con los comprobantes del rango seleccionado?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PlanCuentasView;