import createAxiosInstance from "../util/axiosInstance";

const apiService = (path) => {
  const api = createAxiosInstance(path);

  return {
    getAll: async () => {
      const response = await api.get('/');
      return response.data;
    },
    
    getAllSome: async (some) => {
      const response = await api.get(`/${some}`);
      return response.data;
    },

    getAllBySomeId: async (some, id) => {
      const response = await api.get(`/${some}/${id}`);
      return response.data;
    },
    getAllByGroup: async (groupName) => {
      const response = await api.get(`/group/${groupName}`);
      return response.data;
    },
    getAllAndSomeAssociation: async (some) => {
      const response = await api.get(`/${some}`);
      return response.data;
    },
    getById: async (id) => {
      const response = await api.get(`/${id}`);
      return response.data;
    },
    getBySomeAttribute: async (some, attribute) => {
      const response = await api.get(`/${some}/${attribute}`);
      return response.data;
    },
    getAllBySomeIdAndRol: async (some, rol, id) => {
      const response = await api.get(`/${some}/${rol}/${id}`);
      return response.data;
    },
    create: async (data) => {
      const response = await api.post('/', data, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return response.data;
    },
    createSome: async (endpoint, data) => {
      try {
        //console.log("Enviando petición POST al endpoint:", endpoint, "con los datos:", data);
        const response = await api.post(`${endpoint}`, data, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
       // console.log("Respuesta del servidor:", response.data);
        return response.data; // Axios ya convierte la respuesta JSON automáticamente
      } catch (error) {
       // console.error("Error en la solicitud POST:", error.message || error);
        throw error;  // Lanza el error para que sea manejado por el caller
      }
    },
    createWithFormDataByEndpoint: async (endpoint, data) => {
      const response = await api.post(`${endpoint}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      return response.data;
    },
    update: async (id, data) => {
      //console.log("en apiservice update=",id," data=",data)
      const response = await api.put(`/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    },
    updateSomeDatoJ: async (some, id, data) => {
      const response = await api.put(`${some}/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return response.data;
    },
    updateSomeDato: async (some, id, data) => {
      const response = await api.put(`${some}/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      return response.data;
    },
    remove: async (id) => {
      const response = await api.delete(`/${id}`);
      return response.data;
    },
    removeType: async (id, type) => {
      const response = await api.delete(`/${type}/${id}`);
      return response.data;
    },
    // Nuevos métodos para manejar FormData
    createWithFormData: async (data) => {
      console.log("Contenido del FormData createWithFormData:");
      const response = await api.post('/', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      return response.data;
    },
    
    updateWithFormData: async (id, data) => {
      console.log("Contenido del FormData updateWithFormData:");
      const response = await api.put(`/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    },
  };
};

export default apiService;
