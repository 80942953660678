import React, { useRef } from 'react';
import { PanZoom } from 'react-easy-panzoom';
import { Button } from 'react-bootstrap';

const VistaPreviaArchivo = ({ archivo, medida="" }) => {
  const panZoomRef = useRef(null); // Referencia para el componente PanZoom

  // Funciones para cambiar el zoom
  const handleZoomIn = () => {
    if (panZoomRef.current) {
      panZoomRef.current.zoomIn();
    }
  };

  const handleZoomOut = () => {
    if (panZoomRef.current) {
      panZoomRef.current.zoomOut();
    }
  };

  if (!archivo) return (<></>);
  console.log("VistaPreviaArchivoooooo",archivo)

  return (<>
    {medida==="normal" ? (<>{archivo.tipo === "image" ? (
      <div style={{ position: "relative", overflow: "hidden", maxHeight: "80vh" }}>
        <PanZoom
          ref={panZoomRef} // Asignamos la referencia al componente PanZoom
          enableBoundingBox={false} // Permite moverse más allá del contenedor
          enableZoom={true} // Activa el zoom
          enablePan={true} // Permite desplazamiento
          zoomSpeed={2} // Controla la velocidad del zoom (más rápido)
          minZoom={1} // Evita reducir demasiado la imagen
          maxZoom={5} // Aumenta hasta 5x (ajustado para no hacer zoom excesivo)
        >
          <img
            src={archivo.url}
            alt={archivo.nombre}
            style={{ width: "100%", cursor: "grab" }}
          />
        </PanZoom>

        {/* Botones de Zoom */}
        <div className="zoom-buttons" style={{ position: "absolute", top: "10px", right: "10px", zIndex: 10 }}>
          <Button onClick={handleZoomIn} size="sm">+</Button>
          <Button onClick={handleZoomOut} size="sm">-</Button>
        </div>
      </div>
    ) : archivo.tipo === "doc" ? (
      <iframe
        key={archivo.url}
        src={archivo.url}
        width="100%"
        height="650px"
        title="Vista Previa"
      ></iframe>
    ) : (
      <p>No hay vista previa disponible.</p>
    )}</>
):( <div className="text-center">
  {archivo.tipo === "image" || archivo.tipo === "doc" ? (
    <div style={{ position: "relative", overflow: "hidden", border: "1px solid #dbdbdb" }}>
      <iframe
        key={archivo.url}
        src={archivo.url}
        className="my-responsive-iframe"
        maxHeight="450px"
        title="Vista Previa"
      />
    </div>
  ) : (
    <div>
      <p>No hay vista previa disponible.</p>
      {/* Enlace de descarga si no hay vista previa */}
      <a href={archivo.url} target="_blank" download>
        Descargar archivo
      </a>
    </div>
  )}
</div>
)}
    </>
  );
};

export default VistaPreviaArchivo;
