import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Table, Form, ListGroup } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { fetchCuotasByAfiliado, modifyCuotaWithDeposito, fetchDepositoConCuotas } from '../../reducers/cuota_slice';
import { fetchBancos } from '../../reducers/banco_slice';
import moment from 'moment-timezone';
import { BsEye, BsEyeSlash, BsCamera, BsUpload } from 'react-icons/bs';
import EpsonPrintModal from '../UI/EpsonPrintModal'; 
import VistaPagoCuota from './VistaPagoCuota';
import { fetchDatGeneral } from "../../reducers/datGeneral_slice";
import { getCuotaNombre } from '../../util/valueCalculator';
import { formatNumber, nombreApellidosFullPersona } from '../../util/customFormatter';

const PagoCuotasModal = ({ show, handleClose, afiliado, openVistaPagoCuotaModal }) => {
  const dispatch = useDispatch();
  const [selectedCuotas, setSelectedCuotas] = useState([]);
  const [depositos, setDepositos] = useState([{ id_banco: '', monto: '', fecha_deposito: moment().format('YYYY-MM-DD'), docum: '', archivo: null }]);
  const [totalDepositado, setTotalDepositado] = useState(0);
  const [imagenPreview, setImagenPreview] = useState(null);
  const [imagenVisible, setImagenVisible] = useState([]);
  const [botonGrabarHabilitado, setBotonGrabarHabilitado] = useState(false);
  const [botonProformaHabilitado, setBotonProformaHabilitado] = useState(false);
  const [showEpsonPrintModal, setShowEpsonPrintModal] = useState(false); 
  const [proformaContent, setProformaContent] = useState(null); 
  const [paymentSaved, setPaymentSaved] = useState(false); 
  const [showVistaPagoCuota, setShowVistaPagoCuota] = useState(false); 
  const [depositoId, setDepositoId] = useState(null); 
  const [showCameraModal, setShowCameraModal] = useState(false); 
  const [nombreArchivo, setNombreArchivo] = useState(''); 
  const [depositIndex, setDepositIndex] = useState(null);
  const videoRef = useRef(null); 
  const datgeneralList = useSelector((state) => state.datgeneral.datgeneralList);
  const bancos = useSelector(state => state.banco.bancos || []);
  const cuotas = useSelector(state => state.cuota.cuotas.filter(cuota => cuota.estado === 'no_pagada'));
  const userId = useSelector(state => state.sesion.id);
  const xcaja = useSelector(state => state.sesion.caja);
  const xcuentaC = useSelector(state => state.sesion.cuentacaja);
  const [selectedDepositoId, setSelectedDepositoId] = useState(null);
  const [selectedReciboId, setSelectedReciboId] = useState(null);
  const xpago = "B";
  const [cuotasEditable, setCuotasEditable] = useState([]);
  const xcuentaM = datgeneralList[0]?.cuenta_CAT || '';
  const xcuentaN = datgeneralList[0]?.cuenta_CAB || '';
  const xcuotas = datgeneralList[0]?.cuotas_tolerancia || 0;
  const today = new Date().toLocaleDateString('es-BO', {
    timeZone: 'America/La_Paz',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).split('/').reverse().join('-');

  useEffect(() => {
    dispatch(fetchDatGeneral()); 
    if (afiliado && show) {
      dispatch(fetchCuotasByAfiliado(afiliado.id));
    }
    if (bancos.length === 0) {
      dispatch(fetchBancos());
    }
  }, [dispatch, afiliado, show, bancos.length]);

  useEffect(() => {
    console.log("UseEffectDepo",depositos)
    const total = depositos.reduce((total, deposito) => total + parseFloat(deposito.monto || 0), 0);
    setTotalDepositado(total);
    validarCondicionesParaGrabar(total);
    validarCondicionesParaProforma();
  }, [depositos, selectedCuotas]);

  useEffect(() => {
    if (show && cuotas.length > 0 && afiliado && cuotasEditable.length === 0) {
      const cuotasFiltradas = cuotas.filter((cuota) => cuota.id_afiliado === afiliado.id);
      setCuotasEditable(
        cuotasFiltradas.map((cuota) => ({
          ...cuota,
          descuento_cat: cuota.descuento_cat || 0,
          descuento_cab: cuota.descuento_cab || 0,
          observacion: cuota.observacion || '',
        }))
      );
      setSelectedCuotas(cuotasFiltradas.map(() => ({ cat: false, cab: false })));
    }
  }, [show, cuotas, afiliado]);

  const handleCuotaSelect = (index, type, checked) => {
    // Verificar si hay un recibo generado para el tipo específico (CAT o CAB)
    if (cuotas[index]) {
      // Validar para CAT
      if (type === 'cat' && 
          (cuotas[index].id_recibo_cat !== null || cuotas[index].monto_cat == 0)) {
          return; // No permitir marcar si ya tiene recibo o si el monto_cat es 0
      }
  
      // Validar para CAB
      if (type === 'cab' && 
          (cuotas[index].id_recibo_cab !== null || cuotas[index].monto_cab == 0)) {
          return; // No permitir marcar si ya tiene recibo o si el monto_cab es 0
      }
  }
    const newSelectedCuotas = [...selectedCuotas];
    
    if (checked) {
      // Marcar la cuota actual y todas las anteriores (excepto las bloqueadas)
      for (let i = 0; i <= index; i++) {
          // Verificar si la cuota debe ser bloqueada (no editable)
          const isBlocked = cuotas[i] && (
              (type === 'cat' && (cuotas[i].id_recibo_cat !== null || cuotas[i].monto_cat == 0)) ||
              (type === 'cab' && (cuotas[i].id_recibo_cab !== null || cuotas[i].monto_cab == 0))
          );
  
          if (isBlocked) {
              continue; // Saltar cuotas con recibo o monto=0
          }
  
          if (!newSelectedCuotas[i]) {
              newSelectedCuotas[i] = { cat: false, cab: false }; // Inicializar si no existe
          }
          newSelectedCuotas[i][type] = true; // Marcar la cuota
      }
  } else {
      // Desmarcar la cuota actual y todas las siguientes (excepto las bloqueadas)
      for (let i = index; i < newSelectedCuotas.length; i++) {
          // Verificar si la cuota debe ser bloqueada (no editable)
          const isBlocked = cuotas[i] && (
              (type === 'cat' && (cuotas[i]?.id_recibo_cat !== null || cuotas[i].monto_cat == 0)) ||
              (type === 'cab' && (cuotas[i]?.id_recibo_cab !== null || cuotas[i].monto_cab == 0))
          );
  
          if (isBlocked) {
              continue; // Saltar cuotas con recibo o monto=0
          }
  
          if (newSelectedCuotas[i]) {
              newSelectedCuotas[i][type] = false; // Desmarcar la cuota
          }
      }
  }
    
    setSelectedCuotas(newSelectedCuotas);
};

  const handleAddDeposito = () => {
    const lastDeposito = depositos[depositos.length - 1];
    if (lastDeposito.id_banco && lastDeposito.monto && lastDeposito.fecha_deposito && lastDeposito.docum && lastDeposito.archivo) {
      setDepositos([...depositos, { id_banco: '', monto: '', fecha_deposito: moment().format('YYYY-MM-DD'), docum: '', archivo: null }]);
    } else {
      Swal.fire('Error', 'Debe completar todos los campos del depósito antes de agregar uno nuevo.', 'error');
    }
  };

  const handleRemoveDeposito = (index) => {
    const newDepositos = depositos.filter((_, i) => i !== index);
    setDepositos(newDepositos);
  };

  const handleFileChange = (index, file) => {
    const newDepositos = [...depositos];
    newDepositos[index].archivo = file;
    setDepositos(newDepositos);
  };

  const handleChange = (index, field, value) => {

    // Validamos la fecha (si es necesario)
    if (field === 'fecha_deposito' && moment(value).isAfter(moment())) {
      Swal.fire('Error', 'La fecha de depósito no puede ser posterior a la fecha actual.', 'error');
      return;
    }
  
    // Actualizamos el estado con el nuevo array
    setDepositos(prevDep => {
      return prevDep.map((deposito, i) =>
        i === index ? { ...deposito, [field]: value } : deposito
      );
    });
  };
  
  const toggleImageVisibility = (index) => {
    const newVisibility = [...imagenVisible];
    newVisibility[index] = !newVisibility[index];
    setImagenVisible(newVisibility.map((visible, i) => (i === index ? visible : false)));

    if (newVisibility[index] && depositos[index].archivo && depositos[index].archivo.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => setImagenPreview(e.target.result);
      reader.readAsDataURL(depositos[index].archivo);
    } else {
      setImagenPreview(null);
    }
  };

  const validarCondicionesParaGrabar = (totalDepositos) => {
    const cuotasSeleccionadas = cuotasEditable.filter(
      (_, index) => selectedCuotas[index]?.cat || selectedCuotas[index]?.cab
    );
    
    const totalCuotas = cuotasSeleccionadas.reduce((total, cuota, index) => {
      let subtotal = 0;
      if (selectedCuotas[index]?.cat) {
        subtotal += parseFloat(cuota.monto_cat || 0) - parseFloat(cuota.descuento_cat || 0);
      }
      if (selectedCuotas[index]?.cab) {
        subtotal += parseFloat(cuota.monto_cab || 0) - parseFloat(cuota.descuento_cab || 0);
      }
      return total + subtotal;
    }, 0);

    console.log("Cuotas selec", cuotasSeleccionadas, totalCuotas,totalDepositos)
    const observacionesValidas = cuotasEditable.every((cuota, index) => {
      if (!(selectedCuotas[index]?.cat || selectedCuotas[index]?.cab)) return true;
      
      const tieneDescuentoCat = selectedCuotas[index]?.cat && (cuota.descuento_cat || 0) > 0;
      const tieneDescuentoCab = selectedCuotas[index]?.cab && (cuota.descuento_cab || 0) > 0;
      const tieneObservacionValida = cuota.observacion && cuota.observacion.trim().length > 0;
      
      return !(tieneDescuentoCat || tieneDescuentoCab) || tieneObservacionValida;
    });

    const camposCompletos = depositos.every(
      (deposito) =>
        deposito.id_banco &&
        deposito.monto &&
        deposito.fecha_deposito &&
        deposito.docum &&
        deposito.archivo
    );

    const totalCorrecto = totalDepositos === totalCuotas;
    const hayCuotasSeleccionadas = cuotasSeleccionadas.length > 0;

    setBotonGrabarHabilitado(
      camposCompletos && totalCorrecto && hayCuotasSeleccionadas && observacionesValidas && !paymentSaved
    );
  };

  const validarCondicionesParaProforma = () => {
    const hayCuotasSeleccionadas = cuotasEditable.some(
      (_, index) => selectedCuotas[index]?.cat || selectedCuotas[index]?.cab
    );
    setBotonProformaHabilitado(hayCuotasSeleccionadas && !paymentSaved); 
  };

  const handleProforma = () => {
    const cuotasSeleccionadas = cuotasEditable.filter(
      (_, index) => selectedCuotas[index]?.cat || selectedCuotas[index]?.cab
    );
    const totalCat = cuotasSeleccionadas.reduce(
      (total, cuota, index) =>
        selectedCuotas[index]?.cat
          ? total + (parseFloat(cuota.monto_cat || 0) - parseFloat(cuota.descuento_cat || 0))
          : total,
      0
    );
    
    const totalCab = cuotasSeleccionadas.reduce(
      (total, cuota, index) =>
        selectedCuotas[index]?.cab
          ? total + (parseFloat(cuota.monto_cab || 0) - parseFloat(cuota.descuento_cab || 0))
          : total,
      0
    );
    const totalGeneral = totalCat + totalCab;

    const detalle = [
      'PROFORMA PAGO DE CUOTAS',
      '=======================',
      `Afiliado: ${afiliado.persona.nombre} ${afiliado.persona.apellido1 || ""} ${afiliado.persona.apellido2 || ""}`,
      `C.I.: ${afiliado.persona.ci}`,
      `Nro. Reg.: ${afiliado.num_reg}`,
      '--------------------------------------',
      `TOTAL CAT = ${totalCat.toFixed(2)} Bs.`,
      `TOTAL CAB = ${totalCab.toFixed(2)} Bs.`,
      '______________________________________',
      `TOTAL = ${totalGeneral.toFixed(2)} Bs.`
    ];

    setProformaContent({ detalle });
    setShowEpsonPrintModal(true); 
  };

  const handlePay = async () => {
  
    let mensaje;
    if (depositos.length > 0 && depositos.some(deposito => deposito.monto)) {
      mensaje = "<ul style='list-style: none; padding: 0;'>";
      mensaje += depositos.map(deposito => {
        const banco = bancos.find(b => b.id === Number(deposito.id_banco));
        const siglaBanco = banco ? banco.sigla : 'Sin sigla';
        const cuentaBancaria = banco ? banco.cuenta_bancaria : 'Sin cuenta';
        return `<li>${siglaBanco} (${cuentaBancaria}): Bs. ${formatNumber(deposito.monto)} | #Ref.Dep.: ${deposito.docum}</li>`;
      })
    } else {
      mensaje = '<ul> <li>Pago de cuotas en efectivo</li>';
    }
    mensaje+="</ul> <p>Este proceso es irreversible</p> "
    const confirm = await Swal.fire({
      title: 'Confirmar pago',
      html: mensaje,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, registrar pago',
      reverseButtons:true
    });

    if (!confirm.isConfirmed) return;

    const cuotasToPay = cuotasEditable
      .map((cuota, index) => {
        const pagoCat = selectedCuotas[index]?.cat;
        const pagoCab = selectedCuotas[index]?.cab;
        
        return {
          ...cuota,
          monto_cat_pagada: pagoCat ? (cuota.monto_cat - cuota.descuento_cat) : 0,
          monto_cab_pagada: pagoCab ? (cuota.monto_cab - cuota.descuento_cab) : 0,
          estado: pagoCat && pagoCab ? 'pagada' : 'no_pagada',
          fecha_pago: moment().tz("America/La_Paz").format('YYYY-MM-DD HH:mm:ss'),
          id_usuario: userId
        };
      })
      .filter((_, index) => selectedCuotas[index]?.cat || selectedCuotas[index]?.cab);

    const totalCat = cuotasToPay.reduce(
      (total, cuota, index) => 
        selectedCuotas[index]?.cat 
          ? total + (parseFloat(cuota.monto_cat || 0) - parseFloat(cuota.descuento_cat || 0)) 
          : total,
      0
    );
    
    const totalCab = cuotasToPay.reduce(
      (total, cuota, index) => 
        selectedCuotas[index]?.cab 
          ? total + (parseFloat(cuota.monto_cab || 0) - parseFloat(cuota.descuento_cab || 0)) 
          : total,
      0
    );
    let concepto;
    if (depositos.length > 0 && depositos.some(deposito => deposito.monto)) {
      concepto = "Concepto: Pago cuotas " + depositos.map(deposito => {
        const banco = bancos.find(b => b.id === Number(deposito.id_banco));
        const siglaBanco = banco ? banco.sigla : 'Sin sigla';
        const cuentaBancaria = banco ? banco.cuenta_bancaria : 'Sin cuenta';
        return `${siglaBanco} (${cuentaBancaria}): Bs. ${parseFloat(deposito.monto).toFixed(2)} Trans.: ${deposito.docum}`;
      }).join(' | ');
    } else {
      concepto = 'Pago de cuotas en efectivo';
    }

    const recibo = {
      tipo: 'INGRESO',
      fecha: moment().tz("America/La_Paz").format('YYYY-MM-DD HH:mm:ss'),
      monto: totalCat + totalCab,
      beneficiario: `Arquitecto: ${afiliado.persona.apellido1 || ""} ${afiliado.persona.apellido2 || ""}, ${afiliado.persona.nombre}  Nro.Reg.: ${afiliado.num_reg}`,
      ci_persona: afiliado.persona.ci,
      concepto,
      concepto2: "",
      concepto3: "",
      id_caja: xcaja,
      id_usuario: userId,
      pago: depositos.length > 0 && depositos.some(deposito => deposito.monto) ? "B" : "C"
    };

    const reciboDetalles = cuotasToPay.flatMap((cuota, index) => {
      const detalles = [];
      
      if (selectedCuotas[index]?.cat) {
        detalles.push({
          id_caja: xcaja,
          tipo: 'INGRESO',
          fecha: moment().tz("America/La_Paz").format('YYYY-MM-DD HH:mm:ss'),
          concepto: `Pago cuota ${getCuotaNombre(cuota.cuota)} CAT=${cuota.monto_cat}`,
          cantidad: 1,
          pu: parseFloat(cuota.monto_cat || 0),
          monto: parseFloat(cuota.monto_cat || 0),
          pagado: parseFloat(cuota.monto_cat || 0) - parseFloat(cuota.descuento_cat || 0),
          descuento: cuota.descuento_cat,
          id_usuario: userId,
          codigo_cuentaC: xcuentaC,
          codigo_cuentaM: xcuentaM,
          pago: recibo.pago
        });
      }
      
      if (selectedCuotas[index]?.cab) {
        detalles.push({
          id_caja: xcaja,
          tipo: 'INGRESO',
          fecha: moment().tz("America/La_Paz").format('YYYY-MM-DD HH:mm:ss'),
          concepto: `Pago cuota ${getCuotaNombre(cuota.cuota)} CAB=${cuota.monto_cab}`,
          cantidad: 1,
          pu: parseFloat(cuota.monto_cab || 0),
          monto: parseFloat(cuota.monto_cab || 0),
          pagado: parseFloat(cuota.monto_cab || 0) - parseFloat(cuota.descuento_cab || 0),
          descuento: cuota.descuento_cab,
          id_usuario: userId,
          codigo_cuentaC: xcuentaC,
          codigo_cuentaM: xcuentaN,
          pago: recibo.pago
        });
      }
      
      return detalles;
    });

    try {
      const response = await dispatch(modifyCuotaWithDeposito({
        id_afiliado: afiliado.id,
        cuotas: cuotasToPay,
        depositos, 
        recibo, 
        reciboDetalles,
        cuentaCAT: xcuentaM,
        montoCAT: totalCat,
        cuentaCAB: xcuentaN,
        montoCAB: totalCab,
      })).unwrap();

      if (response.message === 'Cuotas, depósitos y recibos registrados correctamente') {
        Swal.fire('Éxito', 'Pago registrado exitosamente', 'success');
        setSelectedDepositoId(response.id_deposito);
        setSelectedReciboId(response.id_recibo);
        setPaymentSaved(true);
        dispatch(fetchCuotasByAfiliado(afiliado.id));
        handleClose({ idDeposito: response.id_deposito, idAfiliado: afiliado.id, idRecibo: response.id_recibo });
        
      } else {
        Swal.fire('Error', 'Ocurrió un problema', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Error al procesar el pago', 'error');
    }
  };

  const startCamera = (index) => {
    const iniciales = `${afiliado.persona.apellido1.charAt(0)}${afiliado.persona.apellido2.charAt(0)}${afiliado.persona.nombre.split(' ').map(n => n.charAt(0)).join('')}`;
    const randomNum = Math.floor(Math.random() * 1000) + 1;

    setNombreArchivo(`${iniciales}-${randomNum}.jpg`);
    setDepositIndex(index);
    setShowCameraModal(true);

    navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    }).catch((err) => {
      console.error("Error al acceder a la cámara: ", err);
    });
  };

  const captureImage = () => {
    if (!nombreArchivo) {
      Swal.fire('Error', 'Debe proporcionar un nombre para el archivo antes de capturar la imagen.', 'error');
      return;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL('image/jpeg');
    const blob = dataURLtoBlob(dataUrl);

    const file = new File([blob], nombreArchivo, { type: 'image/jpeg' });

    if (depositIndex !== null) {
      handleFileChange(depositIndex, file);
    }

    setShowCameraModal(false);
  };

  const handleCuotaChange = (index, field, value) => {
    setCuotasEditable((prevState) =>
      prevState.map((cuota, i) => {
        if (i === index) {
          if (field === 'descuento_cat' || field === 'descuento_cab') {
            const nuevoValor = parseFloat(value) || 0;
            if (
              (field === 'descuento_cat' && nuevoValor >= cuota.monto_cat) ||
              (field === 'descuento_cab' && nuevoValor >= cuota.monto_cab)
            ) {
              Swal.fire(
                'Descuento no válido',
                `El descuento no puede ser igual o mayor al monto de ${
                  field === 'descuento_cat' ? 'CAT' : 'CAB'
                }.`,
                'error'
              );
              return cuota;
            }
            return { ...cuota, [field]: nuevoValor };
          }
          return { ...cuota, [field]: value };
        }
        return cuota;
      })
    );
  };
  
  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  useEffect(() => {
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, [showCameraModal]);

  const totalCat = cuotasEditable.reduce(
    (total, cuota, index) =>
      selectedCuotas[index]?.cat
        ? total + (parseFloat(cuota.monto_cat || 0) - parseFloat(cuota.descuento_cat || 0))
        : total,
    0
  );

  const totalCab = cuotasEditable.reduce(
    (total, cuota, index) =>
      selectedCuotas[index]?.cab
        ? total + (parseFloat(cuota.monto_cab || 0) - parseFloat(cuota.descuento_cab || 0))
        : total,
    0
  );

  const totalGeneral = totalCat + totalCab;

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton style={{ backgroundColor: "#254159", color: 'white' }}>
          <Modal.Title>Pagar Cuotas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ position: 'relative' }}>
           
            {afiliado && (
              <div>
                <h5>Datos del Afiliado</h5>
                <ListGroup className='mb-4'>
                <ListGroup.Item><strong>Afiliado: {`${nombreApellidosFullPersona(afiliado.persona, "ci")}`} </strong> </ListGroup.Item>
                <ListGroup.Item><strong>N° Registro : {afiliado.num_reg} </strong> </ListGroup.Item>
                </ListGroup>
                {imagenPreview && (
              <img
                src={imagenPreview}
                alt="Imagen de Depósito"
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  maxWidth: '150px',
                  border: '1px solid #ccc',
                  padding: '5px',
                  borderRadius: '5px',
                  backgroundColor: '#fff'
                }}
              />
            )}
                <div className="table-container">
                     <h5>Registrar Depósitos</h5>
                <Table bordered hover size="sm">
                  <thead>
                    <tr>
                      <th style={{minWidth:"160px"}}>Banco</th>
                      <th>Monto (Bs)</th>
                      <th>Fecha Depósito</th>
                      <th>N° Ref. Dep.</th>
                      <th >Foto de respaldo</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {depositos.map((deposito, index) => (
                      <tr key={index}>
                        <td style={{minWidth:"160px"}}>
                          <Form.Control
                            as="select"
                            value={deposito.id_banco}
                            onChange={(e) => handleChange(index, 'id_banco', e.target.value)}
                            disabled={paymentSaved}
                          >
                            <option value="">- Sin seleccionar -</option>
                            {bancos.map(banco => (
                              <option key={banco.id} value={banco.id}>
                                {banco.sigla}
                              </option>
                            ))}
                          </Form.Control>
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={deposito.monto}
                            onChange={(e) => handleChange(index, 'monto', e.target.value)}
                            required
                            disabled={paymentSaved}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="date"
                            value={deposito.fecha_deposito}
                            onChange={(e) => handleChange(index, 'fecha_deposito', e.target.value)}
                            required
                            disabled={paymentSaved}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            value={deposito.docum}
                            onChange={(e) => handleChange(index, 'docum', e.target.value)}
                            disabled={paymentSaved}
                          />
                        </td>
                        <td style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Control
                          type="file"
                          onChange={(e) => handleFileChange(index, e.target.files[0])}
                          disabled={paymentSaved}
                          style={{ display: 'none' }} // Esto oculta el botón original
                          id={`file-input-${index}`} // Asignamos un ID único
                        />
                        {/* Creamos un botón personalizado para activar el file input */}
                        <label htmlFor={`file-input-${index}`} className="icon-button" style={{ cursor: 'pointer', padding:"2px 5px" }}>
                          <BsUpload /> {/* Usamos un ícono como el de subida */}
                        </label>
                          {deposito.archivo ? (
                            <>
                              <span style={{ marginLeft: '10px' }}>
                              <a
                                style={{ marginLeft: '10px', cursor: 'pointer' }}
                                href={URL.createObjectURL(deposito.archivo)}
                                target="_blank"
                                rel="noopener noreferrer"
                                title='Click para ver en una nueva pestaña'
                              >
                                {deposito.archivo.name}
                              </a>
                              </span>
                              <button
                                className="icon-button"
                                style={{ marginLeft: '10px' }}
                                onClick={() => toggleImageVisibility(index)}
                               type="button"
                              >
                                {imagenVisible[index] ? <BsEyeSlash /> : <BsEye />}
                              </button>
                            </>
                          ):(<>-Sin seleccionar-</>)}
                          <button
                            className="icon-button"
                            style={{ marginLeft: '10px' }}
                            onClick={() => startCamera(index)}
                          >
                            <BsCamera />
                          </button>
                        </td>
                        <td>
                          {!paymentSaved && index === depositos.length - 1 && (
                            <button className="icon-button" onClick={handleAddDeposito}>
                              <i className="bi bi-plus-circle"></i>
                            </button>
                          )}
                          {!paymentSaved && depositos.length > 1 && (
                            <button className="icon-button" onClick={() => handleRemoveDeposito(index)}>
                              <i className="bi bi-trash"></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                </div>
                <ListGroup className='mb-4'>
          <ListGroup.Item className='fw-bold' style={{fontSize:"1.05rem"}}>Total depositado: {totalDepositado.toFixed(2)} Bs.
          </ListGroup.Item>
          </ListGroup>
              </div>
            )}
          </div>

          <h5>Cuotas No Pagadas</h5>
          {cuotasEditable.length === 0 ? (
                <div  style={{ textAlign: 'center' }}> - Sin cuotas pendientes -
                </div>
              ) : (<>
          <Table hover size="sm" responsive>
            <thead style={{ position: 'sticky', top: '0', zIndex: '1'}}>
              <tr>
                <th>#</th>
                <th style={{width:"120px"}}>Cuota</th>
                <th>Paga CAT</th>
                <th>Monto CAT (Bs)</th>
                <th>Desc.CAT (Bs)</th>
                <th>Pagado CAT (Bs)</th>
                <th></th>
                <th>Paga CAB</th>
                <th>Monto CAB (Bs) </th>
                <th>Desc. CAB (Bs)</th>
                <th>Pagado CAB (Bs)</th>
                <th></th>
                <th>Observación</th>
              </tr>
            </thead>
            <tbody>
              {
                cuotasEditable.map((cuota, index) => {
                  const fontWeightCat =  selectedCuotas[index]?.cat? 'fw-bold' :""
                   const fontWeightCab =  selectedCuotas[index]?.cab? 'fw-bold' :""
                  
                   const disabledCat =  cuotas[index]?.id_recibo_cat !== null || parseFloat(cuotas[index].monto_cat) == 0 
                    const disabledCab =  cuotas[index]?.id_recibo_cab !== null || parseFloat(cuotas[index].monto_cab) == 0 
                   return (
                  <tr key={cuota.id} >
                      <td className={fontWeightCat || fontWeightCab}>{index+1}</td>
                      <td className={fontWeightCat || fontWeightCab} style={{width:"120px"}}>{getCuotaNombre(cuota.cuota)}</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={selectedCuotas[index]?.cat || false}
                        onChange={(e) => handleCuotaSelect(index, 'cat', e.target.checked)}
                        disabled={paymentSaved || disabledCat}
                      />
                    </td>
                    <td className={fontWeightCat}>{disabledCat?"_____" : `${(parseFloat(cuota.monto_cat || 0).toFixed(2))}`}</td>
                    <td className={fontWeightCat}>
                      <Form.Control
                        type="number"
                        min="0"
                        step="0.01"
                        value={cuota.descuento_cat}
                        onChange={(e) =>
                          handleCuotaChange(index, 'descuento_cat', parseFloat(e.target.value) || 0)
                        }
                        style={{ width: '80px' }}
                        disabled={paymentSaved || !selectedCuotas[index]?.cat}
                      />
                    </td>
                    <td className={fontWeightCat}>
                      {!disabledCat? (selectedCuotas[index]?.cat
                        ? (parseFloat(cuota.monto_cat || 0) - parseFloat(cuota.descuento_cat || 0)).toFixed(2) 
                        : '0.00'):"_____"}
                    </td>
                    <td></td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={selectedCuotas[index]?.cab || false}
                        onChange={(e) => handleCuotaSelect(index, 'cab', e.target.checked)}
                        disabled={paymentSaved || disabledCab}
                      />
                    </td>
                    <td className={fontWeightCab}>{!disabledCab?(parseFloat(cuota.monto_cab || 0).toFixed(2)):"_____"}</td>
                    
                    <td>
                      <Form.Control
                        type="number"
                        min="0"
                        step="0.01"
                        value={cuota.descuento_cab}
                        onChange={(e) =>
                          handleCuotaChange(index, 'descuento_cab', parseFloat(e.target.value) || 0)
                        }
                        style={{ width: '80px' }}
                        disabled={paymentSaved || !selectedCuotas[index]?.cab}
                      />
                    </td>
                    <td className={fontWeightCab}>
                      {!disabledCab? (selectedCuotas[index]?.cab 
                        ? (parseFloat(cuota.monto_cab || 0) - parseFloat(cuota.descuento_cab || 0)).toFixed(2) 
                        : '0.00') : "_____"}
                    </td>
                    <td></td>
                    <td>
                      <Form.Control
                        type="text"
                        value={cuota.observacion}
                        onChange={(e) =>
                          handleCuotaChange(index, 'observacion', e.target.value)
                        }
                        style={{ width: '200px' }}
                        placeholder=""
                        disabled={
                          paymentSaved || 
                          (!(selectedCuotas[index]?.cat && cuota.descuento_cat > 0) && 
                           !(selectedCuotas[index]?.cab && cuota.descuento_cab > 0))
                        }
                      />
                    </td>
                  </tr>
                )})
              }
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2" style={{ textAlign: 'right' }}><strong>Totales (Bs):</strong></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="fw-bold">
                    {totalCat.toFixed(2)}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="fw-bold">
                    {totalCab.toFixed(2)}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </Table>
          <ListGroup>
          <ListGroup.Item className='fw-bold' style={{fontSize:"1.1rem"}}>Total a pagar: {totalGeneral.toFixed(2)} Bs.
          </ListGroup.Item>
          </ListGroup>
          </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleProforma} disabled={!botonProformaHabilitado}>
            Proforma
          </Button>
          <Button variant="primary" onClick={handlePay} disabled={!botonGrabarHabilitado}>
            Pagar Cuotas
          </Button>
        </Modal.Footer>
      </Modal>

      {showEpsonPrintModal && (
        <EpsonPrintModal
          show={showEpsonPrintModal}
          handleClose={() => setShowEpsonPrintModal(false)}
          content={proformaContent} 
        />
      )}
      
      {showCameraModal && (
        <Modal show={showCameraModal} onHide={() => setShowCameraModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Capturar Imagen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <video ref={videoRef} style={{ width: '100%' }} />
            <Form.Group className="mt-3">
              <Form.Label>Nombre del Archivo</Form.Label>
              <Form.Control
                type="text"
                value={nombreArchivo}
                onChange={(e) => setNombreArchivo(e.target.value)}
                placeholder="Ingrese el nombre del archivo"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowCameraModal(false)}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={captureImage}>
              Capturar Imagen
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default PagoCuotasModal;