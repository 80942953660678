import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPortadas, removePortada } from '../../reducers/portada_slice';
import PortadasForm from './PortadasForm';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import CustomPrintModal from '../UI/CustomPrintModal';
import { useNavigate } from 'react-router-dom';

const PortadasList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const portadas = useSelector(state => state.portada.portadas || []);
  const [showModal, setShowModal] = useState(false);
  const [currentPortada, setCurrentPortada] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });

  useEffect(() => {
    dispatch(fetchPortadas());
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminarla!',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(removePortada(id)).unwrap().then(() => {
          Swal.fire('¡Eliminada!', 'La portada ha sido eliminada.', 'success');
        }).catch(error => {
          Swal.fire('Error', error.message || 'No se pudo eliminar la portada.', 'error');
        });
      }
    });
  };

  const handleEdit = portada => {
    setCurrentPortada(portada);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentPortada(null);
    setShowModal(true);
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      portadas.map(portada => ({
        ID: portada.id,
        "Título Corto": portada.tituloCorto,
        "Título Largo": portada.tituloLargo,
        "Descripción": portada.descripcion,
        "Fecha Inicio": portada.fechaIni,
        "Fecha Fin": portada.fechaFin || 'Sin fecha',
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Portadas");
    XLSX.writeFile(workbook, "portadas.xlsx");
  };

  const handleShowPreview = () => {
    const content = {
      head: [['#', 'Título Corto', 'Fecha Inicio', 'Fecha Fin']],
      body: portadas.map((portada, index) => [
        index + 1,
        portada.tituloCorto,
        portada.fechaIni,
        portada.fechaFin || 'Sin fecha',
      ]),
    };
    setPrintContent(content);
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const handleBack = () => {
    navigate('/admin'); // Cambia la ruta según tu aplicación
  };

  return (
    <div className="list-container list0">
      <h1 className="table-title">Lista de Portadas</h1>
      <div className="list-container-header d-flex justify-content-between">
        <div className="left-buttons">
          <button title="Volver" onClick={handleBack} className="icon-button btn-transparent">
            <i className="bi bi-arrow-left"></i>
          </button>
        </div>
        <div className="right-buttons d-flex gap-2">
          <Button variant="outline-primary" onClick={handleCreate}>
            <i className="bi bi-plus-circle"></i> Nueva Portada
          </Button>
          <Button variant="outline-secondary" onClick={handleShowPreview}>
            <i className="bi bi-printer"></i> Imprimir
          </Button>
          <Button variant="outline-success" onDoubleClick={handleExportExcel}>
            <i className="bi bi-file-spreadsheet"></i> Exportar a Excel
          </Button>
        </div>
      </div>
      <div className="table-container">
        <Table bordered hover size="sm">
          <thead className="text-center align-middle">
            <tr>
              <th>#</th>
              <th>Título Corto</th>
              <th>Fecha Inicio</th>
              <th>Fecha Fin</th> {/* Nueva columna para Fecha Fin */}
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {portadas.map((p, index) => (
              <tr key={p.id}>
                <td>{index + 1}</td> {/* Numeración basada en el índice */}
                <td>{p.tituloCorto}</td>
                <td>{p.fechaIni}</td>
                <td>{p.fechaFin || 'Sin fecha'}</td> {/* Mostrar Fecha Fin o 'Sin fecha' */}
                <td>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    title="Editar"
                    onClick={() => handleEdit(p)}
                  >
                    <i className="bi bi-pencil"></i>
                  </Button>{' '}
                  <Button
                    variant="outline-danger"
                    size="sm"
                    title="Eliminar"
                    onClick={() => handleDelete(p.id)}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {portadas.length === 0 && (
          <p className="text-center mt-4">No hay portadas disponibles.</p>
        )}
      </div>
      <PortadasForm currentPortada={currentPortada} showModal={showModal} handleClose={() => setShowModal(false)} />
      <CustomPrintModal
        show={showPreviewModal}
        handleClose={handleClosePreview}
        title1="LISTA DE PORTADAS"
        title2=""
        content={printContent}
        copia={false}
      />
    </div>
  );
};

export default PortadasList;
