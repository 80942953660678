import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addInmueble, modifyInmueble, fetchInmuebles } from '../../reducers/inmueble_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';
import { formatearDecimal } from '../../util/customFormatter';
import { fetchZonas } from '../../reducers/zona_slice';
import { fetchLugares, fetchLugaresDeptoTarija } from '../../reducers/lugar_slice';

const initialState = {
    cod_catastral: "",
    id_zona: "",
    id_lugar: "",
}
const initialFormDetalles = {title:"Nuevo Inmueble",
    headerColor:"#254159", 
    buttonText:"Crear" }

const FormInmueble = ({  onSelectInmueble=null, showModal, handleClose }) => {
    const [datos, setDatos] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isModified, setIsModified] = useState(false);

    const dispatch = useDispatch();
    const requestStatus = useSelector(state => state.inmueble.statusSend);
    const errorInRequest = useSelector(state => state.inmueble.error);
    const currentInmueble = useSelector(state => state.inmueble.current );
    const zonas = useSelector(state => state.zona.zonas || [] );
    const lugares = useSelector(state => state.lugar.lugares || []);
    const [formDetalles, setFormDetalles] = useState(initialFormDetalles);

    useEffect(() => {
        if(showModal)
       { if (currentInmueble) {
            setFormDetalles({title:"Editar Inmueble",headerColor:"#255957", buttonText:"Actualizar" })
            setDatos({ cod_catastral: currentInmueble.cod_catastral,
                id_zona: currentInmueble.id_zona, id_lugar:currentInmueble.zona.id_lugar});
            setIsModified(false);
        } else {
            resetForm();
        }
        dispatch(fetchZonas());
        dispatch(fetchLugaresDeptoTarija());}

    }, [currentInmueble, showModal]);

    const handleSubmit = async e => {
        e.preventDefault();

        let nuevosErrores = {
            ...errors,
            cod_catastral: datos.cod_catastral === '',
            id_zona: datos.id_zona === ''
        };
        setErrors(nuevosErrores);

        if (isModified) {
           if (!Object.values(nuevosErrores).includes(true)) {
            setErrors({});

            const inmuebleData = {cod_catastral: datos.cod_catastral, id_zona:datos.id_zona};
            const zonaSeleccionada = zonas.find(z => `${z.id}` === `${datos.id_zona}`);
            let mensajeAlerta = `        <hr>
              
              <div><strong>`
              if(!currentInmueble) mensajeAlerta+=`NUEVO `
              
                mensajeAlerta+=`INMUEBLE</strong></div>
              <hr>
            <ul style="list-style: none; padding: 0; text-align: left;">
    
              <li><strong>Cód. Catastral:</strong> ${datos.cod_catastral}</li>
              <li><strong>Zona:</strong> ${zonaSeleccionada?.nombre || "Sin seleccionar"}</li>
            <hr>
              </ul>
            `;
            
            const confirmacion = await Swal.fire({
                title: "Confirmar datos",
                html: mensajeAlerta,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: currentInmueble? "Actualizar" : "Crear",
                cancelButtonText: "Cancelar"
            });
            if (confirmacion.isConfirmed) {
                    if (currentInmueble) {
                        dispatch(modifyInmueble({ id: currentInmueble.id, inmueble: inmuebleData })).then(response => {
                            if (response.error || response.payload.error) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: response.payload.error || response.error.message || 'Error al actualizar el inmueble de proyecto.',
                                });
                            } else {
                                handleClose();
                                dispatch(fetchInmuebles());
                            }
                        });
                    } else {
                        dispatch(addInmueble(inmuebleData)).then(response => {
                            if (response.error || response.payload.error) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: response.payload.error || response.error.message || 'Error al crear el inmueble de proyecto.',
                                });
                            } else {
                                handleClose();
                                dispatch(fetchInmuebles());
                                const zona = zonas.find(z => z.id === response.payload.id_zona)
                                
                                if(onSelectInmueble) onSelectInmueble({...response.payload,zona})
                            }
                        });
                    }
        }else{

        }}
    } else {
            handleClose();
        }
    };

    const resetForm = () => {
        setDatos(initialState);
        setErrors({});
        setIsModified(false);
        setFormDetalles(initialFormDetalles);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let codEsvalido = false
        if (name === "cod_catastral") {
            const regex = /^(?!.*--)[A-Z0-9.-]+$/;
            codEsvalido = value && !regex.test(value) 
        }
            
        setErrors({ ...errors, [name]: value === '', cod_valido: codEsvalido });
        setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        setIsModified(true);
    }


    const filteredZonas = datos.id_lugar? [...zonas].filter(zona=> `${zona.id_lugar}` === `${datos.id_lugar}`) : zonas

    return (
        <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="lg" centered>
            <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
                <Modal.Title>{formDetalles.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {errors.mensaje && (
                    <Alert variant="danger">
                        {errors.mensaje }
                    </Alert>
                )}  
                <Form className='soft-labels'>
                    <Row xs={1}>
                        <Col className='col-form'>
                            <Form.Label>Código catastral</Form.Label>
                            <Form.Control
                                type="text"
                                name="cod_catastral"
                                placeholder='Código catastral del inmueble'
                                value={datos.cod_catastral}
                                onChange={(e)=>{!(currentInmueble?.cod_catastral) && handleInputChange(e)}}
                                isInvalid={errors.cod_catastral || errors.cod_valido}
                                required
                            />
                             <Form.Control.Feedback type='invalid'>
                                {errors.cod_catastral? "Se requiere el código catastral" : ""}
                                {errors.cod_valido? "El código catastral solo puede contener letras mayúsculas, números, puntos y guiones simples." : ""}
                            </Form.Control.Feedback>
                        </Col>
                        </Row>
                        <Row xs={1} md={2}>
                        <Col className='col-form'>
                        <Form.Label>Lugar (filtro opcional)</Form.Label>
                            <Form.Control
                                as="select"
                                name="id_lugar"
                                value={datos.id_lugar}
                                onChange={handleInputChange}
                            >
                                <option value="">Selecciona un lugar</option>
                                {lugares.map(lugar =>
                                 (<option key={lugar.id} value={lugar.id}>{`${lugar.nombre}`}</option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col className='col-form'>
                        <Form.Label>Zona</Form.Label>
                            <Form.Control
                                as="select"
                                name="id_zona"
                                value={datos.id_zona}
                                onChange={handleInputChange}
                                required
                                isInvalid={errors.id_zona}
                            >
                                <option value="">Selecciona una zona</option>
                                {filteredZonas.map(zona =>
                                 (<option key={zona.id} value={zona.id}>{`${zona.nombre} - ${zona.lugar.nombre}`}</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Se requiere que seleccione una zona.
                            </Form.Control.Feedback>
                        </Col>
                       
                        </Row>
                    <div className="d-flex justify-content-between mt-5">
                        <Button variant="outline-dark" onClick={ handleClose} type='button'>
                            Cancelar
                        </Button>
                        <button type="button" onClick={handleSubmit}  className="button-mine btn-success-outline" disabled={!isModified}>
                        <i className="bi bi-check-circle"></i>   {requestStatus !== "sending" ? formDetalles.buttonText: "Enviando..."}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default FormInmueble;
