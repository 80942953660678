import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import cabeceraCud from '../../assets/cabeceraCatR.png';
import { useSelector } from 'react-redux';

const CustomPrintModalR = ({ show, handleClose, title1, title2, title3, title4, title5,title6,title7, total1, total2, content, copia }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const { nombre } = useSelector(state => state.sesion);
    let pasotit=0.9;
    useEffect(() => {
        if (show) {
            generatePdf();
        }
    }, [show, content]);

    const generatePdf = () => {
        // Tamaño personalizado: 11 cm de ancho x 16.5 cm de largo
        const doc = new jsPDF({
            orientation: 'portrait', // Orientación vertical
            unit: 'cm', // Unidades en centímetros
            format: [14, 22], // Tamaño personalizado
        });

        const pageWidth = doc.internal.pageSize.getWidth(); // Ancho de la hoja en cm (11 cm)
        const pageHeight = doc.internal.pageSize.getHeight(); // Alto de la hoja en cm (16.5 cm)
        
        const marginLeftRight = 0.5; // Márgenes izquierdo y derecho en cm (reducidos para ocupar más espacio)
        const marginTop = 0.5; // Margen superior en cm
        const marginBottom = 1.5; // Margen inferior en cm
        const headerHeight = 2; // Altura de la cabecera
   
       
        // Función para agregar la marca de agua
        const addWatermark = (doc) => {
            doc.setFontSize(24); // Tamaño de fuente más pequeño para el nuevo tamaño
            doc.setTextColor(240, 240, 240); // Color de la marca de agua
            doc.setFont("helvetica", "bold");
            doc.text("COPIA", pageWidth / 2, pageHeight / 2, {
                angle: 45,
                align: 'center'
            });
        };

        // Función para agregar la cabecera
        const addHeader = (doc, pageNumber) => {
            try {
                // Dibujar la imagen de la cabecera
                doc.addImage(cabeceraCud, 'PNG', marginLeftRight, marginTop, doc.internal.pageSize.getWidth() - (marginLeftRight * 2) , headerHeight);

                // Dibujar el número de página (solo en páginas posteriores)
                if (pageNumber > 0) {
                    doc.setFontSize(8); // Tamaño de fuente más pequeño
                    doc.text(`Página ${pageNumber}`, pageWidth - marginLeftRight, marginTop + headerHeight + 0.5, { align: 'right' });
                }
            } catch (error) {
                console.error("Error al agregar la imagen:", error);
                doc.setFontSize(8);
                doc.text("Error al cargar la imagen", marginLeftRight, marginTop + 1);
            }
        };

        // Función para agregar el pie de página
        const addFooter = (doc) => {
            doc.setDrawColor(0, 50, 0); // Verde más oscuro
            doc.setLineWidth(0.1); // Grosor de la línea en cm
            doc.line(marginLeftRight, pageHeight - marginBottom, pageWidth - marginLeftRight, pageHeight - marginBottom);

            doc.setFontSize(8); // Tamaño de fuente más pequeño para el nuevo tamaño
            doc.setTextColor(0, 50, 0); // Verde más oscuro
            doc.setFont("times", "normal");
            doc.text('Calle La Madrid # 615 casi Esq. Junín - Celular.: (591-4)6640066 - cat.tarija@gmail.com', pageWidth / 2, pageHeight - marginBottom + 0.5, { align: 'center' });
            doc.text('Tarija - Bolivia', pageWidth / 2, pageHeight - marginBottom + 0.8, { align: 'center' });
            doc.text(
                `Impreso el: ${new Date().toLocaleString()} por: ${nombre} `,
                pageWidth - marginLeftRight,
                pageHeight - marginBottom + 1.1,
                { align: 'right' }
            );
        };

        // Validaciones: Asegurar que los valores no sean null o undefined
        const validTitle1 = title1 || '';
        const validTitle2 = title2 || '';
        const validTitle3 = title3 || '';
        const validTitle4 = title4 || '';
        const validTitle5 = title5 || '';
        const validTitle6 = title6 || '';
        const validTitle7 = title7 || '';
        
        // Incrementa pasotit si el título no está vacío
        if (validTitle3) pasotit += 0.4;
        if (validTitle4) pasotit += 0.4;
        if (validTitle5) pasotit += 0.4;
        if (validTitle6) pasotit += 0.4;
        if (validTitle7) pasotit += 0.4;
        
    
        const validTotal1 = total1 || '';
        const validTotal2 = total2 || '';

        // Inicializa el número de página
        let pageNumber = 0;

        // Función para agregar los títulos
        const addTitles = (doc) => {
            doc.setFontSize(10); // Tamaño de fuente más pequeño para los títulos
            doc.setFont("times", "bold");
            doc.setTextColor(0, 0, 0); // Texto en negro
            if (validTitle1) {
                doc.text(validTitle1, pageWidth / 2, marginTop + headerHeight + 0.3, { align: 'center' }); // Subido más arriba
            }
            if (validTitle2) {
                doc.text(validTitle2, marginLeftRight, marginTop + headerHeight + 0.8, { align: 'left' }); // Subido más arriba
            }
            doc.setFontSize(10); // Tamaño de fuente más pequeño para los subtítulos
            let paso=0.8;
            doc.setFont("times", "normal");
            if (validTitle3) { paso=paso+0.4;pasotit=paso;
                doc.text(validTitle3, marginLeftRight, marginTop + headerHeight + paso, { align: 'left' }); // Subido más arriba
            }
            if (validTitle4) { paso=paso+0.4;pasotit=paso;
                // Desplegar title4 normalmente sin dividir en líneas
                doc.text(validTitle4, marginLeftRight, marginTop + headerHeight + paso, { align: 'left', maxWidth: pageWidth - (marginLeftRight * 2) });
            }
            if (validTitle5) {paso=paso+0.4;pasotit=paso;
                // Desplegar title5 normalmente sin dividir en líneas
                doc.text(validTitle5, marginLeftRight, marginTop + headerHeight + paso, { align: 'left', maxWidth: pageWidth - (marginLeftRight * 2) });
            }
            if (validTitle6) {paso=paso+0.4;pasotit=paso;
                // Desplegar title5 normalmente sin dividir en líneas
                doc.text(validTitle6, marginLeftRight, marginTop + headerHeight + paso, { align: 'left', maxWidth: pageWidth - (marginLeftRight * 2) });
            }
            if (validTitle7) {paso=paso+0.4;pasotit=paso;
                // Desplegar title5 normalmente sin dividir en líneas
                doc.text(validTitle7, marginLeftRight, marginTop + headerHeight + paso, { align: 'left', maxWidth: pageWidth - (marginLeftRight * 2) });
            }
        };
        // Función para agregar los totales
        const addTotals = (doc) => {
            const startY = doc.autoTable.previous.finalY + 0.7; // Posición inicial después de la tabla
            doc.setFontSize(10); // Tamaño de fuente más pequeño para los totales
            doc.setFont("times", "bold");
            doc.setTextColor(0, 0, 0); // Texto en negro
        
            // Imprimir los totales
            if (validTotal1) {
                doc.text(validTotal1, marginLeftRight, startY);
            }
            if (validTotal2) {
                doc.text(validTotal2, marginLeftRight, startY + 0.4);
            }
        
            // Añadir espacio después de los totales
            const spaceAfterTotals = startY + 2.0; // Ajusta este valor según sea necesario
        
            // Agregar dos líneas para firmas en la misma fila
            doc.setFontSize(10); // Tamaño de fuente para las firmas
            doc.setFont("times", "normal"); // Fuente normal para las firmas
            doc.setTextColor(0, 0, 0); // Texto en negro
        
            // Primera línea para firma (izquierda)
            const firma1X = marginLeftRight; // Posición X de la primera línea
            const firma1Y = spaceAfterTotals + 0.5; // Posición Y de la primera línea
            doc.text("             __________________________                 __________________________", firma1X, firma1Y); // Línea para firma 1
        
        };
        // Dibujar marca de agua en la primera página si es copia
        if (copia) {
            addWatermark(doc);
        }

        if (typeof content === 'string') {
            addHeader(doc, pageNumber);
            addTitles(doc);
            doc.text(content, marginLeftRight, marginTop + headerHeight + 3);
            addFooter(doc);
        } else {
            addHeader(doc, pageNumber);
            //addTitles(doc); 
            autoTable(doc, {
                head: content.head,
                body: content.body,
                startY: marginTop + headerHeight + pasotit, 
                margin: { top: marginTop + headerHeight + pasotit, left: marginLeftRight, right: marginLeftRight, bottom: marginBottom },
                didDrawPage: function (data) {
                    pageNumber++;
                    addHeader(doc, pageNumber);
                    addTitles(doc);
                    if (copia) addWatermark(doc);
                    addFooter(doc);
                },
                styles: {
                    fontSize: 8,
                    lineColor: [0, 0, 0],
                    lineWidth: 0,
                    cellPadding: 0.1,
                    valign: 'top',
                },
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineWidth: 0,
                    lineColor: [0, 0, 0],
                    fontStyle: 'bold',
                    valign: 'middle',
                    align: 'right',
                },
                bodyStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineWidth: 0,
                    lineColor: [0, 0, 0],
                    valign: 'top',
                },
                columnStyles: {
                    0: { halign: 'left', cellWidth: 1 },  // Cantidad - izquierda
                    1: { halign: 'left', cellWidth: 5}, // Concepto - izquierda
                    2: { halign: 'right', cellWidth: 1.7 }, // Últimas 4 columnas alineadas a la derecha
                    3: { halign: 'right', cellWidth: 1.7},
                    4: { halign: 'right', cellWidth: 1.7 },
                    5: { halign: 'right', cellWidth: 1.7 },
                },
                pageBreak: 'auto',
            });
            
            
            
            addTotals(doc);
        }

        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{title1}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfUrl && (
                    <iframe src={pdfUrl} width="100%" height="500px" title="Vista previa del Recibo"></iframe>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomPrintModalR;

