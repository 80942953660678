import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPlanCuentas, addPlanCuenta, modifyPlanCuenta } from '../../reducers/planCuentas_slice';
import Swal from 'sweetalert2'; // Para mostrar alertas

const PlanCuentasForm = ({ currentCuenta, showModal, handleClose, isCreatingChild }) => {
  console.log("currecntcuenta=", currentCuenta);
  const dispatch = useDispatch();
  const planCuentasState = useSelector(state => state.planCuentas || { planCuentas: [] });
  const planCuentas = planCuentasState.planCuentas || [];
  const [nombreCuenta, setNombreCuenta] = useState('');
  const [tipoCuenta, setTipoCuenta] = useState('GRUPO');
  const [naturalezaCuenta, setNaturalezaCuenta] = useState('DEBE');
  const [codigo_cuenta, setCodigoCuenta] = useState('');
  const [isModified, setIsModified] = useState(false); // Estado para cambios

  // Solo llamamos fetchPlanCuentas si no hay datos cargados
  useEffect(() => {
    if (planCuentas.length === 0) {
      dispatch(fetchPlanCuentas());
    }
  }, [dispatch, planCuentas.length]);

  useEffect(() => {
    if (currentCuenta) {
      setNombreCuenta(currentCuenta.nombre_cuenta);
      setTipoCuenta(currentCuenta.tipo_cuenta);
      if (isCreatingChild) {
        setNaturalezaCuenta(currentCuenta.naturaleza_cuenta);
        const newCode = generateNextCode(currentCuenta);
        setCodigoCuenta(newCode);
      } else {
        setCodigoCuenta(currentCuenta.codigo_cuenta);
        setNaturalezaCuenta(currentCuenta.naturaleza_cuenta);
      }
    } else {
      resetForm();
      if (planCuentas.length === 0) {
        setTimeout(() => {
          setCodigoCuenta(generateNextCode(null));
        }, 0);
      } else {
        setCodigoCuenta(generateNextCode(null));
      }
    }
    setIsModified(false); // Reiniciar el estado de modificación
  }, [currentCuenta, isCreatingChild, planCuentas]);

  const resetForm = () => {
    setNombreCuenta('');
    setTipoCuenta('GRUPO');
    setNaturalezaCuenta('DEBE');
    setCodigoCuenta('');
  };

  const handleSave = () => {
    if (!nombreCuenta.trim()) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El campo "Nombre de la Cuenta" no puede estar vacío.',
      });
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'El proceso es irreversible. ¿Desea continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, continuar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        const upperCaseNombreCuenta = nombreCuenta.toUpperCase();

        const newCuenta = {
          nombre_cuenta: upperCaseNombreCuenta,
          tipo_cuenta: tipoCuenta,
          id_padre: currentCuenta ? currentCuenta.id : null,
          codigo_padre: currentCuenta ? currentCuenta.codigo_cuenta : null,
          naturaleza_cuenta: naturalezaCuenta,
          codigo_cuenta: codigo_cuenta,
          es_activa: true
        };
        const modCuenta = {
          nombre_cuenta: upperCaseNombreCuenta,
          tipo_cuenta: tipoCuenta,
          id_padre: currentCuenta ? currentCuenta.id_padre : null,
          codigo_padre: currentCuenta ? currentCuenta.codigo_cuenta : null,
          naturaleza_cuenta: naturalezaCuenta,
          codigo_cuenta: codigo_cuenta,
          es_activa: true
        };

        if (currentCuenta && !isCreatingChild) {
          dispatch(modifyPlanCuenta({ id: currentCuenta.id, cuenta: modCuenta })).unwrap().then(() => {
            resetForm();
            handleClose();
          });
        } else {
          dispatch(addPlanCuenta(newCuenta)).unwrap().then(() => {
            resetForm();
            handleClose();
          });
        }
      }
    });
  };

  const generateNextCode = (cuentaPadre) => {
    if (!cuentaPadre) {
      const cuentasNivel0 = planCuentas.filter(cuenta => cuenta.codigo_cuenta.length === 2);
      if (cuentasNivel0.length === 0) {
        return '01';
      } else {
        const maxCodigo = Math.max(...cuentasNivel0.map(cuenta => parseInt(cuenta.codigo_cuenta)));
        return (maxCodigo + 1).toString().padStart(2, '0');
      }
    } else {
      const subcuentas = planCuentas.filter(cuenta => cuenta.id_padre === cuentaPadre.id);
      if (subcuentas.length === 0) {
        return `${cuentaPadre.codigo_cuenta}01`;
      } else {
        const maxCodigo = Math.max(...subcuentas.map(cuenta => parseInt(cuenta.codigo_cuenta.slice(-2))));
        const newCode = `${cuentaPadre.codigo_cuenta}${(maxCodigo + 1).toString().padStart(2, '0')}`;
        return newCode;
      }
    }
  };

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
    setIsModified(true); // Activar el estado de modificación
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{currentCuenta ? (isCreatingChild ? 'Nueva Subcuenta' : 'Editar Cuenta') : 'Nueva Cuenta'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Código de la Cuenta</Form.Label>
            <Form.Control type="text" value={codigo_cuenta} readOnly />
          </Form.Group>

          <Form.Group>
            <Form.Label>Nombre de la Cuenta</Form.Label>
            <Form.Control
              type="text"
              value={nombreCuenta}
              onChange={handleChange(setNombreCuenta)}
              placeholder="Ingrese el nombre de la cuenta"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Tipo de Cuenta</Form.Label>
            <Form.Control as="select" value={tipoCuenta} onChange={handleChange(setTipoCuenta)} disabled={!isCreatingChild && !currentCuenta}>
              <option value="GRUPO">Grupo</option>
              <option value="DETALLE">Detalle</option>
            </Form.Control>
          </Form.Group>

          {!isCreatingChild && !currentCuenta && (
            <Form.Group>
              <Form.Label>Naturaleza de la Cuenta</Form.Label>
              <Form.Control as="select" value={naturalezaCuenta} onChange={handleChange(setNaturalezaCuenta)}>
                <option value="DEBE">Debe</option>
                <option value="HABER">Haber</option>
              </Form.Control>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={currentCuenta && !isModified}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlanCuentasForm;
