import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBanco, modifyBanco } from '../../reducers/banco_slice';
import { fetchPlanCuentas } from '../../reducers/planCuentas_slice';
import { Form, Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

const BancoForm = ({ banco, handleClose, show }) => {
  const dispatch = useDispatch();
  const cuentas = useSelector((state) => state.planCuentas?.planCuentas || []);
  const planCuentasStatus = useSelector((state) => state.planCuentas.status);

  const [formData, setFormData] = useState({
    banco: '',
    sigla: '',  // Añadido para gestionar la sigla
    cuenta_contable: '',
    cuenta_bancaria: '',
    estado: 'HABILITADA',
  });

  useEffect(() => {
    if (planCuentasStatus !== 'idle') {
      dispatch(fetchPlanCuentas());
    }
  }, [dispatch, planCuentasStatus]);

  useEffect(() => {
    if (banco) {
      setFormData(banco);
    } else {
      setFormData({
        banco: '',
        sigla: '',  // Campo sigla vacío al crear un nuevo banco
        cuenta_contable: '',
        cuenta_bancaria: '',
        estado: 'HABILITADA',
      });
    }
  }, [banco]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (banco) {
      dispatch(modifyBanco({ id: banco.id, banco: formData })).then(() => {
        Swal.fire('Éxito', 'El banco ha sido actualizado correctamente', 'success');
        handleClose();
      });
    } else {
      dispatch(addBanco(formData)).then(() => {
        Swal.fire('Éxito', 'El banco ha sido creado correctamente', 'success');
        handleClose();
      });
    }
  };

  const cuentasFiltradas = cuentas.filter(
    (cuenta) => cuenta.tipo_cuenta === 'DETALLE'
  );

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{banco ? 'Editar Banco' : 'Nuevo Banco'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBanco">
          <Form.Label>Banco</Form.Label>
          <Form.Control
            type="text"
            name="banco"
            value={formData.banco}
            onChange={handleChange}
            required
            maxLength={40} // Limita el número máximo de caracteres
          />

          </Form.Group>

          <Form.Group controlId="formSigla"> {/* Campo para Sigla */}
            <Form.Label>Sigla del Banco</Form.Label>
            <Form.Control
              type="text"
              name="sigla"
              value={formData.sigla}
              onChange={handleChange}
              required
              maxLength={15} 
            />
          </Form.Group>

          <Form.Group controlId="formCuentaContable">
            <Form.Label>Cuenta Contable</Form.Label>
            <Form.Control
              as="select"
              name="cuenta_contable"
              value={formData.cuenta_contable}
              onChange={handleChange}
              required
            >
              <option value="">Seleccione una cuenta</option>
              {cuentasFiltradas.map((cuenta) => (
                <option key={cuenta.id} value={cuenta.codigo_cuenta}>
                  {cuenta.nombre_cuenta}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formCuentaBancaria">
            <Form.Label>Cuenta Bancaria</Form.Label>
            <Form.Control
              type="text"
              name="cuenta_bancaria"
              value={formData.cuenta_bancaria}
              onChange={handleChange}
              required
              maxLength={25} 
            />
          </Form.Group>

          <Form.Group controlId="formEstado">
            <Form.Label>Estado</Form.Label>
            <Form.Control as="select" name="estado" value={formData.estado} onChange={handleChange}>
              <option value="HABILITADA">HABILITADA</option>
              <option value="INHABILITADA">INHABILITADA</option>
            </Form.Control>
          </Form.Group>

          <Button variant="primary" type="submit">
            {banco ? 'Actualizar' : 'Guardar'}
          </Button>
          <Button variant="secondary" onClick={handleClose} className="ml-2">
            Cancelar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BancoForm;
