import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTitulosProfesionalesByAfiliado } from '../../reducers/tituloProfesional_slice';
import { modifyAfiliado } from '../../reducers/afiliado_slice';
import { Modal, Table, Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

const VincularTituloModal = ({ showModal, handleClose, afiliado }) => {
  const dispatch = useDispatch();
  const titulos = useSelector((state) => state.tituloProfesional.titulos || []);
  const [selectedTituloId, setSelectedTituloId] = useState(afiliado?.id_titulo || '');

  useEffect(() => {
    console.log("Entra a VincularTitulos...")
    if (afiliado?.id) {
      dispatch(fetchTitulosProfesionalesByAfiliado(afiliado.id));
    }
  }, [dispatch, afiliado.id]);

  useEffect(() => {
    // Si el afiliado tiene un título vinculado, seleccionarlo por defecto
    setSelectedTituloId(afiliado?.id_titulo || '');
  }, [afiliado]);

  const handleSave = () => {
    if (!selectedTituloId) {
      Swal.fire('Error', 'Debe seleccionar un título para vincular.', 'error');
      return;
    }

    const updatedAfiliado = { ...afiliado, id_titulo: selectedTituloId };
    dispatch(modifyAfiliado({ id: afiliado.id, afiliado: updatedAfiliado }))
      .then(() => {
        Swal.fire('Éxito', 'El título fue vinculado correctamente.', 'success');
        handleClose();
      })
      .catch(() => {
        Swal.fire('Error', 'Hubo un problema al vincular el título.', 'error');
      });
  };

  const tituloActual = titulos.find((titulo) => titulo.id === afiliado?.id_titulo);

  return (
    <Modal show={showModal} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Vincular Título Profesional</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <p><strong>Número de Registro:</strong> {afiliado?.num_reg}</p>
          <p><strong>Nombre:</strong> {`${afiliado?.persona?.nombre} ${afiliado?.persona?.apellido1 || ""} ${afiliado?.persona?.apellido2 || ""}`}</p>
          <p>
            <strong>Título Vinculado:</strong>{' '}
            {tituloActual
              ? `${tituloActual.nombre_titulo} (${tituloActual.grado?.nombre || 'Sin grado'})`
              : 'Sin Vinculación'}
          </p>
        </div>
        <Table bordered hover>
          <thead className="text-center">
            <tr>
              <th>Seleccionar</th>
              <th>Código</th>
              <th>Nombre</th>
              <th>Grado</th>
              <th>Institución</th>
            </tr>
          </thead>
          <tbody>
            {titulos.map((titulo) => (
              <tr key={titulo.id}>
                <td className="text-center">
                  <Form.Check
                    type="radio"
                    name="tituloSeleccionado"
                    value={titulo.id}
                    checked={selectedTituloId === titulo.id}
                    onChange={() => setSelectedTituloId(titulo.id)}
                  />
                </td>
                <td>{titulo.id}</td>
                <td>{titulo.nombre_titulo}</td>
                <td>{titulo.grado?.nombre || 'Sin grado'}</td>
                <td>{titulo.institucion?.nombre || 'Sin institución'}</td>
              </tr>
            ))}
            {titulos.length === 0 && (
              <tr>
                <td colSpan="5" className="text-center">
                  No hay títulos profesionales registrados.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VincularTituloModal;
