import { Card, ListGroup, Container, Row, Col, TabContainer } from "react-bootstrap";
import { FaFileDownload } from "react-icons/fa";
import { useState } from "react";
import VistaPreviaDocumento from "./VistaPreviaDocumento";

const documentos = [
    { id: 1, nombre: "Reglamento de Aranceles de Honorarios Profesionales", url: "/reglamento_arancel_honorarios_cat.pdf", tipo: "doc" },
    { id: 2, nombre: "Tabla Arancel Mínimo de Honorarios Profesionales", url: "/tabla_aranceles.jpg", tipo: "image" },
  ];
  
  const DocumentosPropiedadIntelectual = () => {
    const [showModal, setShowModal] = useState(false);
    const [documentoSeleccionado, setDocumentoSeleccionado] = useState(null);
  
    const handleShowModal = (doc) => {
      console.log("Documento seleccionado:", doc); // 🔍 Verificar que el documento tenga el tipo correcto
      setDocumentoSeleccionado(doc);
      setShowModal(true);
    };
  
    return (
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <Card className="shadow-lg border-0">
              <Card.Body>
                <Card.Title className="text-center mb-3">
                  Documentos de Propiedad Intelectual
                </Card.Title>
                <ListGroup variant="flush">
                  {documentos.map((doc) => (
                    <ListGroup.Item 
                      key={doc.id} 
                      className="d-flex justify-content-between align-items-center"
                    >
                      {doc.nombre} 
                      <button 
                        onClick={() => handleShowModal(doc)} 
                        className="btn-sm icon-button btn-mine-secondary"
                      >
                         <i className="bi bi-eye"></i> Vista Previa
                      </button>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
  
        {/* Modal de Vista Previa */}
        <VistaPreviaDocumento 
          show={showModal} 
          handleClose={() => setShowModal(false)} 
          documento={documentoSeleccionado} 
        />
      </Container>
    );
  };
  
  export default DocumentosPropiedadIntelectual;
  