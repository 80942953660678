import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

const portadaService = apiService('/portadas');

// Thunks para operaciones CRUD
export const fetchPortadas = createAsyncThunk(
  'portada/fetchPortadas',
  async (_, { rejectWithValue }) => {
    try {
      const response = await portadaService.getAll();
      return response || [];
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

export const addPortada = createAsyncThunk(
  'portada/addPortada',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await portadaService.createWithFormData(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

export const modifyPortada = createAsyncThunk(
  'portada/modifyPortada',
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await portadaService.updateWithFormData(id, formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

export const removePortada = createAsyncThunk(
  'portada/removePortada',
  async (id, { rejectWithValue }) => {
    try {
      await portadaService.remove(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

// Estado inicial
const initialState = {
  portadas: [],
  current: null,
  status: 'idle',
  error: null,
};

// Slice
const portadaSlice = createSlice({
  name: 'portada',
  initialState,
  reducers: {
    setCurrentPortada(state, action) {
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortadas.fulfilled, (state, action) => {
        state.portadas = action.payload;
        state.status = 'idle';
      })
      .addCase(addPortada.fulfilled, (state, action) => {
        state.portadas.push(action.payload);
        state.status = 'idle';
      })
      .addCase(modifyPortada.fulfilled, (state, action) => {
        const index = state.portadas.findIndex(n => n.id === action.payload.id);
        if (index !== -1) {
          state.portadas[index] = action.payload;
        }
        state.status = 'idle';
      })
      .addCase(removePortada.fulfilled, (state, action) => {
        state.portadas = state.portadas.filter(n => n.id !== action.payload);
        state.status = 'idle';
      });
  }
});

export const { setCurrentPortada } = portadaSlice.actions;

export default portadaSlice.reducer;
