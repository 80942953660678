import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllCajasDiarias } from '../../reducers/caja_slice';
import { fetchDetallesReciboByCaja } from '../../reducers/recibo_slice';
import { fetchUsuarioById } from '../../reducers/usuario_slice';
import { Table, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import ActionButton from '../UI/Buttons/ActionButton';
import CustomPrintModal from '../UI/CustomPrintModal';
import CajaDiariaForm from './CajaDiariaForm';
import CajaDiariaCierre from './CajaDiariaCierre';
import ImprimirCajaDiaria from './ImprimirCajaDiaria';
import { fetchCajas } from '../../reducers/caja_slice';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
const CajaDiariaList = () => {
  const dispatch = useDispatch();
  const { id,usuario,nombre, rolActivo, roles } = useSelector(state => state.sesion);
  const xusuario = id;
  const idUsuario = xusuario;
  const cajaState = useSelector(state => state.caja || { cajas: [] });
  const cajasDiarias = cajaState.cajasDiarias || [];
  const reciboState = useSelector(state => state.recibo.detallesRecibo || []); // Mantén la definición de reciboState
  const [filteredCajas, setFilteredCajas] = useState(cajasDiarias);

  // Definir fechas de inicio y fin por defecto: desde el inicio del año hasta la fecha actual
  const currentDate = new Date();
  
  const fecha = moment().tz("America/La_Paz").format('YYYY-MM-DD');
  const currentYear = new Date().getFullYear();
  const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');
  const currentDay = String(new Date().getDate()).padStart(2, '0');

  const [startDate, setStartDate] = useState(fecha);
  const [endDate, setEndDate] = useState(fecha);

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [showForm, setShowForm] = useState(false);
  const [showCierreModal, setShowCierreModal] = useState(false);
  const [currentCajaDiaria, setCurrentCajaDiaria] = useState(null);
  const [totals, setTotals] = useState({ ingresos: 0, egresos: 0, saldo: 0 });
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [cajaToPrint, setCajaToPrint] = useState(null);
  const [detallesRecibo, setDetallesRecibo] = useState([]);
  const navigate = useNavigate();

  const today = new Date().toLocaleDateString('es-BO', {
    timeZone: 'America/La_Paz',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).split('/').reverse().join('-');

  useEffect(() => {
    dispatch(fetchAllCajasDiarias());
    dispatch(fetchCajas());
  }, [dispatch, idUsuario]);

  useEffect(() => {
    // Filtrar las cajas por el rango de fechas
    const filtered = cajasDiarias.filter(caja => {
      const cajaDate = new Date(caja.fechaAper);
      return cajaDate >= new Date(startDate) && cajaDate <= new Date(endDate);
    });
    setFilteredCajas(filtered.sort((a, b) => new Date(b.fechaAper) - new Date(a.fechaAper)));
  }, [startDate, endDate, cajasDiarias]);

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['NroCaja', 'FechaApertura', 'HoraApertura', 'FechaCierre', 'HoraCierre','MontoInicial','Ingresos','Egresos']],
      body: filteredCajas.map(caja => [
        caja.nrocaja,
        caja.fechaAper,
        caja.horaAper,
        caja.fechaCierre,
        caja.horaCierre,
        caja.montoInicial,
        caja.ingresos,
        caja.egresos
      ])
    };
    return content;
  };

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredCajas);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Cajas Diarias");
    XLSX.writeFile(workbook, "cajas_diarias.xlsx");
  };

  const handleCreate = () => {
    const aperturaExistente = cajasDiarias.some(caja => caja.fechaAper === today);
    if (aperturaExistente) {
      Swal.fire({
        icon: 'warning',
        title: 'Apertura ya realizada',
        text: 'Ya se aperturó la caja para esta fecha...',
        confirmButtonText: 'Aceptar',
      });
      return;
    }
    setCurrentCajaDiaria(null);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setCurrentCajaDiaria(null);
    dispatch(fetchAllCajasDiarias());
  };

  // Controlar cambios en el rango de fechas
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleCierre = (caja) => {
    dispatch(fetchDetallesReciboByCaja(caja.id_caja)).then((response) => {
      const detalles = Array.isArray(response.payload) ? response.payload : [];
      const detallesFiltrados = detalles.filter(det => det.id_caja === caja.id_caja && det.fecha === caja.fechaAper);
      const ingresos = detallesFiltrados
        .filter(det => det.tipo === 'INGRESO')
        .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);
      const egresos = detallesFiltrados
        .filter(det => det.tipo === 'EGRESO')
        .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);
      const saldo = parseFloat(caja.montoInicial) + ingresos - egresos;

      setTotals({ ingresos, egresos, saldo });
      setCurrentCajaDiaria(caja);
      setShowCierreModal(true);
    });
  };

  const handlePrint = (caja) => {
    if (!caja.fechaCierre) {
      Swal.fire('Error', 'Debe cerrar la caja antes de imprimir.', 'error');
      return;
    }

    setCajaToPrint(caja);

    dispatch(fetchDetallesReciboByCaja(caja.id_caja)).then((response) => {
        const detalles = Array.isArray(response.payload) ? response.payload : [];
      //  console.log("detalles=",detalles)
        const detallesFiltrados = detalles.filter(det => det.id_caja === caja.id_caja && det.fecha === caja.fechaAper);
       // console.log("detallesFiltrados=",detallesFiltrados)
        const ingresos = detallesFiltrados
          .filter(det => det.tipo === 'INGRESO')
          .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);
        const egresos = detallesFiltrados
          .filter(det => det.tipo === 'EGRESO')
          .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);
        const saldo = parseFloat(caja.montoInicial) + ingresos - egresos;
  
        setTotals({ ingresos, egresos, saldo });
        setCurrentCajaDiaria(caja);
        setDetallesRecibo(detallesFiltrados);
        setShowPrintModal(true);
      });
  };

  const handleBack = () => {
    navigate('/admin');
  };

  return (
    <div className="list-container list0">
      <h1 className="table-title">Lista de Aperturas Diarias de Caja</h1>
      <div className="list-content">
        <div className="list-container-header">
          <div className="right-buttons" style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            <input 
              type="date" 
              value={startDate} 
              onChange={handleStartDateChange} 
              className="form-control filter-input" 
              placeholder="Fecha de inicio"
            />
            <input 
              type="date" 
              value={endDate} 
              onChange={handleEndDateChange} 
              className="form-control filter-input" 
              placeholder="Fecha de fin"
            />
          </div>
           <div className='list-controls'>
            <div className="col-md-6">
            </div>
            <div className="col-md-6 text-right">
              <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
                Apertura
              </ActionButton>
            </div>
          </div>
        </div>
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent"><i className="bi bi-file-spreadsheet"></i></button>
          </div>
        </div>
        {filteredCajas.length === 0 ? (
          <p>No hay aperturas diarias de caja disponibles.</p>
        ) : (
          <div className="table-container">
            <Table bordered hover size="sm">
              <thead style={{ backgroundColor: "#b2aea3" }}>
                <tr>
                  <th>Nro Caja</th>
                  <th>Fecha Apertura</th>
                  <th>Hora Apertura</th>
                  <th>Monto Inicial</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {filteredCajas.map(caja => (
                  <tr key={caja.id}>
                    <td>{caja.nrocaja}</td>
                    <td>{caja.fechaAper}</td>
                    <td>{caja.horaAper}</td>
                    <td>{caja.montoInicial}</td>
                    <td>
                      <button 
                          className="icon-button btn-secondary-outline" 
                          onClick={() => handleCierre(caja)} 
                          disabled={caja.fechaCierre !== null} 
                      >
                          <i className="bi bi-lock"></i>
                      </button>
                      <button 
                          className="icon-button btn-secondary-outline" 
                          onClick={() => handlePrint(caja)} 
                          disabled={!caja.fechaCierre} 
                      >
                          <i className="bi bi-receipt"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {showForm && (
          <CajaDiariaForm 
            cajaDiaria={currentCajaDiaria}
            show={showForm} 
            handleClose={handleCloseForm} 
          />
        )}
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="RESUMEN MOVIMIENTO DIARIO"
          title2="=========================="
          content={printContent}
          copia={false}
        />
        {showCierreModal && (
          <CajaDiariaCierre
            show={showCierreModal}
            handleClose={() => {
              setShowCierreModal(false);
              dispatch(fetchAllCajasDiarias()); 
            }}
            cajaDiaria={currentCajaDiaria}
            detallesRecibo={reciboState} // `reciboState` se pasa correctamente
            totals={totals}
          />
        )}
        {showPrintModal && usuario && (
          <ImprimirCajaDiaria
            show={showPrintModal}
            handleClose={() => setShowPrintModal(false)}
            cajaDiaria={cajaToPrint}
            detallesRecibo={detallesRecibo} 
            totals={totals} 
            usuario={usuario}
            nombre={nombre}
          />
        )}
      </div>
    </div>
  );
};

export default CajaDiariaList;
