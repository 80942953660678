import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPlanCuentas, removePlanCuenta } from '../../reducers/planCuentas_slice';
import PlanCuentasForm from './PlanCuentasForm';
import Table from 'react-bootstrap/Table';
import CustomPrintModal from '../UI/CustomPrintModal';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import { useNavigate } from 'react-router-dom';

const PlanCuentasList = () => {
  const dispatch = useDispatch();
  const planCuentasState = useSelector(state => state.planCuentas || { planCuentas: [] });
  const planCuentas = planCuentasState.planCuentas || [];
  const [showModal, setShowModal] = useState(false);
  const [currentCuenta, setCurrentCuenta] = useState(null);
  const [isCreatingChild, setIsCreatingChild] = useState(false); 
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [expandedCuentas, setExpandedCuentas] = useState({});
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchPlanCuentas());
  }, [dispatch]);

  const handleDelete = cuenta => {
    if (!cuenta || !cuenta.id) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se puede eliminar la cuenta: ID de cuenta no válido.'
      });
      return;
    }

    const subcuentas = planCuentas.filter(c => c.id_padre === cuenta.id);
    if (subcuentas.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se puede eliminar una cuenta que tiene subcuentas. Elimine primero las subcuentas.'
      });
      return;
    }

    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarla!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removePlanCuenta(cuenta.id))
          .unwrap()
          .then(() => {
            Swal.fire('¡Eliminada!', 'La cuenta ha sido eliminada.', 'success');
          })
          .catch((error) => {
            Swal.fire('Error', error.error || 'Ocurrió un error al eliminar la cuenta', 'error');
          });
      }
    });
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleEdit = cuenta => {
    setCurrentCuenta(cuenta);
    setIsCreatingChild(false); 
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentCuenta(null);
    setIsCreatingChild(false); 
    setShowModal(true);
  };

  const handleCreateChild = (cuenta) => {
    setCurrentCuenta(cuenta); 
    setIsCreatingChild(true); 
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentCuenta(null);
    setIsCreatingChild(false); 
  };

  const toggleExpand = (id) => {
    setExpandedCuentas(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const buildHierarchy = (cuentas, parentId = null) => {
    return cuentas
      .filter(cuenta => cuenta.id_padre === parentId)
      .map(cuenta => ({
        ...cuenta,
        subcuentas: buildHierarchy(cuentas, cuenta.id)
      }));
  };

  const sortedCuentas = useMemo(() => {
    let sortedData = [...planCuentas];
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return buildHierarchy(sortedData);
  }, [planCuentas, sortConfig]);

  const renderCuentas = (cuentas, level = 0) => {
    return cuentas.map(cuenta => (
      <React.Fragment key={cuenta.id}>
        <tr>
          <td style={{ paddingLeft: `${level * 20}px` }}>
            {cuenta.subcuentas.length > 0 && (
              <button onClick={() => toggleExpand(cuenta.id)} className="icon-button btn-sm">
                {expandedCuentas[cuenta.id] ? <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i>}
              </button>
            )}
            {cuenta.codigo_cuenta}
          </td>
          <td>{cuenta.nombre_cuenta}</td>
          <td>{cuenta.tipo_cuenta}</td>
          <td>{cuenta.naturaleza_cuenta}</td>
          <td>
            <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(cuenta)}>
              <i className="bi bi-pencil"></i>
            </button>
            <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(cuenta)}>
              <i className="bi bi-trash"></i>
            </button>
            {/* Ocultar el botón de crear subcuenta si es una cuenta de detalle */}
            {cuenta.tipo_cuenta !== 'DETALLE' && (
              <button className="icon-button btn-secondary-outline" title="Crear Subcuenta" onClick={() => handleCreateChild(cuenta)}>
                <i className="bi bi-node-plus"></i>
              </button>
            )}
          </td>
        </tr>
        {expandedCuentas[cuenta.id] && renderCuentas(cuenta.subcuentas, level + 1)}
      </React.Fragment>
    ));
  };

  const handleExport = () => {
    const exportData = buildExportData(sortedCuentas);
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "PlanCuentas");
    XLSX.writeFile(workbook, "plan_cuentas.xlsx");
  };

  const buildExportData = (cuentas, level = 0) => {
    let result = [];
    cuentas.forEach(cuenta => {
      result.push({
        Código: cuenta.codigo_cuenta,
        Nombre: cuenta.nombre_cuenta,
        Tipo: cuenta.tipo_cuenta,
        Naturaleza: cuenta.naturaleza_cuenta,
        Nivel: level
      });
      if (cuenta.subcuentas && cuenta.subcuentas.length > 0) {
        result = result.concat(buildExportData(cuenta.subcuentas, level + 1));
      }
    });
    return result;
  };

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['Código', 'Nombre', 'Tipo', 'Naturaleza']],
      body: buildExportData(sortedCuentas).map(cuenta => [
        cuenta.Código,
        cuenta.Nombre,
        cuenta.Tipo,
        cuenta.Naturaleza,
        
      ])
    };
    return content;
  };

  return (
    <div className="list-container list4">
      <h1 className="mb-4">Plan de Cuentas</h1>

      <div className="list-container-header">
        <div className="right-buttons">
          <ActionButton onClick={handleCreate} outline icon="plus">
            Nueva Cuenta
          </ActionButton>
        </div>
      </div>

      <div className="list-container-header">
        <div className="left-buttons">
          <button title="Volver" onClick={() => navigate('/admin')} className="icon-button btn-transparent">
            <i className="bi bi-arrow-left"></i>
          </button>
        </div>
        <div className="right-buttons">
          <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent">
            <i className="bi bi-printer"></i>
          </button>
          <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent">
            <i className="bi bi-file-spreadsheet"></i>
          </button>
        </div>
      </div>

      <div className="list-content">
        {planCuentas.length === 0 ? (
          <p>No hay cuentas disponibles.</p>
        ) : (
          <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto', overflowX: 'auto' }}>
            <Table bordered hover size="sm">
              <thead style={{ backgroundColor: "#b2aea3" }}>
                <tr>
                  <th onClick={() => handleSort('codigo_cuenta')}>Código</th>
                  <th onClick={() => handleSort('nombre_cuenta')}>Nombre</th>
                  <th onClick={() => handleSort('tipo_cuenta')}>Tipo</th>
                  <th onClick={() => handleSort('naturaleza_cuenta')}>Naturaleza</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>{renderCuentas(sortedCuentas)}</tbody>
            </Table>
          </div>
        )}
        <PlanCuentasForm currentCuenta={currentCuenta} showModal={showModal} handleClose={handleClose} isCreatingChild={isCreatingChild} />
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={() => setShowPreviewModal(false)}
          title1="PLAN DE CUENTAS"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default PlanCuentasList;
