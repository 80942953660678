import "../styles/Inicio.scss";
import portadaImg from '../assets/portada.png';
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPortadas } from "../reducers/portada_slice";
import { fetchNoticias } from "../reducers/noticia_slice";
import { getUploadUrl } from "../util/valueCalculator";

const InicioPage = () => {
  const dispatch = useDispatch();
  const noticias = useSelector(state => state.noticia.noticias || []);
  const portadas = useSelector(state => state.portada.portadas || []);
  const usuario = useSelector(state => state.sesion.usuario);

  useEffect(() => {
    if (!usuario) {
      dispatch(fetchNoticias()); // Cargar noticias al entrar
      dispatch(fetchPortadas()); // Cargar portadas al entrar
    }
  }, [dispatch, usuario]);

  // Filtrar noticias válidas según la fecha actual
  const today = new Date();
  const noticiasFiltradas = noticias
    .filter(noticia => {
      const fechaIni = new Date(noticia.fechaIni);
      const fechaFin = new Date(noticia.fechaFin);
      return today >= fechaIni && today <= fechaFin;
    })
    .sort((a, b) => new Date(b.fechaIni) - new Date(a.fechaIni));

  // Buscar la primera portada con url="VIGENTE"
  const portadaVigente = portadas.find(portada => portada.url === "VIGENTE");
  let portada = portadaImg; // Valor predeterminado

  // Si hay una portada vigente, usar su URL como fondo
  if (portadaVigente && portadaVigente.foto) {
    portada = getUploadUrl(portadaVigente.foto);
  }

  let sections = (<></>);

  if (!usuario) {
    sections = (
      <div id="section1" className="home-container">
        <h1 className="home-subtitle">Bienvenido!</h1>
        <p className="home-description">
          Esta plataforma te permite gestionar los registros de afiliados, gestionar las cuotas, compras y ventas de valorados y más.
        </p>
        <div className="noticias-grid">
          {noticiasFiltradas.map((noticia, index) => (
            <div className="noticia-card" key={index}>
              <img
                src={getUploadUrl(noticia.foto)}
                alt={`Foto de ${noticia.tituloCorto}`}
                className="noticia-card-img"
              />
              <div className="noticia-card-content">
                <p className="noticia-card-titulo-corto">{noticia.tituloCorto}</p>
                <h3 className="noticia-card-titulo-largo">{noticia.tituloLargo}</h3>
                <p className="noticia-card-descripcion">{noticia.descripcion}</p>
                {noticia.url && (
                  <a
                    href={noticia.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="noticia-card-link"
                  >
                    Leer más
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="home-container img-background" style={{ backgroundImage: `url(${portada})` }}>
        <h1 className="home-title special-title">COLEGIO DE ARQUITECTOS</h1>
        <h2 className="home-subtitle special-title">TARIJA</h2>
      </div>
      {sections}
    </>
  );
};

export default InicioPage;
