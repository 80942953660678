import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchGrados, removeGrado } from '../../reducers/grado_slice';
import GradoForm from './GradoForm';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import Button from 'react-bootstrap/Button';

const GradoList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const grados = useSelector(state => state.grado.grados || []);
  const [showModal, setShowModal] = useState(false);
  const [currentGrado, setCurrentGrado] = useState(null);

  useEffect(() => {
    dispatch(fetchGrados());
  }, [dispatch]);

  // Ordenar grados por nombre
  const gradosOrdenados = useMemo(() => {
    return [...grados].sort((a, b) => a.nombre.localeCompare(b.nombre));
  }, [grados]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeGrado(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El grado ha sido eliminado.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.message || 'No se pudo eliminar el grado.', 'error');
        });
      }
    });
  };

  const handleEdit = grado => {
    setCurrentGrado(grado);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentGrado(null);
    setShowModal(true);
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      gradosOrdenados.map((grado, index) => ({
        Número: index + 1,
        Nombre: grado.nombre,
        Abreviado: grado.abreviado,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Grados");
    XLSX.writeFile(workbook, "grados.xlsx");
  };

  const handleBack = () => {
    navigate('/admin'); // Ajusta la ruta según tu aplicación
  };

  return (
    <div className="list-container list0">
      <h1 className="table-title">Lista de Grados</h1>
      <div className="list-container-header d-flex justify-content-between">
        {/* Botón Volver */}
        <div className="left-buttons">
          <button title="Volver" onClick={handleBack} className="icon-button btn-transparent">
            <i className="bi bi-arrow-left"></i>
          </button>
        </div>
        {/* Botones de acción */}
        <div className="right-buttons d-flex gap-2">
          <Button variant="outline-primary" onClick={handleCreate}>
            <i className="bi bi-plus-circle"></i> Nuevo Grado
          </Button>
          <Button variant="outline-success" onDoubleClick={handleExportExcel}>
            <i className="bi bi-file-spreadsheet"></i> Exportar a Excel
          </Button>
        </div>
      </div>
      <div className="table-container">
        <Table bordered hover size='sm'>
          <thead className='text-center align-middle'>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Abreviado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {gradosOrdenados.map((grado, index) => (
              <tr key={grado.id}>
                <td>{index + 1}</td>
                <td>{grado.nombre}</td>
                <td>{grado.abreviado}</td>
                <td>
                  <Button 
                    variant="outline-secondary" 
                    size="sm" 
                    title="Editar" 
                    onClick={() => handleEdit(grado)}
                  >
                    <i className="bi bi-pencil"></i>
                  </Button>{' '}
                  <Button 
                    variant="outline-danger" 
                    size="sm" 
                    title="Eliminar" 
                    onClick={() => handleDelete(grado.id)}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {gradosOrdenados.length === 0 && (
          <p className="text-center mt-4">No hay grados disponibles.</p>
        )}
      </div>
      <GradoForm
        currentGrado={currentGrado}
        showModal={showModal}
        handleClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default GradoList;
