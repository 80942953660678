import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDatGeneral, modifyDatGeneral } from '../../reducers/datGeneral_slice';
import { fetchPlanCuentas } from '../../reducers/planCuentas_slice';
import { Form, Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

const DatGeneralForm = ({ datGeneral, handleClose, show }) => {
  const dispatch = useDispatch();
  const cuentas = useSelector((state) => state.planCuentas?.planCuentas || []);
  const planCuentasStatus = useSelector((state) => state.planCuentas.status);

  const [formData, setFormData] = useState({
    nombre_colegio: '',
    direccion: '',
    telefono: '',
    email: '',
    representante: '',
    cuenta_CAT: '',
    cuenta_CAB: '',
    cuenta_visado: '',
    cuotas_tolerancia: 0, // Nuevo campo inicializado en 0
    estado: 'ACTIVO',
  });

  useEffect(() => {
    if (planCuentasStatus !== 'idle') {
      dispatch(fetchPlanCuentas());
    }
  }, [dispatch, planCuentasStatus]);

  useEffect(() => {
    if (datGeneral) {
      setFormData(datGeneral);
    } else {
      setFormData({
        nombre_colegio: '',
        direccion: '',
        telefono: '',
        email: '',
        representante: '',
        cuenta_CAT: '',
        cuenta_CAB: '',
        cuenta_visado: '',
        cuotas_tolerancia: 0, // Inicializar en 0
        estado: 'ACTIVO',
      });
    }
  }, [datGeneral]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'cuotas_tolerancia' ? Math.max(0, parseInt(value) || 0) : value, // Asegurar que cuotas_tolerancia sea >= 0
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (datGeneral) {
      dispatch(modifyDatGeneral({ id: datGeneral.id, datGeneral: { ...formData } })).then(() => {
        Swal.fire('Éxito', 'Datos generales actualizados correctamente', 'success');
        handleClose();
      });
    } else {
      dispatch(addDatGeneral({ ...formData })).then(() => {
        Swal.fire('Éxito', 'Datos generales creados correctamente', 'success');
        handleClose();
      });
    }
  };

  const cuentasFiltradas = cuentas.filter((cuenta) => cuenta.tipo_cuenta === 'DETALLE');

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{datGeneral ? 'Editar Datos Generales' : 'Nuevo Registro'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formNombreColegio">
            <Form.Label>Nombre del Colegio</Form.Label>
            <Form.Control
              type="text"
              name="nombre_colegio"
              value={formData.nombre_colegio}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formDireccion">
            <Form.Label>Dirección</Form.Label>
            <Form.Control
              type="text"
              name="direccion"
              value={formData.direccion}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formTelefono">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="text"
              name="telefono"
              value={formData.telefono}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formRepresentante">
            <Form.Label>Representante</Form.Label>
            <Form.Control
              type="text"
              name="representante"
              value={formData.representante}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formCuentaCAT">
            <Form.Label>Cuenta CAT</Form.Label>
            <Form.Control
              as="select"
              name="cuenta_CAT"
              value={formData.cuenta_CAT}
              onChange={handleChange}
              required
            >
              <option value="">Seleccione una cuenta</option>
              {cuentasFiltradas.map((cuenta) => (
                <option key={cuenta.id} value={cuenta.codigo_cuenta}>
                  {cuenta.nombre_cuenta}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formCuentaCAB">
            <Form.Label>Cuenta CAB</Form.Label>
            <Form.Control
              as="select"
              name="cuenta_CAB"
              value={formData.cuenta_CAB}
              onChange={handleChange}
              required
            >
              <option value="">Seleccione una cuenta</option>
              {cuentasFiltradas.map((cuenta) => (
                <option key={cuenta.id} value={cuenta.codigo_cuenta}>
                  {cuenta.nombre_cuenta}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formCuentaVisado">
            <Form.Label>Cuenta Visado</Form.Label>
            <Form.Control
              as="select"
              name="cuenta_visado"
              value={formData.cuenta_visado}
              onChange={handleChange}
              required
            >
              <option value="">Seleccione una cuenta</option>
              {cuentasFiltradas.map((cuenta) => (
                <option key={cuenta.id} value={cuenta.codigo_cuenta}>
                  {cuenta.nombre_cuenta}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formCuotasTolerancia"> {/* Nuevo campo cuotas_tolerancia */}
            <Form.Label>Cuotas de Tolerancia</Form.Label>
            <Form.Control
              type="number"
              name="cuotas_tolerancia"
              value={formData.cuotas_tolerancia}
              onChange={handleChange}
              min="0" // Asegurar que no sea menor a 0
              required
            />
          </Form.Group>

          <Form.Group controlId="formEstado">
            <Form.Label>Estado</Form.Label>
            <Form.Control
              as="select"
              name="estado"
              value={formData.estado}
              onChange={handleChange}
            >
              <option value="ACTIVO">ACTIVO</option>
              <option value="INACTIVO">INACTIVO</option>
            </Form.Control>
          </Form.Group>

          <Button variant="primary" type="submit">
            {datGeneral ? 'Actualizar' : 'Guardar'}
          </Button>
          <Button variant="secondary" onClick={handleClose} className="ml-2">
            Cancelar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default DatGeneralForm;
