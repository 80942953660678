import { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { apellidosNombreFullPersona } from "../../util/customFormatter";

const initialFormDetalles = {title:"Cambio de situación",
    headerColor:"#254159", 
    buttonText:"Actualizar" }

const CambioSituacionModal = ({ currentAfiliado, showModal, handleClose }) => {

    const situaciones = useSelector(state => state.situacion.situaciones)
    const [selectedSituacion, setSelectedSituacion] = useState('');
    const [formDetalles, setFormDetalles] = useState(initialFormDetalles);

    const handleSituacionChange = (event) => {
        setSelectedSituacion(event.target.value);
      };
    return (
        <>
         <Modal show={showModal} onHide={() => { handleClose(); }} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
                <Modal.Title>{formDetalles.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 
        <Form className="soft-labels">
                     <p> <Form.Label>N° de registro : </Form.Label> {currentAfiliado?.num_reg}</p>
                    <p> <Form.Label>Afiliado : </Form.Label> {currentAfiliado?.persona ? apellidosNombreFullPersona(currentAfiliado?.persona):""}</p>
                    <p><Form.Label>Situación actual : </Form.Label> {currentAfiliado?.situacion.nombre}</p>
        
            <Row md={2}>
                <Col>
                <Form.Label>Nueva situación:</Form.Label>
                <Form.Control
                    as="select"
                    className="form-control"
                    size="sm"
                    value={selectedSituacion}
                    onChange={handleSituacionChange}
                    >
                    <option value="" disabled>- Seleccionar situación -</option>
                    {situaciones.filter(s=>s.alias && !s.alias.includes("preinscrito")).map(situ =>
                        <option key={situ.id} value={situ.id}>{situ.nombre}</option>
                    )}
                    </Form.Control>
                </Col>
            </Row>
            <div className="d-flex justify-content-between mt-5">
            <button className="button-mine btn-cancel-outline" type="button" onClick={handleClose}>
              Cancelar
            </button>
            <button className="button-mine btn-mine-primary-outline" type="submit">
              {formDetalles.buttonText}
            </button>
          </div>
        </Form> 
        </Modal.Body>
        </Modal>
        </>
    )
}

export default CambioSituacionModal;