import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "lugares"
const lugarService = apiService('/lugares');

// Thunks para las operaciones CRUD
export const fetchLugares = createAsyncThunk('lugar/fetchLugares', async () => {
  const response = await lugarService.getAll();
  return response || [];
});
// Thunks para las operaciones CRUD
export const fetchLugaresDeptoTarija = createAsyncThunk('lugar/fetchLugares', async () => {
  const response = await lugarService.getAllByGroup("TARIJA");
  return response || [];
});

export const fetchLugarCat = createAsyncThunk('lugar/fetchLugarById', async (id) => {
  const response = await lugarService.getBySomeAttribute("cat","");
  return response;
});

export const fetchLugarById = createAsyncThunk('lugar/fetchLugarById', async (id) => {
  const response = await lugarService.getById(id);
  return response;
});

export const addLugar = createAsyncThunk('lugar/addLugar', async (lugar, { rejectWithValue }) => {
  try {
    const response = await lugarService.create(lugar);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyLugar = createAsyncThunk('lugar/modifyLugar', async ({ id, lugar }, { rejectWithValue }) => {
  try {
    const response = await lugarService.update(id, lugar);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeLugar = createAsyncThunk('lugar/removeLugar', async (id, { rejectWithValue }) => {
  try {
    await lugarService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  lugares: [],
  current: null,
  status: 'idle',
  error: null,
};

// Definición del slice
const lugarSlice = createSlice({
  name: 'lugar',
  initialState,
  reducers: {
    setCurrentLugar(state, action) {
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLugares.fulfilled, (state, action) => {
        state.lugares = action.payload;
      })
      .addCase(fetchLugares.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLugares.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchLugarById.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(addLugar.fulfilled, (state, action) => {
        state.lugares.push(action.payload);
      })
      .addCase(addLugar.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addLugar.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(modifyLugar.fulfilled, (state, action) => {
        const index = state.lugares.findIndex(lugar => lugar.id === action.payload.id);
        if (index !== -1) {
          state.lugares[index] = action.payload;
        }
      })
      .addCase(modifyLugar.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(modifyLugar.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeLugar.fulfilled, (state, action) => {
        state.lugares = state.lugares.filter(lugar => lugar.id !== action.payload);
      })
      .addCase(removeLugar.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeLugar.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      });
  }
});

export const { setCurrentLugar } = lugarSlice.actions;

export default lugarSlice.reducer;
