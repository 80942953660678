import { redirect } from "react-router-dom";
import { toast } from "react-toastify";

export function getTokenDuration() {
    const storedExpirationDate = localStorage.getItem('expiration');
    const expirationDate = new Date(storedExpirationDate);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();
    return duration;
}
export function getAuthToken() {
    const token = localStorage.getItem('token');
    const tokenDuration = getTokenDuration();
    
    if(!token) {
        return null;
    }

    if(tokenDuration < 0 ) {
        return 'EXPIRED';
    }
    return token;
}

export function tokenLoader() {
    if(!checkAuthLoader()){
        return redirect('/login');
    }
    return getAuthToken();
}

export function checkAuthLoader() {
    const token = getAuthToken();

    if(!token || token === "EXPIRED") {
        return false
    }
    return true;
}


export function checkLoaderByRol(rolAliasList) {
    const rolActivo = localStorage.getItem('rolActivo');
    if(!checkAuthLoader()){
        return redirect('/login');
    }else{
        let rolParseado;
        try {
            rolParseado = rolActivo ? JSON.parse(rolActivo) : null;
        } catch (error) {
            toast.error("Error de sesión, inicie sesión nuevamente")
            return redirect('/login'); // Valor por defecto en caso de error
        }
        if(rolAliasList.every(r=> rolParseado?.alias!==r)){
            toast.error("No cuenta con los permisos para acceder a esa ruta.")
            return redirect('/admin')
        }
        return null;
    }
   
}