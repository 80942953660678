import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  usuario: null,
  nombre: null,
  rolActivo: null,
  caja: null,
  cuentacaja: null,
  id_persona: null,
  afiliado: null,
  roles: [],
  cambio:null
};

const sesionSlice = createSlice({
  name: "sesion",
  initialState: initialState,
  reducers: {
    login(state, action) {
      localStorage.setItem('sessionData', JSON.stringify(action.payload));
      if (action.payload.roles && action.payload.roles[0]) {
        localStorage.setItem('rolActivo', JSON.stringify(action.payload.roles[0]));
        
        // Cargar todos los datos, incluidos caja y cuentacaja
        return { ...action.payload, rolActivo: action.payload.roles[0] };
      }else{ return { ...action.payload, rolActivo: null };}

      // Cargar todos los datos cuando no hay rolActivo
      return action.payload;
    },

    setUser() {
      const sessionDataString = localStorage.getItem('sessionData');
      const rolActivoString = localStorage.getItem('rolActivo');

      if (sessionDataString) {
        try {
          const sesion = JSON.parse(sessionDataString);
          const rolActivo = JSON.parse(rolActivoString);

          // Cargar todos los datos de sessionData, incluidos caja y cuentacaja
          return { ...sesion, rolActivo: rolActivo };
          
        } catch (error) {
          console.error("Error al cargar los datos de sesión:", error);
        }
      }
    },

    changeRol(state, action) {
      localStorage.setItem('rolActivo',  JSON.stringify(action.payload));
      return { ...state, rolActivo: action.payload };
    },

    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('sessionData');
      localStorage.removeItem('expiration');
      localStorage.removeItem('rolActivo');
      
      return initialState; // Devuelve el estado inicial al cerrar sesión
    },
  },
});

export const sesionActions = sesionSlice.actions;
export default sesionSlice.reducer;
