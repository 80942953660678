import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addSituacion, modifySituacion, fetchSituaciones } from '../../reducers/situacion_slice';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { formatearDecimal } from '../../util/customFormatter';

const initialState = {
  nombre: "",
  descripcion: "",
  cuota_cat: "",
  cuota_cab: "",
  extra: 1 // Nuevo campo para tipo de situación
};

const initialFormDetalles = {
  title: "Nueva situación",
  headerColor: "#254159",
  buttonText: "Crear"
};

const FormSituacion = ({ currentSituacion, showModal, handleClose }) => {
  const dispatch = useDispatch();
  const [datos, setDatos] = useState(initialState);
  const [error, setError] = useState('');
  const [isModified, setIsModified] = useState(false);
  const [formDetalles, setFormDetalles] = useState(initialFormDetalles);

  useEffect(() => {
    if (showModal) {
      if (currentSituacion) {
        setFormDetalles({ title: "Editar situación", headerColor: "#255957", buttonText: "Actualizar" });
        const { alias, ...situacion } = currentSituacion;
        setDatos({
          ...situacion,
          cuota_cat: situacion.cuota_cat === "0.00" ? "" : formatearDecimal(situacion.cuota_cat),
          cuota_cab: situacion.cuota_cab === "0.00" ? "" : formatearDecimal(situacion.cuota_cab),
          extra: situacion.extra
        });
        setIsModified(false);
      } else {
        resetForm();
      }
    }
  }, [currentSituacion, showModal]);

  const handleSubmit = e => {
    e.preventDefault();
    const situacionData = {
      nombre: datos.nombre ? datos.nombre.toUpperCase() : null,
      descripcion: datos.descripcion ? datos.descripcion : null,
      cuota_cab: formatearDecimal(datos.cuota_cab),
      cuota_cat: formatearDecimal(datos.cuota_cat),
      extra: datos.extra
    };
    setError('');
    if (currentSituacion) {
      dispatch(modifySituacion({ id: currentSituacion.id, situacion: situacionData })).then(response => {
        if (response.error || response.payload.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.payload.error || response.error.message || 'Error al actualizar la situación',
          });
        } else {
          handleCloseForm();
          dispatch(fetchSituaciones());
        }
      });
    } else {
      dispatch(addSituacion(situacionData)).then(response => {
        if (response.error || response.payload.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.payload.error || response.error.message || 'Error al crear la situación',
          });
        } else {
          handleCloseForm();
          dispatch(fetchSituaciones());
        }
      });
    }
  };

  const resetForm = () => {
    setDatos(initialState);
    setFormDetalles(initialFormDetalles);
    setIsModified(false);
  };

  const handleCloseForm = () => {
    handleClose();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setDatos(prevDatos => ({
      ...prevDatos,
      [name]: name === "extra" ? parseInt(value, 10) : value
    }));
    setIsModified(true);
  };

  const handleFormat = e => {
    const { name, value } = e.target;

    let numero = value
        // Asegurar que el valor esté entre -100 y 100
        if (parseFloat(value) < 0) {
          numero = 0;
      }
    const valueFormatted = (name === 'cuota_cat' || name === 'cuota_cab') ? formatearDecimal(numero) : numero;
  
    setDatos(prevDatos => ({
      ...prevDatos,
      [name]: valueFormatted
    }));
    setIsModified(true);
  };

  return (
    <Modal show={showModal} onHide={handleCloseForm} centered>
      <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
        <Modal.Title>{formDetalles.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className='row'>
            <div className='col-12'>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre de la situación"
                name="nombre"
                value={datos.nombre}
                onChange={handleChange}
                required
              />
            </div>
            <div className='col-12 mt-3'>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                placeholder="Descripción de la situación"
                name="descripcion"
                value={datos.descripcion}
                onChange={handleChange}
                required
              />
            </div>
            <div className='col-12 mt-3'>
              <Form.Label>Cuota CAT</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                placeholder="0,00"
                name="cuota_cat"
                value={datos.cuota_cat}
                onChange={handleChange}
                onBlur={handleFormat}
              />
            </div>
            <div className='col-12 mt-3'>
              <Form.Label>Cuota CAB</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                placeholder="0,00"
                name="cuota_cab"
                value={datos.cuota_cab}
                onChange={handleChange}
                onBlur={handleFormat}
              />
            </div>
            <div className='col-12 mt-3'>
              <Form.Label>Tipo de Situación</Form.Label>
              <Form.Control
                as="select"
                name="extra"
                value={datos.extra}
                onChange={handleChange}
              >
                <option value={0}>Situación de afiliado</option>
                <option value={1}>Situación de no afiliado</option>
              </Form.Control>
            </div>
          </Form.Group>
          {error && <p className="text-danger">{error}</p>}
          <div className="d-flex justify-content-between mt-5">
            <button className="button-mine btn-cancel-outline" type="button" onClick={handleCloseForm}>
              Cancelar
            </button>
            <button className="button-mine btn-mine-primary-outline" type="submit" disabled={currentSituacion && !isModified}>
              {formDetalles.buttonText}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default FormSituacion;
