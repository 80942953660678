import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchZonas, removeZona } from '../../reducers/zona_slice';
import { fetchLugares } from '../../reducers/lugar_slice';
import ZonaForm from './ZonaForm';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import CustomPrintModal from '../UI/CustomPrintModal';
import { useNavigate } from 'react-router-dom';

const ZonaList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const zonas = useSelector(state => state.zona.zonas || []);
  const lugares = useSelector(state => state.lugar.lugares || []);
  const [currentZona, setCurrentZona] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });

  useEffect(() => {
    dispatch(fetchZonas());
    dispatch(fetchLugares());
  }, [dispatch]);

  // Ordenar zonas por nombre
  const zonasOrdenadas = useMemo(() => {
    return [...zonas].sort((a, b) => a.nombre.localeCompare(b.nombre));
  }, [zonas]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarla!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeZona(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'La zona ha sido eliminada.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.message || 'No se pudo eliminar la zona.', 'error');
        });
      }
    });
  };

  const handleEdit = zona => {
    setCurrentZona(zona);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentZona(null);
    setShowModal(true);
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      zonasOrdenadas.map((zona, index) => ({
        Número: index + 1,
        Nombre: zona.nombre,
        Lugar: getLugarNombre(zona.id_lugar),
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Zonas");
    XLSX.writeFile(workbook, "zonas.xlsx");
  };

  const handleShowPreview = () => {
    const content = {
      head: [['Número', 'Nombre', 'Lugar']],
      body: zonasOrdenadas.map((zona, index) => [
        index + 1,
        zona.nombre,
        getLugarNombre(zona.id_lugar),
      ])
    };
    setPrintContent(content);
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getLugarNombre = idLugar => {
    const lugar = lugares.find(l => l.id === idLugar);
    return lugar ? lugar.nombre : 'Sin asignar';
  };

  const handleBack = () => {
    navigate('/admin');
  };

  return (
    <div className="list-container list0">
      <h1 className='table-title'>Lista de Zonas</h1>
      <div className="list-container-header d-flex justify-content-between">
        <div className="left-buttons">
          <button title="Volver" onClick={handleBack} className="icon-button btn-transparent">
            <i className="bi bi-arrow-left"></i>
          </button>
        </div>
        <div className="right-buttons d-flex gap-2">
          <Button variant="outline-primary" onClick={handleCreate}>
            <i className="bi bi-plus-circle"></i> Nueva Zona
          </Button>
          <Button variant="outline-secondary" onClick={handleShowPreview}>
            <i className="bi bi-printer"></i> Imprimir
          </Button>
          <Button variant="outline-success" onDoubleClick={handleExportExcel}>
            <i className="bi bi-file-spreadsheet"></i> Exportar a Excel
          </Button>
        </div>
      </div>
      <div className='table-container'>
        <Table bordered hover size='sm'>
          <thead className='text-center align-middle'>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Lugar</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {zonasOrdenadas.map((zona, index) => (
              <tr key={zona.id}>
                <td>{index + 1}</td>
                <td>{zona.nombre}</td>
                <td>{getLugarNombre(zona.id_lugar)}</td>
                <td>
                  <Button 
                    variant="outline-secondary" 
                    size="sm" 
                    title="Editar" 
                    onClick={() => handleEdit(zona)}
                  >
                    <i className="bi bi-pencil"></i>
                  </Button>{' '}
                  <Button 
                    variant="outline-danger" 
                    size="sm" 
                    title="Eliminar" 
                    onClick={() => handleDelete(zona.id)}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {zonasOrdenadas.length === 0 && (
          <p className="text-center mt-4">No hay zonas disponibles.</p>
        )}
      </div>
      <ZonaForm
        currentZona={currentZona}
        showModal={showModal}
        handleClose={() => setShowModal(false)}
      />
      <CustomPrintModal
        show={showPreviewModal}
        handleClose={handleClosePreview}
        title1="LISTA DE ZONAS"
        title2=""
        content={printContent}
        copia={false}
      />
    </div>
  );
};

export default ZonaList;
