import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import { changePassword, clearAuthState } from "../../reducers/auth_slice";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  generateCaptcha,
  validateCaptcha,
  clearCaptchaState,
} from "../../reducers/captcha_slice";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    actual: false,
    nueva: false
  });
  // Estado local para los inputs del formulario
  const [formData, setFormData] = useState({
    claveActual: "",
    nuevaClave: "",
    confirmarClave: "",
    captcha: "",
  });

  // Estados locales para la imagen del CAPTCHA y su texto
  const [captchaImage, setCaptchaImage] = useState(null);
  const [captchaText, setCaptchaText] = useState(null); // Estado para guardar el texto del CAPTCHA

  // Estado global para manejar errores y mensajes
  const { status, error, successMessage } = useSelector((state) => state.auth);
  const { status: captchaStatus, error: captchaError } = useSelector((state) => state.captcha);
  const { id,nombre,cambio } = useSelector(state => state.sesion);

  // Generar un nuevo CAPTCHA al cargar el componente
  useEffect(() => {
    //console.log("Generando CAPTCHA...");
    dispatch(generateCaptcha()).then((result) => {
      //console.log("Resultado completo del CAPTCHA:", result.payload);
      if (result.meta.requestStatus === "fulfilled") {
        if (result.payload?.captchaSvg && result.payload?.captchaText) {
         //console.log("CAPTCHA recibido correctamente:", result.payload.captchaSvg);
          setCaptchaImage(result.payload.captchaSvg); // Guarda la imagen del CAPTCHA
          setCaptchaText(result.payload.captchaText); // Guarda el texto del CAPTCHA
        } else {
          console.error("CAPTCHA no contiene los campos requeridos.");
        }
      } else {
        console.error("Error al generar CAPTCHA:", result.error.message);
      }
    });

    return () => {
      dispatch(clearAuthState());
      dispatch(clearCaptchaState());
    };
  }, [dispatch]);

  if (!captchaImage) {
    console.error("La imagen del CAPTCHA no se generó correctamente.");
    return (
      <Alert variant="danger">
        Error al cargar el CAPTCHA. Por favor, recarga la página.
      </Alert>
    );
  }
     // Función para alternar la visibilidad de la contraseña al presionar y soltar el botón
     const handleMouseDown = (name) => {
      setShowPassword((prev)=>({...prev, [name]:true}));  // Mostrar la contraseña al presionar el botón
  };

  const handleMouseUp = (name) => {
    setShowPassword((prev)=>({...prev, [name]:false}));  // Ocultar la contraseña cuando se suelta el botón
  };

  // Manejar cambios en los inputs del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Validar CAPTCHA antes de enviar el formulario
  const handleCaptchaValidation = async () => {
    //console.log("Validando CAPTCHA:", formData.captcha);
    if (formData.captcha === captchaText) {
     // console.log("CAPTCHA validado correctamente.");
      return true;
    } else {
      //console.error("Error al validar CAPTCHA: Texto incorrecto.");
      return false;
    }
  };

  // Manejar envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validaciones locales
    if (!formData.claveActual || !formData.nuevaClave || !formData.confirmarClave) {
      Swal.fire('Error', 'Todos los campos son obligatorios.', 'error');
      return;
    }
    if (formData.claveActual === formData.nuevaClave) {
      Swal.fire('Error', 'Debe cambiar de clave, no puede ser la misma.', 'error');
      return;
    }

    if (formData.nuevaClave.length < 8) {
      Swal.fire('Error', 'La nueva clave debe tener al menos 8 caracteres', 'error');
      return;
    }
    if (formData.nuevaClave !== formData.confirmarClave) {
      Swal.fire('Error', 'La nueva clave y la confirmación no coinciden.', 'error');
      return;
    }

    // Validar CAPTCHA
    const isCaptchaCorrect = await handleCaptchaValidation();
    if (!isCaptchaCorrect) {
      Swal.fire('Error', 'CAPTCHA incorrecto. Por favor, inténtalo de nuevo.', 'error');
      return;
    }

    // Enviar datos al backend
    dispatch(changePassword({
      claveActual: formData.claveActual,
      nuevaClave: formData.nuevaClave,
      id:id
    }));

      Swal.fire("Éxito", 'Se cambió la contraseña correctamente.', "success");
      navigate("/");
   
  };

  return (
    <div className="form-container" style={{backgroundColor:"#f7f7f7", padding:"30px", marginBottom:"10px"}}>
      <h3>Cambiar Clave</h3>
      {status === "failed" && <Alert variant="danger">{error}</Alert>}
      {status === "succeeded" && <Alert variant="success">{successMessage}</Alert>}
      {captchaError && <Alert variant="danger">{captchaError}</Alert>}

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="claveActual" className="mb-3" style={{ position: 'relative' }}>
          <Form.Label>Clave Actual</Form.Label>
          <Form.Control
            type={showPassword.actual ? 'text' : 'password'} 
            name="claveActual"
            value={formData.claveActual}
            onChange={handleInputChange}
            required
          />
            <Button
                variant="link"
                style={{
                    position: 'absolute',
                    top: '70%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    color:"gray"
                }}
                onMouseDown={() => handleMouseDown("actual")} // Muestra la contraseña al presionar el botón
                onMouseUp={() => handleMouseUp("actual")}     // Oculta la contraseña cuando se suelta el botón
                onMouseLeave={() => handleMouseUp("actual")}  // Asegura que la contraseña se oculte si se sale del área del botón
                onTouchStart={() => handleMouseDown("actual")} // Muestra la contraseña al tocar el botón (para táctil)
                onTouchEnd={() => handleMouseUp("actual")}
            >
                {showPassword.actual ? <FaEyeSlash /> : <FaEye />} {/* Icono de ojo */}
            </Button>
        </Form.Group>

        <Form.Group style={{ position: 'relative' }} controlId="nuevaClave" className="mb-3">
          <Form.Label>Nueva Clave</Form.Label>
          <Form.Control
             type={showPassword.nueva ? 'text' : 'password'}
            name="nuevaClave"
            value={formData.nuevaClave}
            onChange={handleInputChange}
            required
          />
           <Button
                variant="link"
                style={{
                    position: 'absolute',
                    top: '70%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    color:"gray"
                }}
                onMouseDown={() => handleMouseDown("nueva")} // Muestra la contraseña al presionar el botón
                onMouseUp={() => handleMouseUp("nueva")}     // Oculta la contraseña cuando se suelta el botón
                onMouseLeave={() => handleMouseUp("nueva")}  // Asegura que la contraseña se oculte si se sale del área del botón
                onTouchStart={() => handleMouseDown("nueva")} // Muestra la contraseña al tocar el botón (para táctil)
                onTouchEnd={() => handleMouseUp("nueva")}
            >
                {showPassword.nueva ? <FaEyeSlash /> : <FaEye />} {/* Icono de ojo */}
            </Button>
        </Form.Group>

        <Form.Group controlId="confirmarClave" className="mb-3">
          <Form.Label>Confirmar Nueva Clave</Form.Label>
          <Form.Control
            type="password"
            name="confirmarClave"
            value={formData.confirmarClave}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="captcha" className="mb-3">
          <Form.Label>Completa el CAPTCHA</Form.Label>
          {captchaImage && (
            <div
              dangerouslySetInnerHTML={{ __html: captchaImage }}
          
              style={{ display: 'inline-block' ,backgroundColor:"#ffffff", padding:"5px"}}
            />
          )}
          {captchaStatus === "loading" && <Spinner 
                                            animation="border" />}
          <Form.Control
            type="text"
            name="captcha"
            value={formData.captcha}
            onChange={handleInputChange}
            placeholder="Escribe el texto que ves en la imagen"
            required
          />
        </Form.Group>

        <Button type="submit" variant="primary" disabled={status === "loading"}>
          {status === "loading" ? (
            <>
              <Spinner animation="border" size="sm" /> Cambiando...
            </>
          ) : (
            "Cambiar Clave"
          )}
        </Button>
      </Form>
    </div>
  );
};

export default ChangePasswordForm;