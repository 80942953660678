import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear instancia del servicio para el endpoint /captchas
const captchaService = apiService("/captchas");

// Thunk para generar CAPTCHA
export const generateCaptcha = createAsyncThunk(
  "captcha/generateCaptcha",
  async (_, { rejectWithValue }) => {
    try {
      const response = await captchaService.getAllSome("generate-captcha", {
        responseType: "json", // Asegúrate de que esto sea JSON
      });
      console.log("Respuesta completa del backend al generar CAPTCHA:", response);
      return {
        captchaSvg: response.captchaSvg, // SVG del CAPTCHA
        captchaText: response.captchaText, // Texto del CAPTCHA
      };
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Error al generar el CAPTCHA.";
      console.error("Error al generar CAPTCHA:", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// Thunk para validar CAPTCHA
export const validateCaptcha = createAsyncThunk(
  "captcha/validateCaptcha",
  async ({ captcha, captchaText }, { rejectWithValue }) => {
    try {
      const response = await captchaService.createSome("validate-captcha", {
        captcha,
        captchaText, // Incluye el texto almacenado del CAPTCHA
      });
      console.log("Resultado de validación del CAPTCHA:", response.data); // Log para depuración
      return response.data.success; // Retornar el éxito de la validación
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "CAPTCHA inválido.";
      console.error("Error al validar CAPTCHA:", errorMessage); // Log de error
      return rejectWithValue(errorMessage);
    }
  }
);

// Estado inicial del slice
const initialState = {
  captchaImage: null, // SVG del CAPTCHA
  captchaText: null, // Texto del CAPTCHA (para enviar al backend)
  isCaptchaValid: null, // Resultado de validación
  status: "idle", // idle | loading | succeeded | failed
  error: null,
};

// Definición del slice
const captchaSlice = createSlice({
  name: "captcha",
  initialState,
  reducers: {
    clearCaptchaState(state) {
      state.captchaImage = null;
      state.captchaText = null; // Limpia el texto del CAPTCHA
      state.isCaptchaValid = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Generación de CAPTCHA
      .addCase(generateCaptcha.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(generateCaptcha.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.captchaImage = action.payload.captchaSvg; // Almacena el SVG
        state.captchaText = action.payload.captchaText; // Almacena el texto
        console.log("CAPTCHA generado con éxito:", action.payload); // Log del CAPTCHA generado
      })
      .addCase(generateCaptcha.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        console.error("Error al generar CAPTCHA:", action.payload);
      })
      // Validación de CAPTCHA
      .addCase(validateCaptcha.pending, (state) => {
        state.status = "loading";
        state.error = null;
        console.log("Validación de CAPTCHA pendiente...");
      })
      .addCase(validateCaptcha.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isCaptchaValid = action.payload; // true si es válido
        console.log("CAPTCHA validado correctamente:", action.payload);
      })
      .addCase(validateCaptcha.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        console.error("Error al validar CAPTCHA:", action.payload);
      });
  },
});

// Exportar acciones y reducer
export const { clearCaptchaState } = captchaSlice.actions;
export default captchaSlice.reducer;
