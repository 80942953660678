import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import Header from "../components/templates/Header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { sesionActions } from "../reducers/session_slice";
import { getTokenDuration } from "../util/auth";
import { toast, ToastContainer } from "react-toastify";
import PublicHeader from "../components/templates/PublicHeader";
import Footer from "../components/templates/PublicFooter";
import { fetchDatGeneral } from "../reducers/datGeneral_slice";

const RootLayout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useLoaderData();
    const usuario = useSelector(state => state.sesion.usuario)

    useEffect(()=>{
    dispatch(fetchDatGeneral()); 
    },[dispatch])

    
    useEffect(() => {
    if(!token) {
        return;
    }
    if(token === 'EXPIRED') {
        dispatch(sesionActions.logout());
        return;
    } 
    dispatch(sesionActions.setUser());

    const tokenDuration = getTokenDuration();
    const cautionMinutesBefore = tokenDuration - 2 * 60 * 1000; // 5 minutos antes en milisegundos

    if (cautionMinutesBefore > 0) {
        setTimeout(() => {
            // Mostrar aviso
            toast.info("Su sesión expirará en 2 minutos. Por favor, guarde su trabajo.");
        }, cautionMinutesBefore);
    }
    //Definimos un temporizador con la duracion que le queda al token
    //Para el logout automatico
    setTimeout(() => {
        dispatch(sesionActions.logout());
        alert("Por favor, inicie sesión nuevamente.");
        navigate('/login');
    }, tokenDuration)

}, [token]);

    let cabecera = <Header />
    let piePagina = (<></>)
    let contentClass = "main-content"
    if(!usuario){
        cabecera = <PublicHeader />
        piePagina = <Footer />
        contentClass ="main-public-content"
    }
    return ( 
      <div className="app-container">
        {cabecera}
        <main className={contentClass}>
          <Outlet />
          <ToastContainer />
        </main>
        {piePagina}
      </div>
        )
}
export default RootLayout;