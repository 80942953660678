import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';

const depositoService = apiService('/depositos');

// Fetch depósitos by afiliado
export const fetchDepositosByAfiliado = createAsyncThunk('deposito/fetchDepositosByAfiliado', async (id_afiliado) => {
  try {
    const response = await depositoService.getAllBySomeId('afiliado', id_afiliado);
    return response;
  } catch (error) {
    throw error;
  }
});

// Add depósito
export const addDeposito = createAsyncThunk('deposito/addDeposito', async (depositoData) => {
  try {
    const response = await depositoService.create(depositoData);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchAllDepositos = createAsyncThunk(
  'deposito/fetchAllDepositos',
  async () => {
    try {
      const response = await depositoService.getAllSome('All');
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDepositosByCI = createAsyncThunk(
  'deposito/fetchDepositosByCI',
  async (ci) => {
    try {
      const response = await depositoService.getAllBySomeId('ci', ci);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const updateDetalleArchivo = createAsyncThunk(
  'deposito/updateDetalleArchivo',
  async ({ id, file }, { rejectWithValue }) => {
    try {
      console.log('[Slice] Llegó a updateDetalleArchivo');
      console.log('[Slice] ID del detalle:', id);
      console.log('[Slice] Archivo recibido:', file);
      
      const formData = new FormData();
      formData.append('archivo', file);
      
      // Verificar contenido del FormData
      console.log('[Slice] FormData entries:');
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }
      
      const response = await depositoService.updateSomeDato('detalle', `${id}/archivo`, formData);
      console.log('[Slice] Respuesta del servicio:', response);
      
      return { id, archivo: response.filename };
    } catch (error) {
      console.error('[Slice] Error en updateDetalleArchivo:', error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


// Slice
const depositoSlice = createSlice({
  name: 'deposito',
  initialState: {
    depositos: [],
    current: null,
    statusSend: 'idle',
    statusGet: 'idle',
    statusUpdateFile: 'idle', // Nuevo estado para actualización de archivos
    error: null,
  },
  reducers: {
    resetCurrentDeposito(state) {
      state.current = null;
    },
    resetStatusSend(state) {
      state.statusSend = 'idle';
    },
    // Reducer para actualización optimista del archivo
    updateDetalleArchivoLocal(state, action) {
      const { detalleId, archivo } = action.payload;
      
      // Actualizar en la lista de depósitos
      state.depositos.forEach(deposito => {
        if (deposito.detalles) {
          const detalle = deposito.detalles.find(d => d.id === detalleId);
          if (detalle) detalle.archivo = archivo;
        }
      });
      
      // Actualizar en current si existe
      if (state.current?.detalles) {
        const detalle = state.current.detalles.find(d => d.id === detalleId);
        if (detalle) detalle.archivo = archivo;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepositosByAfiliado.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchDepositosByAfiliado.fulfilled, (state, action) => {
        state.statusGet = 'idle';
        state.depositos = action.payload;
      })
      .addCase(fetchDepositosByAfiliado.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(addDeposito.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addDeposito.fulfilled, (state, action) => {
        state.statusSend = 'idle';
        state.depositos.push(action.payload);
      })
      .addCase(addDeposito.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchAllDepositos.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchAllDepositos.fulfilled, (state, action) => {
        state.statusGet = 'idle';
        state.depositos = action.payload;
      })
      .addCase(fetchAllDepositos.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchDepositosByCI.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchDepositosByCI.fulfilled, (state, action) => {
        state.statusGet = 'idle';
        state.depositos = action.payload;
      })
      .addCase(fetchDepositosByCI.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      // Manejadores para la nueva acción updateDetalleArchivo
      .addCase(updateDetalleArchivo.pending, (state) => {
        state.statusUpdateFile = 'loading';
      })
      .addCase(updateDetalleArchivo.fulfilled, (state, action) => {
        state.statusUpdateFile = 'idle';
        const { id, archivo } = action.payload;
        
        // Actualizar en todos los depósitos
        state.depositos.forEach(deposito => {
          if (deposito.detalles) {
            const detalle = deposito.detalles.find(d => d.id === id);
            if (detalle) detalle.archivo = archivo;
          }
        });
        
        // Actualizar en current si existe
        if (state.current?.detalles) {
          const detalle = state.current.detalles.find(d => d.id === id);
          if (detalle) detalle.archivo = archivo;
        }
      })
      .addCase(updateDetalleArchivo.rejected, (state, action) => {
        state.statusUpdateFile = 'failed';
        state.error = action.payload?.message || action.error.message;
      });
  },
});

export const { 
  resetCurrentDeposito, 
  resetStatusSend,
  updateDetalleArchivoLocal // Exportamos el nuevo reducer
} = depositoSlice.actions;
export default depositoSlice.reducer;
