import React, { useEffect, useState } from 'react';
import { Modal, Table, Button } from 'react-bootstrap';
import VistaPagoCuota from './VistaPagoCuota';
import { useDispatch } from 'react-redux';
import { fetchReciboById } from '../../reducers/recibo_slice';
import { formaFechaI, formatMonetaryValue, getCuotaNombre, numeroALetras } from '../../util/valueCalculator';
import ImprimirRecibo from '../recibo/ImprimirRecibo';
import Swal from 'sweetalert2';
import { formatNumber } from '../../util/customFormatter';

const PagosRealizados = ({ show, handleClose, afiliado, cuotas }) => {
  const dispatch = useDispatch();
  const [showVistaPagoCuota, setShowVistaPagoCuota] = useState(false);
  const [selectedDepositoId, setSelectedDepositoId] = useState(null);
  const [selectedReciboId, setSelectedReciboId] = useState(null);
  const [recibos, setRecibos] = useState([]);
  const [reciboData, setReciboData] = useState(null);
  const [showPrintModal, setShowPrintModal] = useState(false);

  useEffect(() => {
    if (show && afiliado) {
      // Filtrar cuotas asociadas al afiliado que tengan un id_recibo_cab o id_recibo_cat
      const cuotasAfiliado = cuotas.filter(
        cuota => cuota.id_afiliado === afiliado.id && (cuota.id_recibo_cab || cuota.id_recibo_cat)
      );
      setRecibos(cuotasAfiliado);
    }
  }, [show, afiliado, cuotas, dispatch]);

  const handleViewRecibo = async (id_recibo) => {
    try {
      const recibo = await dispatch(fetchReciboById(id_recibo)).unwrap();
      const totalLiteral = numeroALetras(recibo.monto);

      setReciboData({
        recibo,
        reciboDetalles: recibo.reciboDets,
        totalLiteral
      });
      setShowPrintModal(true);
    } catch (error) {
      Swal.fire('Error', 'No se pudo obtener el recibo', 'error');
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Pagos Realizados por {`${afiliado.persona.nombre} ${afiliado.persona.apellido1 || afiliado.persona.apellido2 || ""}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Cuota</th>
                <th style={{textAlign:"center"}}>Monto CAT (Bs)</th>
                <th style={{textAlign:"center"}}>Monto CAB (Bs)</th>
                <th style={{textAlign:"center"}}>Pagado CAT (Bs)</th>
                <th style={{textAlign:"center"}}>Pagado CAB (Bs)</th>
                <th>Observación</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {recibos.map((cuota, index) => {
                const hayCuotaCat = parseFloat(cuotas[index].monto_cat) > 0 
                const hayCuotaCab = parseFloat(cuotas[index].monto_cab) > 0 
                const colorCat = hayCuotaCat? parseFloat(cuota.monto_cat) !==parseFloat(cuota.monto_cat_pagada)?"#ffc3b5":"":""
                return(
                <tr key={cuota.id}>
                  <td>{index + 1}</td>
                  <td>{getCuotaNombre(cuota.cuota)}</td>
                  <td style={{textAlign:"center", backgroundColor:colorCat}}>{hayCuotaCat? formatNumber(cuota.monto_cat):"______"}</td>
                  <td style={{textAlign:"center"}}>{hayCuotaCab? formatNumber(cuota.monto_cab) : "______"}</td>
                  <td style={{textAlign:"center", backgroundColor:colorCat}}>{hayCuotaCat? formatNumber(cuota.monto_cat_pagada):"_____"}</td>
                  <td style={{textAlign:"center"}}>{hayCuotaCab? formatNumber(cuota.monto_cab_pagada):"_____"}</td>
                  <td>{cuota.observacion}</td>
                  <td>
                    {cuota.id_recibo_cat && (

                    <button
                    className="icon-button btn-secondary-outline"
                    title="Ver recibo donde se pago la cuota CAT"
                    onClick={() => handleViewRecibo(cuota.id_recibo_cat)}
                    >
                    <i className="bi bi-receipt"></i>
                    </button>
                    )}
                    {cuota.id_recibo_cab && (cuota.id_recibo_cat !== cuota.id_recibo_cab )  && (
                   <button
                   className="icon-button btn-secondary-outline"
                   title="Ver recibo donde se pago la cuota CAB"
                   onClick={() => handleViewRecibo(cuota.id_recibo_cab)}
                   >
                   <i className="bi bi-receipt"></i>
                   </button>




              
                    )}
                  </td>
                </tr>
              )})}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>


 



      {showVistaPagoCuota && (
        <VistaPagoCuota
          show={showVistaPagoCuota}
          handleClose={() => setShowVistaPagoCuota(false)}
          afiliado={afiliado}
          depositoId={selectedDepositoId}
          reciboId={selectedReciboId}
        />
      )}
      {reciboData && (
        <ImprimirRecibo
          show={showPrintModal}
          handleClose={() => setShowPrintModal(false)}
          recibo={reciboData.recibo}
          reciboDetalles={reciboData.reciboDetalles}
          totalLiteral={reciboData.totalLiteral}
        />
      )}
    </>
  );
};

export default PagosRealizados;