import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import Table from 'react-bootstrap/Table';
import { fetchCuotasByAfiliado } from '../../reducers/cuota_slice'; // Importamos la función para obtener cuotas por afiliado
import PagoCuotasModal from './PagoCuotasModal';
import CustomPrintModal from '../UI/CustomPrintModal';
import CustomPrintModal3 from '../UI/CustomPrintModal3';
import VistaPagoCuota from './VistaPagoCuota';
import PagosRealizados from './PagosRealizados'; // Importamos el modal de Pagos Realizados
import * as XLSX from 'xlsx';
import { fetchAllCajasDiarias } from "../../reducers/caja_slice";
import Swal from 'sweetalert2';
import { fetchReciboById } from '../../reducers/recibo_slice';
import { getCuotaNombre, numeroALetras } from '../../util/valueCalculator';
import ImprimirRecibo from '../recibo/ImprimirRecibo';
import { apellidosNombreFullPersona } from '../../util/customFormatter';

const ListaAfiliados = () => {
  const { codigoFuncionalidad } = useParams();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.sesion.id);
  const { nombre, rolActivo, roles, id } = useSelector(state => state.sesion);
  const afiliadoState = useSelector(state => state.afiliado || { afiliados: [] });
  const cajaState = useSelector(state => state.caja || { cajas: [] });
  const cajasDiarias = cajaState.cajasDiarias || [];
  const idCajaDiaria = useSelector(state => state.caja.idCajaDiaria);
  const afiliados = afiliadoState.afiliados || [];
  const cuotas = useSelector(state => state.cuota.cuotas || []); // Estado global de cuotas
  const [showModal, setShowModal] = useState(false);
  const [currentAfiliado, setCurrentAfiliado] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showEstadoCuentaModal, setShowEstadoCuentaModal] = useState(false);
  const [showPagosRealizados, setShowPagosRealizados] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [searchTerm, setSearchTerm] = useState('');
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [showVistaPagoCuota, setShowVistaPagoCuota] = useState(false);
  const [depositoId, setDepositoId] = useState(null);
  const [showImprimirReciboModal, setShowImprimirReciboModal] = useState(false);
  const [reciboData, setReciboData] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key1: "", key2: '', key3: null,  direction: 'asc' });

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAfiliados());
    dispatch(fetchAllCajasDiarias());
  }, [dispatch, id]);

  useEffect(() => {
    if (refreshFlag) {
      dispatch(fetchAfiliados());
      setRefreshFlag(false);
    }
  }, [dispatch, refreshFlag]);

  const filteredAfiliados = useMemo(() => {
    return afiliados.filter(afiliado => afiliado.situacion.extra !== 1);
  }, [afiliados]);

  const handlePagoCuotas = afiliado => {
    console.log("Esta en handlePagoCuotas...")
    setCurrentAfiliado(afiliado);
    setShowModal(true);
  };

  const handleImprimirEstadoCuenta = async afiliado => {
    setCurrentAfiliado(afiliado);

    // Obtener las cuotas del afiliado seleccionado
    const cuotasAfiliado = await dispatch(fetchCuotasByAfiliado(afiliado.id));

    // Filtrar cuotas no pagadas
    const cuotasNoPagadas = cuotasAfiliado.payload
      .filter(cuota => cuota.estado === 'no_pagada')
      .sort((a, b) => a.cuota - b.cuota);

    // Calcular totales
    const totalCABNoPagadas = cuotasNoPagadas.reduce((total, cuota) => total + (Number(cuota.monto_cab) || 0), 0);
    const totalCATNoPagadas = cuotasNoPagadas.reduce((total, cuota) => total + (Number(cuota.monto_cat) || 0), 0);
    const totalTOTNoPagadas = totalCABNoPagadas + totalCATNoPagadas;

    // Preparar contenido para el modal
    const estadoCuentaContent = {
      head: [['#','Emisión', 'Cuota', 'Monto CAB', 'Monto CAT', 'Observacion']],
      body: cuotasNoPagadas.map((cuota, index) => [
        index+1,
        new Date(cuota.fecha_emision).toLocaleDateString(),
        getCuotaNombre(cuota.cuota),
        (Number(cuota.monto_cab || 0) - Number(cuota.monto_cab_pagada || 0)).toFixed(2),
        (Number(cuota.monto_cat || 0) - Number(cuota.monto_cat_pagada || 0)).toFixed(2),
        cuota.observacion
      ])
    };

    setPrintContent({
      head: estadoCuentaContent.head,
      body: estadoCuentaContent.body,
      total1: `TOTAL CAB: ${totalCABNoPagadas.toFixed(2)} Bs    TOTAL CAT: ${totalCATNoPagadas.toFixed(2)} Bs`,
      total2: `TOTAL GENERAL: ${totalTOTNoPagadas.toFixed(2)} Bs `
    });

    setShowEstadoCuentaModal(true);
  };

  const handleImprimirPagosRealizados = async afiliado => {
    setCurrentAfiliado(afiliado);

    // Obtener las cuotas del afiliado seleccionado
    await dispatch(fetchCuotasByAfiliado(afiliado.id));

    setShowPagosRealizados(true); // Mostrar modal para Pagos Realizados
  };

  const handleImprimirRecibo = async (reciboId) => {
    try {
        const recibo = await dispatch(fetchReciboById(reciboId)).unwrap();
        const totalLiteral = numeroALetras(recibo.monto);

        setReciboData({
            recibo,
            reciboDetalles: recibo.reciboDets,
            totalLiteral
        });

        setShowImprimirReciboModal(true);
    } catch (error) {
        Swal.fire('Error', 'No se pudo obtener el recibo', 'error');
    }
};

const handleSort = (key1, key2, key3=null) => {
  if (sortConfig.direction === 'desc') {
    setSortConfig({ key3:null, key1:null, key2: null, direction: 'asc' });
    return;
  }
  let direction = 'asc';
  if (sortConfig.key3 === key3 && sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'asc') {
    direction = 'desc';
  } else if (sortConfig.key3 === key3 && sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'desc') {
    direction = null; // Si ya estaba descendente, lo dejamos sin ordenar
  }
  setSortConfig({key3, key1, key2, direction });
};

  const handleClose = (data) => {
    setCurrentAfiliado(null);
    setRefreshFlag(true);
    setShowModal(false);
    if (data && data.idDeposito) {
      setTimeout(() => {
        handleImprimirRecibo(data.idRecibo);
      }, 300);
    }
  };

  // const openVistaPagoCuotaModal = (idDeposito, idAfiliado, idRecibo) => {
  //   setDepositoId(idDeposito);
  //   setReciboId(idRecibo);
  //   setCurrentAfiliado(afiliados.find(af => af.id === idAfiliado));
  //   setTimeout(() => setShowVistaPagoCuota(true), 0);
  // };

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };
  const filteredList = useMemo(() => ([...filteredAfiliados].filter(afiliado => {
    const searchText = `${afiliado.persona.apellido1 || ""} ${afiliado.persona.apellido2 || ""} ${afiliado.persona.nombre} ${afiliado.persona.ci} ${afiliado.num_reg}`.toLowerCase();
    return searchText.includes(searchTerm.toLowerCase());
  })),[filteredAfiliados, searchTerm])

  const sortedAfiliados = useMemo(() => {

    const sortedList = [...filteredList]
    if (sortConfig.key2) {
      sortedList.sort((a, b) => {
        let aValue, bValue;
            // Comparar subpropiedades
            if (sortConfig.key1 !== "") {
              if (sortConfig.key3 !== "" && sortConfig.key3 !== null) {
                aValue = a[sortConfig.key1][sortConfig.key2][sortConfig.key3];
                bValue = b[sortConfig.key1][sortConfig.key2][sortConfig.key3];
              
              }else{
                aValue = a[sortConfig.key1][sortConfig.key2];
                bValue = b[sortConfig.key1][sortConfig.key2];
              }
            }else{
                aValue = a[sortConfig.key2];
                bValue = b[sortConfig.key2];
            }
            // Manejar valores nulos para que vayan al final
            if (aValue === null || aValue === undefined || Number.isNaN(aValue)) return 1;
            if (bValue === null ||  bValue === undefined || Number.isNaN(bValue)) return -1;
    
            // Comparar valores no nulos
            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
      });
    }
    return sortedList
  }, [filteredList, sortConfig.key1, sortConfig.key2, sortConfig.direction]);

  const handleBack = () => {
    navigate('/admin');
  };

  const handleExport = () => {
    const exportData = sortedAfiliados.map((afiliado, index) => ({
      'N°': index + 1,
      'N° Registro': afiliado.num_reg,
      'Ap. Paterno': afiliado.persona.apellido1,
      'Ap. Materno': afiliado.persona.apellido2,
      'Nombre': afiliado.persona.nombre,
      'C.I.': afiliado.persona.ci,
      'Situación': afiliado.situacion.nombre || 'N/A',
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Afiliados");
    XLSX.writeFile(workbook, "afiliados.xlsx");
  };

  return (
    <div className="list-container list1">
      <h1 className='table-title'>LISTA DE AFILIADOS</h1>
      <div className="list-content">
        <div className='list-controls'>
          <div className="col-md-4 mt-2">
            <input
              type="text"
              placeholder="Buscar por apellidos, CI o número de registro"
              className="form-control"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="list-container-header">
          <div className="right-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent">
              <i className="bi bi-arrow-left"></i>
            </button>
            <button title="Imprimir" onClick={() => setShowPreviewModal(true)} className="icon-button btn-transparent">
              <i className="bi bi-printer"></i>
            </button>
            <button title="Exportar a Excel" onDoubleClick={handleExport} className="icon-button btn-transparent">
              <i className="bi bi-file-spreadsheet"></i>
            </button>
          </div>
        </div>
        {afiliadoState.statusGet === "loading"?(
          <p>Cargando lista de afiliados...</p>
        ): sortedAfiliados.length === 0 ? (
          <p>- No hay afiliados registrados -</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead style={{ backgroundColor: "#b2aea3" }}>
                <tr>
                  <th>#</th>
                  <th>N° Registro</th>
                  <th>Apellido Paterno</th>
                  <th>Apellido Materno</th>
                  <th>Nombre</th>
                  <th>C.I.</th>
                  <th onClick={() => handleSort('situacion','nombre','')}>Situación
                    {sortConfig.key1 === 'situacion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedAfiliados.map((afiliado, index) => (
                  <tr key={afiliado.id}>
                    <td>{index + 1}</td>
                    <td>{afiliado.num_reg}</td>
                    <td>{afiliado.persona.apellido1 || ""}</td>
                    <td>{afiliado.persona.apellido2 || ""}</td>
                    <td>{afiliado.persona.nombre}</td>
                    <td>{afiliado.persona.ci}</td>
                    <td>{afiliado.situacion.nombre || 'N/A'}</td>
                    <td>
                      {codigoFuncionalidad === "1" && (
                        <button
                          className="icon-button btn-secondary-outline"
                          title="Pagar cuotas"
                          onClick={() => handlePagoCuotas(afiliado)}
                          disabled={!idCajaDiaria}
                        >
                          <i className="bi bi-cash-stack"></i>
                        </button>
                      )}
                      {codigoFuncionalidad === "2" && (
                        <>
                          <button
                            className="icon-button btn-secondary-outline"
                            title="Imprimir estado de cuenta"
                            onClick={() => handleImprimirEstadoCuenta(afiliado)}
                          >
                            <i className="bi bi-printer"></i>
                          </button>
                          </>
                      )}
                          <button
                            className="icon-button btn-secondary-outline"
                            title="Recibos"
                            onClick={() => handleImprimirPagosRealizados(afiliado)}
                          >
                            <i className="bi bi-receipt"></i>
                          </button>
                  
                      {codigoFuncionalidad === "3" && (
                        <>
                          
                          <button
                            className="icon-button btn-secondary-outline"
                            title="Recibos"
                            onClick={() => handleImprimirPagosRealizados(afiliado)}
                          >
                            <i className="bi bi-receipt"></i>
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {showImprimirReciboModal && reciboData && (
          <ImprimirRecibo
              show={showImprimirReciboModal}
              handleClose={() => setShowImprimirReciboModal(false)}
              recibo={reciboData.recibo}
              reciboDetalles={reciboData.reciboDetalles}
              totalLiteral={reciboData.totalLiteral}
          />
      )}
        {showModal && currentAfiliado && (
          <PagoCuotasModal
            show={showModal}
            handleClose={handleClose}
            afiliado={currentAfiliado}
          />
        )}
        {/* {showVistaPagoCuota && currentAfiliado && (
          <VistaPagoCuota
            show={showVistaPagoCuota}
            handleClose={() => setShowVistaPagoCuota(false)}
            afiliado={currentAfiliado}
            depositoId={depositoId}
            reciboId={reciboId}
          />
        )} */}
        {showPagosRealizados && currentAfiliado && (
          <PagosRealizados
            show={showPagosRealizados}
            handleClose={() => setShowPagosRealizados(false)}
            afiliado={currentAfiliado}
            cuotas={cuotas.filter(cuota => cuota.id_afiliado === currentAfiliado.id)} // Pasamos solo las cuotas del afiliado
          />
        )}
        {showEstadoCuentaModal && currentAfiliado && (
          <CustomPrintModal3
            show={showEstadoCuentaModal}
            handleClose={() => setShowEstadoCuentaModal(false)}
            title1="ESTADO DE CUENTA - CUOTAS ADEUDADAS"
            title2={`AFILIADO: ${apellidosNombreFullPersona(currentAfiliado.persona,"ci")}`}
            title3={`N° Registro: ${currentAfiliado.num_reg}`}
            title5=""
            content={printContent}
            total1={printContent.total1}
            total2={printContent.total2}
          />
        )}
      </div>
    </div>
  );
};

export default ListaAfiliados;