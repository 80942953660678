import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsuarios, modifyUsuario } from "../../reducers/usuario_slice";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
const ClavesMasivas = () => {
  const dispatch = useDispatch();
  const usuarios = useSelector((state) => state.usuario.usuarios || []);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  // Generador de clave: 4 letras + 4 números
  const generarClaveAleatoria = () => {
    const letras = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const numeros = "0123456789";

    const claveLetras = Array.from({ length: 4 }, () =>
      letras.charAt(Math.floor(Math.random() * letras.length))
    ).join("");
    const claveNumeros = Array.from({ length: 4 }, () =>
      numeros.charAt(Math.floor(Math.random() * numeros.length))
    ).join("");

    return claveLetras + claveNumeros;
  };

  // Actualizar claves de usuarios
  const actualizarClaves = async () => {
    setIsProcessing(true);
    try {
      const usuariosSinClave = usuarios.filter((u) => !u.clave_acceso);

      if (usuariosSinClave.length === 0) {
        Swal.fire("Atención", "Todos los usuarios ya tienen claves asignadas.", "info");
        setIsProcessing(false);
        return;
      }

      for (const usuario of usuariosSinClave) {
        const claveGenerada = generarClaveAleatoria();

        // Modificar el usuario con clave nueva
        await dispatch(
          modifyUsuario({
            id: usuario.id,
            usuario: { ...usuario, clave_acceso: claveGenerada, cambio: 1, cuentacaja:claveGenerada },
          })
        );

        console.log(
          `Usuario: ${usuario.usuario}, Clave Generada: ${claveGenerada}`
        );
      }

      Swal.fire(
        "Proceso Completado",
        "Se han generado claves para todos los usuarios sin clave.",
        "success"
      );
    } catch (error) {
      console.error("Error al actualizar claves:", error);
      Swal.fire("Error", "Ocurrió un error al actualizar las claves.", "error");
    } finally {
      setIsProcessing(false);
      dispatch(fetchUsuarios()); // Refrescar lista de usuarios
    }
  };

  useEffect(() => {
    dispatch(fetchUsuarios());
  }, [dispatch]);

  const handleCancelar = () => {
    navigate('/admin')
    Swal.fire("Cancelado", "El proceso fue cancelado.", "info");
  };

  return (
    <div className="list-container list2">
      <h3>Actualizar Claves Masivas</h3>
      <p className="text-danger">
        ⚠️ Este proceso es delicado y debe realizarse con extrema precaución.
        Al generar claves masivas, se modificarán los datos de los usuarios
        que actualmente no tienen clave asignada. Verifique cuidadosamente
        antes de proceder.
      </p>
      <div className="mt-3">
        <button
          className="btn btn-secondary me-2"
          onClick={handleCancelar}
          disabled={isProcessing}
        >
          Cancelar
        </button>
        <button
          className="btn btn-primary"
          onClick={actualizarClaves}
          disabled={isProcessing}
        >
          {isProcessing ? "Procesando..." : "Generar Claves"}
        </button>
      </div>
    </div>
  );
};

export default ClavesMasivas;
